import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

const useStyles = makeStyles(theme => ({
    root: {
        // width: '100%',
        // maxWidth: 360,
        // backgroundColor: theme.palette.background.paper,
    },
    filter: {
        padding: theme.spacing(.5)
    }
}));

export const Filter = ({filter, current, onSelect}) => {
    const classes = useStyles(),
        [anchorEl, setAnchorEl] = React.useState(null),
        indexFromState = filter.findIndex(f => f.value === current),
        [selectedIndex, setSelectedIndex] = React.useState(indexFromState < 0 ? 0 : indexFromState);

    const handleClickListItem = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        onSelect && onSelect(filter[index]);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (indexFromState >= 0 && selectedIndex !== indexFromState) {
        setSelectedIndex(indexFromState);
    }

    if (!filter[selectedIndex]) {
        return null;
    }

    return (
        <div className={classes.root}>
            <List component="nav" aria-label="Filter">
                <ListItem
                    className={classes.filter}
                    button
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    aria-label="Filter"
                    onClick={handleClickListItem}
                >
                    <ListItemText primary={filter[selectedIndex].title} />
                </ListItem>
            </List>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {filter.map((option, index) => (
                    <MenuItem
                        key={option.value}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                    >
                        {option.title}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
