export const GA_STATISTICS_REQUESTED = 'GA_STATISTICS_REQUESTED';
export const GA_STATISTICS_SUCCEEDED = 'GA_STATISTICS_SUCCEEDED';
export const GA_STATISTICS_FAILED = 'GA_STATISTICS_FAILED';
export const GA_STATISTICS_HOME_REQUESTED = 'GA_STATISTICS_HOME_REQUESTED';
export const GA_STATISTICS_HOME_SUCCEEDED = 'GA_STATISTICS_HOME_SUCCEEDED';
export const GA_STATISTICS_REALTIME_REQUESTED = 'GA_STATISTICS_REALTIME_REQUESTED';
export const GA_STATISTICS_REALTIME_SUCCEEDED = 'GA_STATISTICS_REALTIME_SUCCEEDED';
export const GA_STATISTICS_DOWNLOAD_REQUESTED = 'GA_STATISTICS_DOWNLOAD_REQUESTED';
export const GA_STATISTICS_DOWNLOAD_SUCCEEDED = 'GA_STATISTICS_DOWNLOAD_SUCCEEDED';

export const GA_REPORT_EXPORT_REQUESTED = 'GA_REPORT_DOWNLOADED_REQUESTED';
export const GA_REPORT_EXPORT_STARTED = 'GA_REPORT_DOWNLOAD_STARTED';
export const GA_REPORT_EXPORT_SUCCEEDED = 'GA_REPORT_DOWNLOAD_SUCCEEDED';
export const GA_REPORT_EXPORT_FAILED = 'GA_REPORT_EXPORT_FAILED';

export const STATISTICS_REQUESTED = 'STATISTICS_REQUESTED';
export const STATISTICS_SUCCEEDED = 'STATISTICS_SUCCEEDED';
export const STATISTICS_FAILED = 'STATISTICS_FAILED';
export const STATISTICS_TOTALS_SUCCEEDED = 'STATISTICS_TOTALS_SUCCEEDED';
export const STATISTICS_TOTALS_FAILED = 'STATISTICS_TOTALS_FAILED';
export const STATISTICS_HOME_REQUESTED = 'STATISTICS_HOME_REQUESTED';
export const STATISTICS_HOME_SUCCEEDED = 'STATISTICS_HOME_SUCCEEDED';
export const STATISTICS_REALTIME_REQUESTED = 'STATISTICS_REALTIME_REQUESTED';
export const STATISTICS_REALTIME_SUCCEEDED = 'STATISTICS_REALTIME_SUCCEEDED';
export const STATISTICS_DOWNLOAD_REQUESTED = 'STATISTICS_DOWNLOAD_REQUESTED';
export const STATISTICS_DOWNLOAD_SUCCEEDED = 'STATISTICS_DOWNLOAD_SUCCEEDED';
export const STATISTICS_PRIMUS_VS_DM_REQUESTED = 'STATISTICS_PRIMUS_VS_DM_REQUESTED';
export const STATISTICS_PRIMUS_VS_DM_SUCCEEDED = 'STATISTICS_PRIMUS_VS_DM_SUCCEEDED';

export const REPORT_EXPORT_REQUESTED = 'REPORT_DOWNLOADED_REQUESTED';
export const REPORT_EXPORT_STARTED = 'REPORT_DOWNLOAD_STARTED';
export const REPORT_EXPORT_SUCCEEDED = 'REPORT_DOWNLOAD_SUCCEEDED';
export const REPORT_EXPORT_FAILED = 'REPORT_EXPORT_FAILED';

export const MASHUPS_REQUESTED = 'MASHUPS_REQUESTED';
export const MASHUPS_SUCCEEDED = 'MASHUPS_SUCCEEDED';
export const MASHUPS_FAILED = 'MASHUPS_FAILED';
export const ARTIFACT_MASHUPS_REQUESTED = 'ARTIFACT_MASHUPS_REQUESTED';
export const ARTIFACT_MASHUPS_SUCCEEDED = 'ARTIFACT_MASHUPS_SUCCEEDED';
export const ARTIFACT_MASHUPS_FAILED = 'ARTIFACT_MASHUPS_FAILED';
export const MASHUP_SELECTED = 'MASHUP_SELECTED';
export const MASHUP_UPDATE_REQUESTED = 'MASHUP_UPDATE_REQUESTED';
export const MASHUP_UPDATE_SUCCEEDED = 'MASHUP_UPDATE_SUCCEEDED';
export const MASHUP_UPDATE_FAILED = 'MASHUP_UPDATE_FAILED';
export const MASHUP_DELETE_REQUESTED = 'MASHUP_DELETE_REQUESTED';
export const MASHUP_DELETE_SUCCEEDED = 'MASHUP_DELETE_SUCCEEDED';
export const MASHUP_DELETE_FAILED = 'MASHUP_DELETE_FAILED';
export const MASHUP_CHANGE = 'MASHUP_CHANGE';
