import DownloadIcon from '@mui/icons-material/CloudDownload';

import { filterStyle, svgToSrc, dmsUrl } from '@ekultur/ui-schema-components';

import { License } from './License';

export const MediaItem = (block, item, index) => {
    const desc = (item.local && item.local.description) || item.description,
        cred = item.local && item.local.credit,
        license = item.licenses && item.licenses[0],
        pos = (block.settings && block.settings.layout) || '',
        size = (item.settings && item.settings.size) || '';

    if (['video', 'audio'].includes(item.type)) {
        return (
            <div key={index} className={`row row--${pos} ${size}`}>
                {item.type === 'video'
                    ? (
                        <video controls>
                            <source src={item.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <audio controls>
                            <source src={item.url} type="audio/mp3" />
                            Your browser does not support the audio tag.
                        </audio>
                    )}
                {desc && desc !== '' && <span className="media__caption">{desc.slice(0, 400)}...</span>}
                {cred && <span className="media__credit">{cred}</span>}
                {license && <License code={license} />}
            </div>
        );
    } else if (item.type === 'document') {
        return (
            <a key={index} className="download-link" href={`${item.url}?filename=${item.filename}`}>
                <DownloadIcon />
                <span>{item.filename}</span>
            </a>
        );
    }

    return (
        <div key={index} className={`row row--${pos} ${size}`}>
            <figure className="article__bodytext">
                {item.mimetype === 'image/svg+xml' && item.source
                    ? <img className="svg" src={svgToSrc(item.source)} alt={desc} style={item.media && filterStyle(item.media)} />
                    : <img src={`${dmsUrl(item, 10)}${item.mimetype && item.mimetype.indexOf('png') > -1 ? '?mediaType=image/png' : ''}`} alt={desc} style={filterStyle(item.media)} />
                }
            </figure>
            {desc && desc !== '' && <span className="media__caption">{desc.slice(0, 400)}...</span>}
            {cred && <span className="media__credit">{cred}</span>}
            {license && <License code={license} />}
        </div>
    );
};
