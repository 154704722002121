import React, { useState } from 'react';
import { Paper, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Cell, Legend, Pie, ResponsiveContainer, Sector, PieChart } from 'recharts';

import { useStyles } from './publishedObjectsStyles';

export const PublishedObjectsPieChart = ({ fetching, published, notPublished, t }) => {
    const classes = useStyles(),
        [activeIndex, setActiveIndex] = useState(),
        { palette } = useTheme(),
        data = [
            { name: t('published', 'Publisert'), value: published },
            { name: t('notPublished', 'Ikke publisert'), value: notPublished }
        ],
        COLORS = [palette.primary.main, palette.grey[500]];

    return (
        <Paper elevation={3} className={classes.paper}>
            <Typography variant='h6' align='center'>
                {t('percentageAllObjects', 'Prosentvis alle objekter')}
            </Typography>
            <ResponsiveContainer
                width='100%'
                height={350}
                id='publishedObjectsPieChartResponsive'
            >
                <PieChart>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        onAnimationEnd={() => setActiveIndex(0)}
                        data={data}
                        nameKey='name'
                        dataKey='value'
                        cx='50%'
                        cy='50%'
                        label={{ fill: 'black' }}
                        outerRadius={100}
                        innerRadius={70}
                    >
                        {
                            data.map((entry, index) =>
                                <Cell key={index} fill={COLORS[index % COLORS.length]} />
                            )
                        }
                    </Pie>
                    <Legend iconType='circle' />
                </PieChart>
            </ResponsiveContainer>
        </Paper>
    )
};

const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, percent }) => {
    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill} fontSize='32'>
                {Math.floor(percent * 100)}%
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
        </g>
    );
};
