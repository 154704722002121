import { kitFetch } from '@ekultur/fetch';
import { config } from '../../config';

export const RESOURCES_REQUESTED = 'RESOURCES_REQUESTED';
export const RESOURCES_SUCCEEDED = 'RESOURCES_SUCCEEDED';
export const RESOURCES_FAILED = 'RESOURCES_FAILED';
export const RESOURCE_DELETE_REQUESTED = 'RESOURCE_DELETE_REQUESTED';
export const RESOURCE_DELETED = 'RESOURCE_DELETED';

export const SEARCH_REQUESTED = 'SEARCH_REQUESTED';
export const SEARCH_SUCCEEDED = 'SEARCH_SUCCEEDED';
export const SEARCH_FAILED = 'SEARCH_FAILED';
export const RESET_MEDIA_SEARCH = 'RESET_MEDIA_SEARCH';
export const RESET_MEDIA_SEARCH_FLAG = 'RESET_MEDIA_SEARCH_FLAG';


export const PREVIEW_SEARCH_REQUESTED = 'PREVIEW_SEARCH_REQUESTED';
export const PREVIEW_SEARCH_SUCCEEDED = 'PREVIEW_SEARCH_SUCCEEDED';
export const PREVIEW_SEARCH_FAILED = 'PREVIEW_SEARCH_FAILED';

export const FILE_UPLOAD_SUCCEEDED = 'FILE_UPLOAD_SUCCEEDED';
export const FILE_UPLOAD_STATUS = 'FILE_UPLOAD_STATUS';

export const RESOURCE_DATA_UPDATE_REQUESTED = 'RESOURCE_DATA_UPDATE_REQUESTED';
export const RESOURCE_DATA_UPDATED = 'RESOURCE_DATA_UPDATED';
export const RESOURCE_DATA_UPDATE_FAILED = 'RESOURCE_DATA_UPDATE_FAILED';

export const requestResources = (owner, q = null, rows = 25, start = 0, mediaType = 'media') => dispatch => {
    dispatch({
        type: RESOURCES_REQUESTED
    });

    const qs = `?owner=${owner}&rows=${rows}&start=${start}&type=${mediaType}${q ? ('&q=' + q) : ''}`;

    kitFetch(`${config.get('articleApi')}media/resources${qs}`)
        .then(data => dispatch({
            type: RESOURCES_SUCCEEDED,
            data: data
        }))
        .catch(error => dispatch({
            type: RESOURCES_FAILED,
            data: error
        }));
};

export const updateResources = (owner, data, resource) => dispatch => {
    dispatch({
        type: RESOURCE_DATA_UPDATE_REQUESTED
    });

    let ids = {owner, resourceId: resource.id}
    const postConfig = {
        method: 'POST',
        body: JSON.stringify({...data, ...ids })
    };

    kitFetch(`${config.get('articleApi')}media/resources/update/`, postConfig)
        .then(media => dispatch({
            type: RESOURCE_DATA_UPDATED,
            data: media

        }))
        .catch(error => dispatch({
            type: RESOURCE_DATA_UPDATE_FAILED,
            data: error
        }));
};

export const requestDmSearch = (query, resetResult = true, preview = false) => dispatch => {
    dispatch({
        type: preview ? PREVIEW_SEARCH_REQUESTED : SEARCH_REQUESTED,
        query: query
    });

    kitFetch(`${config.get('articleApi')}media/search?q=${query}`)
        .then(data => dispatch({
            type: preview ? PREVIEW_SEARCH_SUCCEEDED : SEARCH_SUCCEEDED,
            data: data,
            resetResult: resetResult,
            query: query
        }))
        .catch(error => dispatch({
            type: preview ? PREVIEW_SEARCH_FAILED : SEARCH_FAILED,
            data: error,
            query: query
        }));
};

export const deleteResource = (ownerId, resourceId) => dispatch => {
    dispatch({
        type: RESOURCE_DELETE_REQUESTED
    });

    kitFetch(`${config.get('articleApi')}media/resources/${ownerId}/${resourceId}/`, { method: 'DELETE' })
        .then(res => dispatch({
            type: res['error-id'] ? RESOURCES_FAILED : RESOURCE_DELETED,
            data: res['error-id'] ? res.message : resourceId
        }))
        .catch(error => dispatch({
            type: RESOURCES_FAILED,
            data: error
        }));
};

export const uploadMediaStatus = (ownerId, userId, mmid) => dispatch => {
    const postConfig = {
        method: 'POST',
        body: JSON.stringify({
            owner: ownerId,
            mmid: mmid,
            userId: userId
        })
    };

    let pending = false;

    const interval = setInterval(() => {
        if (!pending) {
            kitFetch(`${config.get('articleApi')}media/resources/upload_status/`, postConfig)
                .then(json => {
                    console.log('Upload status: ', json.status);
                    pending = false;

                    if (['done', 'failed'].includes(json.status)) {
                        clearInterval(interval);
                    }

                    dispatch({
                        type: FILE_UPLOAD_STATUS,
                        data: json.status
                    });
                });
        }

        pending = true;
    }, 30000);
};
