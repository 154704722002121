import { kitFetch } from '@ekultur/fetch';

import { config } from '../../config';

export const FETCHING = 'FETCHING',
    SLUGS_SUCCEEDED = 'SLUGS_SUCCEEDED',
    SLUGS_FAILED = 'SLUGS_FAILED';

export const requestMuseumSlugs = owner => dispatch => {
    dispatch({
        type: FETCHING
    });

    kitFetch(`${config.get('articleApi')}slugs/${owner}`)
        .then(slugs => handleData(dispatch, slugs))
        .catch(error => handleError(dispatch, error));
};

export const activateMuseumSlug = (owner, slug) => dispatch => {
    dispatch({
        type: FETCHING
    });

    const postConfig = {
        method: 'POST',
        body: JSON.stringify({ owner: owner, slug: slug })
    };

    kitFetch(`${config.get('articleApi')}slugs/activate`, postConfig)
        .then(data => handleData(dispatch, data))
        .catch(error => handleError(dispatch, error));
};

export const addMuseumSlug = (owner, dimuCode, slug) => dispatch => {
    dispatch({
        type: FETCHING
    });

    const postConfig = {
        method: 'POST',
        body: JSON.stringify({ owner: owner, dimuCode: dimuCode, slug: slug })
    };

    kitFetch(`${config.get('articleApi')}slugs/add`, postConfig)
        .then(data => handleData(dispatch, data))
        .catch(error => handleError(dispatch, error));
};

const handleData = (dispatch, data) => {
    dispatch({
        type: SLUGS_SUCCEEDED,
        data: data
    });
};

const handleError = (dispatch, error) => {
    dispatch({
        type: SLUGS_FAILED,
        data: error
    });
};
