import { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { codeToToken } from '@ekultur/authentication';

import { USER_LOGGED_IN } from '../redux/actions/user';

export const Callback = () => {
    const match = useMatch('/oauth2/callback'),
        location = useLocation(),
        dispatch = useDispatch(),
        navigate = useNavigate();

    useEffect(() => {
        if (match) {
            const searchParams = new URLSearchParams(location.search);
            let props = {
                code: searchParams.get('code'),
                state: searchParams.get('state'),
                apiGateway: window._env_.REACT_APP_API_GATEWAY,
                onSuccess: data => {
                    dispatch({ type: USER_LOGGED_IN })
                    navigate(data.previousPath)
                }
            };

            if (window._env_.STRICT_REFRESH_COOKIE && window._env_.STRICT_REFRESH_COOKIE === 'true') {
                props.sameSite = true;
            }

            codeToToken(props);
        }
    }, [location, navigate, dispatch, match])

    return null
};
