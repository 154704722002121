import React from 'react';

import { Header } from './tpl/Header';
import { ArticleHeader } from './tpl/ArticleHeader';
import { OwnerLogoDesc } from './tpl/OwnerLogoDesc';
import { OwnerRelated } from './tpl/OwnerRelated';
import { OwnerFooter } from './tpl/OwnerFooter';
import { ArticleBody } from './tpl/ArticleBody';

export const Preview = ({ data, onPreviewClick, anchorObj }) => {
    const { owner_name, content } = data,
        { sections, page_type } = content;

    const handlePreviewClick = e => {
        const { target } = e;
        let id = target.id,
            p = target;

        while (id.substr(0, 8) !== '@section' && p.parentElement) {
            p = p.parentElement;
            id = p.id;
        }

        id = id.split('.');

        if (id.length > 1) {
            onPreviewClick && onPreviewClick(id[0].replace('@section_', ''), id[1].replace('block_', ''));
        }
    };

    return (
        <>
            {page_type === 'article' && <Header owner={owner_name} pageType={page_type} block={sections[0].blocks[0]} />}
            <main className="content" onClick={handlePreviewClick}>
                <article className="article">
                    <ArticleHeader block={sections[0].blocks[0]} pageType={page_type}>
                        {page_type === 'owner' && <Header owner={owner_name} pageType={page_type} block={sections[0].blocks[0]} />}
                    </ArticleHeader>
                    {page_type === 'owner' && <OwnerLogoDesc block={sections[0].blocks[1]} />}
                    {(sections[1].blocks && !!sections[1].blocks.length) && <ArticleBody blocks={sections[1].blocks} anchorObj={anchorObj} />}
                    {page_type === 'owner' && (
                        <>
                            <OwnerRelated blocks={sections[1].blocks} oName={owner_name} oParent={data.owner_parent} oChildren={data.owner_children} />
                            {sections[2] && sections[2].blocks && sections[2].blocks.length && <OwnerFooter block={sections[2].blocks[0]} />}
                        </>
                    )}
                </article>
            </main>
        </>
    );
};
