import React from 'react';
import { Trans } from 'react-i18next';

import image from '../media/image';

export const headerBlock = {
    type: 'object',
    title: <Trans ns="dm.article" i18nKey="headerblock_title">Tittel og ingress</Trans>,
    properties: {
        _editor: {
            '$ref': '#/definitions/_editor'
        },
        title: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>
        },
        author: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_author">Forfatter</Trans>
        },
        resources: {
            type: 'object',
            title: 'media',
            properties: {
                mediaList: {
                    type: 'array',
                    maxItems: 1,
                    default: [],
                    items: {
                        type: 'object',
                        properties: {
                            ...image.properties,
                            bgColor: {
                                type: 'string',
                                title: <Trans ns="dm.article" i18nKey="label_backgroundColor">Velg bakgrunnsfarge basert på farger i bildet:</Trans>
                            }
                        }
                    }
                }
            }
        },
        leadtext: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="headerblock_ingress">Ingress</Trans>
        }
    }
};
