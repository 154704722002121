import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import { WithHeader, Header } from '@ekultur/header-microfrontend';
import { login, logout } from '@ekultur/authentication';

import { config } from '../config';
import { MENU_OPENED, RENDERED } from '../redux/actions/app';
import { USER_LOGGED_OUT } from '../redux/actions/user';
import { themeOptions } from '../App/AppTheme';

const useStyleOverrides = makeStyles(theme => ({
    hamburger: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        '.articles &, .homepage &': {
            display: 'block'
        }
    }
}));

export const AppHeader = () => {
    const dispatch = useDispatch(),
        location = useLocation(),
        navigate = useNavigate(),
        { userIsAuthenticated } = useSelector(state => state.user),
        { userData, museumData } = useSelector(state => state.user),
        styleOverrides = useStyleOverrides();

    const loginHandler = () => {
        const prevPath = location.state && location.state.previousPath ? location.state.previousPath : '/';

        login({
            locationPostLogin: prevPath,
            loginHost: window._env_.REACT_APP_LOGIN_HOST
        });
    };

    const logoutHandler = () => {
        logout({
            onLogout: () => {
                dispatch({ type: USER_LOGGED_OUT });
                sessionStorage.removeItem('museum');
                navigate('/logout');
            }
        });
    };

    const museumsObject = museumData.reduce((accumulator, item) => ({
        ...accumulator,
        [item.id]: item
    }), {});

    if (0 === museumData.length) {
        return null
    } else {
        return (
            <WithHeader>
                <Header
                    appId={config.get('appId')}
                    loggedIn={userIsAuthenticated}
                    mobileOpenHandler={() => dispatch({ type: MENU_OPENED })}
                    logoutHandler={() => logoutHandler()}
                    loginHandler={loginHandler}
                    mobileOpen={false}
                    onRender={() => dispatch({ type: RENDERED })}
                    version={window._env_.REACT_APP_VERSION}
                    className={styleOverrides}
                    theme={themeOptions}
                    museums={museumsObject}
                    user={userData}
                    buildDate={`(${window._env_.REACT_APP_VERSION_DATE})`}
                />
            </WithHeader>
        );
    }
};
