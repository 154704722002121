import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { login, refreshToken } from '@ekultur/authentication';

import { USER_LOGGED_IN } from '../redux/actions/user';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

export const WithAuthentication = ({ children }) => {
    const location = useLocation(),
        dispatch = useDispatch();

    useDeepCompareEffectNoCheck(() => {
        if (!location.pathname.startsWith('/oauth2/callback')) {
            refreshToken({
                apiGateway: window._env_.REACT_APP_API_GATEWAY,
                onSuccess: () => dispatch({ type: USER_LOGGED_IN }),
                onError: () => {
                    login({
                        locationPostLogin: '/',
                        loginHost: window._env_.REACT_APP_LOGIN_HOST
                    });
                }
            })
        }
    }, [])

    return <>{ children }</>;
};
