import { Trans } from 'react-i18next';

import { uniqueKey } from '../body/uniqueKey';

const addressSchema = {
    street: {
        type: 'string',
        title: <Trans ns="dm.article" i18nKey="label_street">Gateadresse</Trans>
    },
    postalCode: {
        type: 'string',
        title: <Trans ns="dm.article" i18nKey="label_postalCode">Postnummer</Trans>
    },
    postalPlace: {
        type: 'string',
        title: <Trans ns="dm.article" i18nKey="label_municipality">Kommune</Trans>
    },
    county: {
        type: 'string',
        title: <Trans ns="dm.article" i18nKey="label_county">Fylke</Trans>
    },
    country: {
        type: 'string',
        title: <Trans ns="dm.article" i18nKey="label_country">Land</Trans>
    }
};

export const addressBlock = {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="label_address">Adresse</Trans>,
    properties: {
        type: { enum: ['museum_address'] },
        header: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>
        },
        address: {
            type: 'object',
            readOnly: true,
            properties: { ...addressSchema }
        },
        override: {
            type: 'boolean',
            title: 'Egendefinert adresse',
            default: false
        },
        settings: { '$ref': '#/definitions/settings' },
        _editor: { '$ref': '#/definitions/_editor' },
        _unique_key: uniqueKey
    },
    dependencies: {
        override: {
            oneOf: [{
                properties: {
                    override: {
                        enum: [true],
                    },
                    customAddress: {
                        type: 'object',
                        title: null,
                        properties: {
                            title: { type: 'string' },
                            title_expanded: { type: 'string' },
                            _lat: { type: 'number' },
                            _lng: { type: 'number' },
                            _locationObject: {
                                type: 'object',
                                title: null,
                                properties: {
                                    address: { type: 'string' },
                                    country: { type: 'string' },
                                    county: { type: 'string' },
                                    house_number: { type: 'number' },
                                    municipality: { type: 'string' },
                                    neighbourhood: { type: 'string' },
                                    road: { type: 'string' },
                                    town: { type: 'string' }
                                }
                            }
                        }
                    }
                },
                required: ['customAddress']
            },
            {
                properties: {
                    override: {
                        enum: [false],
                    }
                }
            }]
        }
    }
};
