import queryString from 'query-string';
import { kitFetch } from '@ekultur/fetch';

import {
    MASHUPS_REQUESTED,
    MASHUPS_FAILED,
    MASHUPS_SUCCEEDED,
    ARTIFACT_MASHUPS_REQUESTED,
    ARTIFACT_MASHUPS_FAILED,
    ARTIFACT_MASHUPS_SUCCEEDED,
    MASHUP_UPDATE_REQUESTED,
    MASHUP_UPDATE_SUCCEEDED,
    MASHUP_UPDATE_FAILED,
    MASHUP_DELETE_REQUESTED,
    MASHUP_DELETE_SUCCEEDED,
    MASHUP_DELETE_FAILED,
    MASHUP_CHANGE
} from '../types';
import { config } from '../../config';

const DIMU_SITES = 'DIMU,DIMUSE,DIMUORG';

export const requestMashups = (t, owner, mashupType = 'all', status = null, rows = 25, start = 0, site = null, timePeriod = null,
        dateFrom = null, dateTo = null, artifactType = null) => dispatch => {
    const sites = site || DIMU_SITES,
        url = recentMashupUrl(mashupType, sites),
        q = {
            owner: owner,
            rows: rows,
            status: status === 'all' ? null : status,
            inboxSchema: true,
            start: start,
            timePeriod: timePeriod,
            startDate: dateFrom,
            endDate: dateTo
        };

    dispatch({
        type: MASHUPS_REQUESTED,
        mashupType: mashupType
    });

    kitFetch(`${config.get('api')}${url}?${queryString.stringify(q)}`)
        .then(messages => dispatch({
            type: MASHUPS_SUCCEEDED,
            data: messages,
            mashupType: mashupType,
            t: t
        }))
        .catch(error => dispatch({
            type: MASHUPS_FAILED,
            data: error,
            mashupType: mashupType
        }));
};

export const requestArtifactMashups = (t, dimuCode, mashupType = 'all', status = null, site = null) => dispatch => {
    dispatch({
        type: ARTIFACT_MASHUPS_REQUESTED
    });

    const sites = site || DIMU_SITES,
        url = `${mashupUrl(mashupType, sites)}/${dimuCode}`,
        q = { inboxSchema: true, status: status === 'all' ? null : status };

    kitFetch(`${config.get('api')}${url}?${queryString.stringify(q)}`)
        .then((data) => {
            dispatch({
                type: ARTIFACT_MASHUPS_SUCCEEDED,
                dimuCode: dimuCode,
                data: data,
                t: t
            })
        })
        .catch((e) => dispatch({
            type: ARTIFACT_MASHUPS_FAILED,
            error: e
        }));
};

export const updateMashup = (m) => dispatch => {
    const url = mashupUrl(m.documentType, m.site);

    dispatch({
        type: MASHUP_UPDATE_REQUESTED,
        id: m.uniqueId
    });

    const putConfig = {
        method: 'PUT',
        body: JSON.stringify(m)
    };

    kitFetch(`${config.get('api')}${url}/${m.uniqueId}`, putConfig)
        .then(data => dispatch({
            type: MASHUP_UPDATE_SUCCEEDED,
            data: data,
            mashupType: m.documentType,
            new: false
        }))
        .catch(error => dispatch({
            type: MASHUP_UPDATE_FAILED,
            data: error,
            mashupType: m.documentType
        }));
};

export const addMashup = (m) => dispatch => {
    const url = mashupUrl(m.documentType, m.site);

    dispatch({
        type: MASHUP_UPDATE_REQUESTED,
        id: m.uniqueId
    });

    const postConfig = {
        method: 'POST',
        body: JSON.stringify(m)
    };

    kitFetch(`${config.get('api')}${url}`, postConfig)
        .then(data => dispatch({
            type: MASHUP_UPDATE_SUCCEEDED,
            data: data,
            mashupType: m.documentType,
            new: true
        }))
        .catch(error => dispatch({
            type: MASHUP_UPDATE_FAILED,
            data: error,
            mashupType: m.documentType
        }));
};

export const deleteMashup = (m) => dispatch => {
    const url = mashupUrl(m.documentType, m.site);

    dispatch({
        type: MASHUP_DELETE_REQUESTED,
        id: m.uniqueId
    });

    const deleteConfig = {
        method: 'DELETE'
    };

    kitFetch(`${config.get('api')}${url}/${m.uniqueId}`, deleteConfig)
        .then(data => dispatch({
            type: data.deleted ?
                MASHUP_DELETE_SUCCEEDED :
                MASHUP_DELETE_FAILED,
            uniqueId: m.uniqueId,
            deleted: true
        }))
        .catch(error => dispatch({
            type: MASHUP_DELETE_FAILED,
            data: error,
            uniqueId: m.uniqueId,
            deleted: false
        }));
};

export function mashupChange(mashupType, mashups) {
    return dispatch => {
        dispatch({
            type: MASHUP_CHANGE,
            mashups: mashups,
            mashupType: mashupType
        });
    }
};

const recentMashupUrl = (mashupType, site) => {
    return config.get('recentMashupsUrl')
        .replace('<TYPE>', mashupType)
        .replace('<SITE>', site);
};

const mashupUrl = (documentType, site) => {
    return config.get('mashupsUrl')
        .replace('<TYPE>', documentType)
        .replace('<SITE>', site);
};
