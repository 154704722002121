export const formatDate = d => {
    const parseISOString = s => {
        const b = s.split(/\D+/);
        return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    };

    const formatDateString = ds => {
        return `${ds.substr(0, 4)}-${ds.substr(4, 2)}-${ds.substr(6, 2)}T${ds.substr(9, 2)}:${ds.substr(11, 2)}:${ds.substr(13, 2)}.${ds.substr(16, 3)}`;
    };

    const date = new Date(d);

    if (date) {
        return date.toLocaleString();
    }

    return parseISOString(formatDateString(d)).toLocaleString();
};
