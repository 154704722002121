import { Trans } from 'react-i18next';

import { uniqueKey } from '../body/uniqueKey';

export const museumDescriptionBlock = {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="label_description">Beskrivelse</Trans>,
    properties: {
        type: { enum: ['museum_description'] },
        title: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>
        },
        titleHidden: {
            type: 'boolean',
            title: <Trans ns="dm.article" i18nKey="label_hide-title">Skjul tittel</Trans>,
            enum: [true, false],
            default: false
        },
        bodytext: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_description">Beskrivelse</Trans>
        },
        _editor: { '$ref': '#/definitions/_editor' },
        _unique_key: uniqueKey
    }
};
