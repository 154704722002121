import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setFinderMode, setExportEnabled } from '../redux/actions/layout';
import {
    requestArtifactMashups, updateMashup,
    addMashup, deleteMashup
} from '../redux/actions/mashup';
import { ProgressIndicator } from '../common/ProgressIndicator';
import { Error } from '../common/Error';
import { DiMuCard } from './DiMuCard';
import { MashupEditor } from './MashupEditor';

export const Mashup = () => {
    const dispatch = useDispatch(),
        { t, ready } = useTranslation('dm.mashupTypes', { useSuspense: false }),
        { userData } = useSelector(state => state.user),
        { currentInboxFilter } = useSelector(state => state.layout),
        { fetching, artifact, error } = useSelector(state => state.mashups.active),
        { mashupType, dimuCode } = useParams();

    useEffect(() => {
        dispatch(setFinderMode('artifact-mashups', currentInboxFilter));
        dispatch(setExportEnabled(false));
    }, [dispatch, currentInboxFilter]);
    
    useEffect(() => {
        dispatch(requestArtifactMashups(t, dimuCode, mashupType || 'all'));
    }, [dispatch, t, dimuCode, mashupType]);

    const onUpdate = message => {
        dispatch(updateMashup(message));
    };

    const onReply = message => {
        dispatch(addMashup(message));
    };

    const onDelete = message => {
        dispatch(deleteMashup(message));
    };

    if (error) {
        return <Error />;
    } else if (!ready || fetching || !artifact) {
        return <ProgressIndicator />;
    }

    const { title, description, url, imageUrl, documentType, identifier } = artifact && artifact.attachments[0];

    return (
        <>
            <DiMuCard identifier={identifier} title={title} description={description} url={url} imageUrl={imageUrl} documentType={documentType} />
            <MashupEditor artifact={artifact} onUpdate={onUpdate} onDelete={onDelete} onReply={onReply} user={userData} />
        </>
    );
};
