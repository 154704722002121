export const MuseumMap = ({ coordinates, customCoordinates, show, override }) => {

    const isValidCoordinates = (coords) => {
        return typeof coords?.latitude === "number" && typeof coords?.longitude === "number";
    };

    const renderMap = () => {
        if (!show) {
            return null;
        }

        const { latitude, longitude } = override ? (customCoordinates || coordinates) : (coordinates || customCoordinates),
            map = ['https://maps.googleapis.com/maps/api/staticmap?',
                'zoom=13&',
                'size=360x240&',
                'scale=2&',
                `markers=size:tiny|color:red|label:|${latitude},${longitude}&`,
                'style=element:labels.text.fill|color:0x616161&',
                'style=element:labels.text.stroke|color:0xf5f5f5&',
                'map_id=5b5bbff3dd7c0c7a&',
                `key=${window._env_.REACT_APP_GOOGLE_MAPS_KEY}`].join('');

        return (
            <div style={{ backgroundImage: `url(${map})` }}></div>
        )
    };

    if ((isValidCoordinates(coordinates) && !override) || (isValidCoordinates(customCoordinates) && override)) {
        return (
            <div id='@section_1.blocks_1' className='header-media__secondary__map'>
                <div className='header-media__media'>
                    {renderMap()}
                </div>
            </div>
        )
    } else {
        return null;
    }
}
