import { OWNER_SELECTED_BY_ID, MENU_OPENED, MENU_CLOSED, RENDERED } from '../actions/app';

const initialState = {
    menuOpen: false,
    rendered: false,
    pathname: '/',
    ownerUniqueId: null
};

export const app = (state = initialState, action = {}) => {
    switch (action.type) {
        case OWNER_SELECTED_BY_ID:
            return {
                ...state,
                ownerUniqueId: action.mid
            };
        case RENDERED:
            return {
                ...state,
                rendered: true
            }
        case MENU_OPENED:
            return {
                ...state,
                menuOpen: true,
            };
        case MENU_CLOSED:
            return {
                ...state,
                menuOpen: false,
            };
        default:
            return { ...state }
    }
};
