import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import { svgToSrc } from '@ekultur/ui-schema-components';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
    logoDesc: {
        display: 'flex',
        position: 'relative',
        margin: '-123px auto 0',
        width: '80%',
        maxWidth: '1064px',
        minHeight: '246px',
        background: '#ccc',
        '.anchorMode &': {
            opacity: .5,
            cursor: 'not-allowed',
            '& a': {
                pointerEvents: 'none'
            }
        },
        '& > figure': {
            display: 'flex',
            flex: '0 0 246px',
            '& > img': {
                width: '50%',
                height: 'auto',
                margin: 'auto'
            }
        },
        '&.landscape': {
            flexWrap: 'wrap',
            '& > figure.logo': {
                flex: '0 0 100%',
                height: spacing(9),
                paddingTop: spacing(4),
                boxSizing: 'border-box',
                '& > img': {
                    width: 'auto',
                    height: '100%'
                }
            },
            '& > div > nav': {
                    marginTop: '1.5em'
            }
        },
        '& > div': {
            flex: '1 1 auto',
            display: 'flex',
            flexWrap: 'wrap',
            padding: '48px',
            '& > p': {
                flex: '0 0 100%',
                lineHeight: 1.5
            },
            '& > nav a': {
                border: 'none',
                borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
                textDecoration: 'none',
                fontWeight: 'bold',
                color: 'inherit',
                borderBottomColor: 'inherit',
                pointerEvents: 'none',
                '&:not(:first-child)': {
                    margin: '6px'
                }
            }
        }
    }
}));

export const OwnerLogoDesc = ({ block }) => {
    const classes = useStyles(),
        { t } = useTranslation(['dm.article'], { useSuspense: false }),
        { homepage, resources, presentation, settings } = block,
        textColor = settings.colorpickercontrast,
        logo = resources && resources.logo && resources.logo.length && resources.logo[0],
        svg = logo && logo.mimetype === 'image/svg+xml';

    let url = logo && logo.url;

    if (logo && logo.mimetype.indexOf('png') > -1) {
        url += '?mediaType=image/png';
    }

    const renderLink = (item, index) => {
        const { title, url, _unique_key } = item;

        return <a key={index} href={url || _unique_key}>{title}</a>;
    };

    return (
        <section id="@section_0.block_1" className={`${classes.logoDesc} ${settings && settings.layout}`} style={{background: settings.colorpicker, color: settings.colorpickercontrast}}>
            <figure className="logo">
                <img src={svg ? svgToSrc(logo.source) : url} alt='owner logo' />
            </figure>
            <div style={{color: (textColor && (textColor === 'black' ? '#000' : '#FFF')) || 'rgb(58, 46, 45)'}}>
                <span>{presentation}</span>
                <nav>
                    {homepage && renderLink({ title: t('homepage', 'Hjemmeside'), url: homepage })}
                    {resources.linkList && resources.linkList.map(renderLink)}
                </nav>
            </div>
        </section>
    );
};
