import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { StatisticsReportDownloads, StatisticsReportMetricsTable } from './plausible';
import {
    StatisticsReportDownloads as GAStatisticsReportDownloads,
    StatisticsReportMetricsTable as GAStatisticsReportMetricsTable
} from './';
import { shouldUsePlausible } from './apiSelector';

export const StatisticsFinder = () => {
    const location = useLocation(),
        { currentDetail } = useSelector(state => state.layout);

    if (shouldUsePlausible(currentDetail)) {
        return (
            location.pathname === '/statistics/downloads'
                ? (
                    <StatisticsReportDownloads viewType='report' />
                ) : (
                    <div className='admin-statistics-wrapper'>
                        <StatisticsReportMetricsTable viewType='report' />
                    </div>
                )
        );
    }

    return (
        location.pathname === '/statistics/downloads'
            ? (
                <GAStatisticsReportDownloads viewType='report' />
            ) : (
                <div className='admin-statistics-wrapper'>
                    <GAStatisticsReportMetricsTable viewType='report' />
                </div>
            )
    );
};
