import React from 'react';
import { Card, CardMedia, CardContent, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { TextInfoCardContent } from './TextInfoCardContent';
import { useCardContentStyles } from './CardContentStyles';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    root: {
        // marginLeft: spacing(1),
        marginBottom: spacing(2),
        transition: '0.3s',
        boxShadow: '2px 2px 5px rgba(0, 0, 0, .1)',
        position: 'relative',
        overflow: 'initial',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        [breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    media: {
        flex: '0 0 auto',
        width: '75px',
        marginTop: 0,
        marginLeft: spacing(1.5),
        height: 0,
        paddingBottom: '75px',
        // borderRadius: spacing(2),
        backgroundColor: '#ddd',
        position: 'relative',
        // [breakpoints.up('md')]: {
        //     transform: 'translateX(-8px)'
        // }
        /* ,
        '&:after': {
            content: '" "',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            // backgroundImage: 'linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)',
            // backgroundColor: 'grey',
            borderRadius: spacing(2),
            opacity: 0.2
        } */
    },
    content: {
        padding: spacing(1),
        paddingLeft: spacing(2),
        '&:last-child': {
            paddingBottom: spacing(1)
        }
    },
    link: {
        marginTop: '0.5em',
        display: 'block',
        cursor: 'pointer',
        fontSize: '0.8em'
    }
}));

export const DiMuCard = ({ identifier, title, description, url, imageUrl, documentType }) => {
    const classes = useStyles(),
        theme = useTheme(),
        {
            // button: buttonStyles,
            ...cardContentStyles
        } = useCardContentStyles(theme);

    return (
        <Card className={classes.root}>
            <CardMedia
                className={classes.media}
                image={imageUrl ? `${imageUrl}?dimension=167x167` : 'https://dms-cf-09.dimu.org/image/02349w5hFUHV?mediaType=image/svg'}
            />
            <CardContent className={classes.content}>
                <TextInfoCardContent
                    classes={cardContentStyles}
                    // overline={'28 MAR 2019'}
                    heading={`${identifier} - ${title}`}
                    body={description}
                    type={documentType}
                />
                <Link className={classes.link} onClick={() => window.open(url, '_blank')}>{url}</Link>
            </CardContent>
        </Card>
    );
};