import { imageWithSettings } from '../media/imageWithSettings';

export const veObject = {
    type: 'object',
    required: ['type'],
    properties: {
        type: { enum: ['VE'] },
        url: { type: 'string' },
        uuid: { type: 'string', readOnly: true },
        artifactType: {
            type: 'string',
            title: '',
            readOnly: true
        },
        objectId: {
            type: 'string',
            title: '',
            readOnly: true
        },
        description: { type: 'string' },
        image_id: { type: 'string' },
        source: { type: 'string' },
        title: {
            type: 'string',
            title: 'Bildetekst'
        },
        image_count: { type: 'integer' },
        identifier: { type: 'string' },
        producer: {
            type: 'string',
            title: 'Fotograf'
        },
        owner_name: { type: 'string' },
        local: {
            type: 'object',
            title: '',
            properties: {
                credit: {
                    type: 'string',
                    title: 'Produsent',
                    readOnly: true,
                    disabled: true
                }
            }
        },
     
        media: {
            type: 'object',
            properties: {
                mediaList: {
                    type: 'array',
                    default: [],
                    items: imageWithSettings
                }
            }
        }
    }
};
