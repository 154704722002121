import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { MuseumItem } from './MuseumItem';

const useStyles = makeStyles(() => ({
    ownersList: {
        overflowX: 'hidden'
    }
}));

export const MuseumsList = ({ museums, selectMuseumHandler }) => {
    const classes = useStyles();
    
    return (
        <Box className={classes.ownersList} >
            <List>
                {museums
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(museum => <MuseumItem key={museum.id} museum={museum} onSelect={selectMuseumHandler} />)
                }
            </List>
        </Box>
    );
};
