import React from 'react';
import { Trans } from 'react-i18next';

import { bodyBlock } from './bodyBlock';

export const bodySection = type => {
    return {
        type: 'object',
        title: <Trans ns="dm.article" i18nKey="section_body">Innhold</Trans>,
        properties: {
            type: { enum: ['article__body'] },
            _editor: {
                '$ref': '#/definitions/_editor'
            },
            blocks: {
                type: 'array',
                title: null,
                items: bodyBlock(type)
            }
        }
    }
};
