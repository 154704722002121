import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';

import { getValueFromQueryString } from '../redux/functions/query';
import { setFinderMode, setExportEnabled } from '../redux/actions/layout';

import { RealtimeStatistics } from './plausible/RealtimeStatistics';
import { UserMuseums } from './UserMuseums';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(10)
    }
}));

export const UserDashboard = () => {
    const dispatch = useDispatch(),
        classes = useStyles(),
        period = getValueFromQueryString('period');

    useEffect(() => {
        dispatch(setFinderMode('dashboard', period));
        dispatch(setExportEnabled(false));
    }, [dispatch, period]);

    return (
        <Grid container className={classes.root} sx={{width: 'auto'}} spacing={1}>
            <Grid item sm={12} md={5} lg={5}>
                <UserMuseums />
            </Grid>
            <Grid item sm={12} md={7} lg={7}>
                <RealtimeStatistics />
            </Grid>
        </Grid>
    );
};
