import React from 'react';
import { useTranslation } from "react-i18next";
import { ListItem, Typography, Chip, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';

import { deleteArticle } from '../redux/actions/article';
import { useMashupStyles } from '../Mashup/mashupStyles';

import { ArticleListActions } from './ArticleListActions/ArticleListActions';


const useStyles = makeStyles(({spacing}) => ({
    typeChip: props => ({ ...props.typeChip }),
    meta: props => ({ ...props.meta, marginTop: 0 }),
    message: props => ({ ...props.message, fontSize: '.9rem', fontWeight: 'bold' }),
    listItem: {
        padding: 0
    },
    cardContentWrapper: {
        display: 'flex',
        flex: 1,
        padding: spacing(1),
        paddingRight: 0
    },
    cardContent: {
        position: 'absolute',
        width: '80%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        paddingLeft: '85px'
    },
    leadText: {
        height: '1.2rem',
        fontSize: '.8rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    media: {
        minWidth: '75px',
        marginTop: 0,
        marginRight: spacing(1),
        height: 0,
        paddingBottom: '75px',
        backgroundColor: '#ddd',
        position: 'relative',
        '&.dm-icon': {
            backgroundSize: 'auto'
        }
    }
}));


export const ArticleListCard = ({ article, onSelect }) => {
    const dispatch = useDispatch(),
        theme = useTheme(),
        { ...mashupStyles } = useMashupStyles(theme),
        classes = useStyles(mashupStyles),
        { t } = useTranslation(['dm.articleTypes'], { useSuspense: false }),
        { ownerUniqueId } = useSelector(state => state.app),
        created = new Date(article.updatedAt || article.createdAt),
        dateTime = `${created.toLocaleDateString()} ${created.toLocaleTimeString()}`,
        headerImg = article.headerImage || 'https://pdms.dimu.org/image/02349w5hFUHV?mediaType=image/svg';

    const handleSelect = id => {
        onSelect && onSelect(id);
    };

    const onDelete = () => {
        dispatch(deleteArticle(article.id, ownerUniqueId));
    };

    const openPreview = e => {
        const url = `${window._env_.REACT_APP_DM4_URL}/${article.status === 'draft'
            ? 'article/' + article.id
            : (article.type === 'article'
                ? article.dimu_unique_id
                : 'owners/' + article.owner_identifier
            )}`;

        window.open(url);
    };

    return (
        <ListItem button component="li" className={classes.listItem}>
            <div className={classes.cardContentWrapper} onClick={() => handleSelect(article.id)}>
                <CardMedia
                    className={`${classes.media}${!headerImg ? ' dm-icon' : ''}`}
                    image={headerImg ? headerImg : 'https://pdms.dimu.org/image/02349w5hFUHV?mediaType=image/svg'}
                />
                <div className={classes.cardContent}>
                    <div className={classes.meta}>
                        <Chip size="small" variant="outlined" label={t(`dm.articleTypes:${article.type}`)} className={classes.typeChip} />
                        <Chip size="small" variant="outlined" label={t(`dm.article:${article.status}`)} className={classes.typeChip} />
                        {(article.updatedAt || article.createdAt) && <Typography component={'span'} >{dateTime} {t('dm.article:by', 'av')} {article.createdBy}</Typography>}
                    </div>
                    <Typography className={classes.message}>
                        {article.title}
                    </Typography>
                    <Typography noWrap className={classes.leadText}>
                        {article.leadText}
                    </Typography>
                </div>
            </div>
            {article.type !== 'museum' && <ArticleListActions onDelete={onDelete} openPreview={openPreview} />}
        </ListItem>
    );
};
