import {
    FOLDERS_REQUESTED, FOLDERS_SUCCEEDED, FOLDERS_FAILED,
    FOLDER_OBJECTS_REQUESTED, FOLDER_OBJECTS_SUCCEEDED, FOLDERS_OBJECTS_FAILED,
    WIKIMEDIA_EXPORT_REQUESTED, WIKIMEDIA_EXPORT_SUCCEEDED, WIKIMEDIA_EXPORT_FAILED,
    WIKIMEDIA_EXPORT_STATUS_REQUESTED, WIKIMEDIA_EXPORT_STATUS_SUCCEEDED, WIKIMEDIA_EXPORT_STATUS_FAILED,
    WIKIMEDIA_EXPORT_STATUSES_SUCCEEDED, SET_ACTIVE_FOLDER_TITLE
} from '../actions/folders';

const initialState = {
    name: '',
    dimu_unique_id: '',
    rows: [],
    rowCount: 0,
    fetching: null,
    exportStatus: null,
    error: false
}

export const folderList = (state = initialState, action) => {
    switch (action.type) {
        case FOLDERS_REQUESTED:
            return {
                ...state,
                rows: [],
                rowCount: 0,
                fetching: true,
                error: false
            };
        case FOLDERS_SUCCEEDED:
            return {
                rows: action.data.folders,
                rowCount: action.data.rowCount,
                fetching: false,
                error: false
            };
        case WIKIMEDIA_EXPORT_STATUSES_SUCCEEDED:
            return {
                ...state,
                rows: state.rows.map(r => (
                    {
                        ...r,
                        exportStatus: action.data.find(s => s.id === r.dimu_unique_id).status || null
                        // exportStatus: action.data.find(s => s.id === '0210911458747').status || null
                    }))
            }
        case FOLDERS_FAILED:
            return {
                ...state,
                rows: [],
                rowCount: 0,
                fetching: false,
                error: true
            };
        default:
            return {
                ...state
            }
    }
};

export const folderIds = (state, action) => {
    if (action.type === FOLDERS_SUCCEEDED) {
        const ids = action.data.folders.map(f => f.dimu_unique_id);
        return ids.join();
    }

    return null;
}

export const folderObjects = (state = initialState, action) => {
    switch (action.type) {
        case FOLDER_OBJECTS_REQUESTED:
            return {
                ...state,
                rows: [],
                rowCount: 0,
                fetching: true,
                error: false
            };
        case FOLDER_OBJECTS_SUCCEEDED:
            return {
                name: action.data.name,
                dimu_unique_id: action.data.dimu_unique_id,
                rows: action.data.objects,
                rowCount: action.data.rowCount,
                fetching: false,
                error: false
            };
        case FOLDERS_OBJECTS_FAILED:
            return {
                ...state,
                name: '',
                dimu_unique_id: '',
                rows: [],
                rowCount: 0,
                fetching: false,
                error: true
            };
        default:
            return {
                ...state
            }
    }
};

const initialWmState = {
    id: '',
    jobs: [],
    registered: 0,
    fetching: null,
    status: null
}

export const wikimediaExport = (state = initialWmState, action) => {
    switch (action.type) {
        case WIKIMEDIA_EXPORT_REQUESTED:
            return {
                ...state,
                id: '',
                jobs: [],
                registered: 0,
                fetching: true,
                status: null
            };
        case WIKIMEDIA_EXPORT_SUCCEEDED:
            return {
                id: action.data.id,
                jobs: action.data.jobs,
                registered: action.data.registered,
                fetching: false,
                status: action.data.status
            };
        case WIKIMEDIA_EXPORT_FAILED:
            return {
                ...state,
                id: '',
                jobs: [],
                registered: 0,
                fetching: false,
                status: null
            };
        default:
            return {
                ...state
            }
    }
};

const initialWmStatusState = {
    fetching: null,
    status: null
}

export const wikimediaExportStatus = (state = initialWmStatusState, action) => {
    switch (action.type) {
        case WIKIMEDIA_EXPORT_STATUS_REQUESTED:
            return {
                ...state,
                fetching: true,
                status: null
            };
        case WIKIMEDIA_EXPORT_STATUS_SUCCEEDED:
            return {
                fetching: false,
                status: action.data.status
            };
        case WIKIMEDIA_EXPORT_STATUS_FAILED:
            return {
                ...state,
                fetching: false,
                status: null
            };
        default:
            return {
                ...state
            }
    }
};

export const activeFolderTitle = (state = null, {type, title} = {}) => {
    if (type === SET_ACTIVE_FOLDER_TITLE) {
        return title;
    }

    return state;
};
