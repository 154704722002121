import { licenseMapper } from '../../helpers/licenseMapper';

export const imageMapper = item => {
    const setCredit = () => {
        if (item.data && item.data.photographer) {
            return item.data.photographer;
        } else if (item.producer) {
            return item.owner_name ? `${item.producer} / ${item.owner_name}` : item.producer;
        } else if (item.owner_name) {
            return item.owner_name;
        }
    };

    return {
        ...item,
        image_id: item.dms_id,
        licenses: (item.licenses && licenseMapper(item.licenses[0])) || [],
        local: {
            credit: setCredit(),
            description: (item.data && item.data.description) || item.description || item.titledesc
        },
        settings: {
            size: 'medium'
        }
    };
};
