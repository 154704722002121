import React from 'react';
import { Trans } from 'react-i18next';

export const departmentsBlock = {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="departmentblock_title">Avdelinger</Trans>,
    properties: {
        type: { enum: ['owner_museums'] },
        title: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>
        },
        titleHidden: {
            type: 'boolean',
            title: <Trans ns="dm.article" i18nKey="label_hide-title">Skjul tittel</Trans>,
            enum: [true, false],
            default: false
        },
        settings: {
            type: 'object',
            properties: {
                visibility: {
                    type: 'string',
                    enum: [
                        'visible',
                        'hidden'
                    ],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="visible">Synlig</Trans>,
                        <Trans ns="dm.article" i18nKey="hidden">Skjult</Trans>
                    ],
                    default: 'visible'
                }
            }
        }
    }
};
