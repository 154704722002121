import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CopyIcon from '@mui/icons-material/ContentCopyRounded';
import { Button, IconButton, Tooltip } from '@mui/material';

import { useDeepCompareEffect } from 'react-use';

import { buildQuery } from '../../helpers/buildQuery';

let timers = { filters: null, field: null, typing: null };

export const DmSearchItemTemplate = ({ props, classes }) => {
    const { properties } = props,
        [expanded, setExpanded] = useState(false),
        qEl = properties.find(x => x.name === 'query'),
        sEl = properties.find(x => x.name === 'settings'),
        fltrEl = properties.find(x => x.name === 'filter'),
        q = qEl.content.props.formData,
        fltr = fltrEl.content.props.formData || {},
        [filter, setFilter] = useState(fltr),
        [changeFrom, setChangeFrom] = useState();

    const getFilterValueFromQuery = (qArr, pos, int = false) => {
        const fStr = qArr.splice(pos, 1)[0],
            f = fStr.split(':')[1] || 0;

        return int ? parseInt(f) : f;
    };

    const copySearchUrl = () => {
        const updateClipboard = () => {
            let params = buildQuery(q, fltr).split(' ');
            let query = [];

            params = params.map(x => {
                const [k, v] = x.split(':');

                if (k && v) {
                    return !['rows'].includes(k) ? `${k}=${v}` : false;
                } else {
                    query.push(x);
                    return false;
                }
            }).filter(x => !!x);

            const url = `/search/?q=${query.join('+')}&${params.join('&')}`;

            navigator.clipboard.writeText(url).then(() => {}, err => {
                console.log(`copy failed! ${err}`);
            });
        };

        navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
            if (result.state === 'granted' || result.state === 'prompt') {
                updateClipboard();
            }
        });
    };

    // deep compare filters to avoid rerenders
    useDeepCompareEffect(() => {
        if (fltr){
            setFilter(fltr);
            setChangeFrom('fltr');
        }
    }, [fltr]);

    useEffect(() => {
        if (q) {
            setChangeFrom('q');
        }
    }, [q]);

    useEffect(() => {
        if (!changeFrom) {
            return;
        }

        console.log(`changeFrom: ${changeFrom}`);

        if (changeFrom === 'q') {
            if (timers.filters) {
                clearTimeout(timers.filters);
            }

            timers.filters = setTimeout(() => {
                const qArr = q ? q.split(' ') : [],
                    fltrProps = fltrEl.content.props,
                    fltrEntries = Object.entries(fltrEl.content.props.schema.properties);

                let newFltr = { ...fltrProps.formData };

                for (const [name, settings] of fltrEntries) {
                    const pos = qArr.findIndex(x => x.split(':')[0] === name);

                    if (pos !== -1) {
                        newFltr[name] = getFilterValueFromQuery(qArr, pos, settings.type === 'integer');
                    } else {
                        newFltr[name] = settings.type === 'integer' ? 0 : '';
                    }
                }

                setChangeFrom();
                fltrProps.onChange(newFltr);
            }, 500);
        } else {
            if (timers.field) {
                clearTimeout(timers.field);
            }
        
            timers.field = setTimeout(() => {
                const newQ = buildQuery(q, filter);
                console.log(`committing change to q`);
                setChangeFrom();
                qEl.content.props.onChange(newQ);
            }, 100);
        }

    }, [changeFrom, q, filter, fltrEl.content.props, qEl.content.props]);

    if (typeof q === 'undefined') {
        return null;
    }

    return (
        <div className={`${classes.searchItem} greyBox`}>
            {!expanded && (
                <div className='titleWrapper' onClick={() => setExpanded(true)}>
                    <SearchIcon />
                    <h5>«{buildQuery(q, fltr)}»</h5>
                </div>
            )}
            {expanded && (
                <div className='fieldWrapper'>
                    {properties.map(el => {
                        if (!['type', 'settings'].includes(el.name)) {
                            return el.content;
                        }

                        return null;
                    })}
                    <Button
                        className='ok'
                        onClick={() => setExpanded(false)}
                        color='primary'
                        sx={{ marginTop: '.5em' }}
                    >
                        Lukk søk
                    </Button>
                </div>
            )}
            <Tooltip title='Kopier url til søket i Dm' sx={{height: '100%'}}>
                <IconButton onClick={copySearchUrl}>
                    <CopyIcon color='info' />
                </IconButton>
            </Tooltip>
            {sEl && sEl.content}
        </div>
    );
};
