import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Link from '@mui/material/Link';
import TableSortLabel from '@mui/material/TableSortLabel';
import { CircularProgress, TableContainer } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/CheckCircle';

import { dmsUrl } from '@ekultur/ui-schema-components';

import { setFinderMode, setExportEnabled } from '../redux/actions/layout';
import { requestFolders, requestWikimediaExportStatuses, setActiveFolder } from '../redux/actions/folders';
import { ProgressIndicator } from '../common/ProgressIndicator';
import { WithProgressOverlay } from '../common/WithProgressOverlay';
import { Error } from '../common/Error';
import { formatDate } from '../common/Date';

const useStyles = makeStyles({
    root: {
        overflowX: 'auto',
        padding: 10
    },
    row: {
        cursor: 'pointer'
    },
    buttonProgress: {
        '&.failed': {
            color: 'red'
        },
        '&.finished': {
            color: 'green'
        }
    }
});

const isLastPage = (rowCount, paging) =>{
    return Math.floor((rowCount - 1) / paging.rows) === paging.page;
}

const displaysRowsText = ({ from, to, count }) => {
    const calculatedTo = to === -1 ? count : to;
    return `${from}-${calculatedTo} av ${count}`;
};


export const Folders = () => {
    const { t, ready } = useTranslation('dm.common', { useSuspense: false }),
        dispatch = useDispatch(),
        [order, setOrder] = useState({ by: 'updatedAt', descending: true }),
        [paging, setPaging] = useState({ rows: 10, page: 0 }),
        { userData } = useSelector(state => state.user),
        { ownerUniqueId } = useSelector(state => state.app),
        { rows, rowCount, fetching, error } = useSelector(state => state.folderList),
        folderIds = useSelector(state => state.folderIds),
        navigate = useNavigate(),
        classes = useStyles();

    const orderHandler = (headerName) => {
        if (headerName === order.by) {
            setOrder({
                ...order,
                descending: !order.descending,
            });
        } else {
            setOrder({
                by: headerName,
                descending: true,
            });
        }
    };

    const getDirection = (headerName) => {
        if (order.by === headerName) {
            return order.descending ? 'desc' : 'asc';
        } else {
            return 'desc';
        }
    };

    const changePageHandler = (newPage) => {
        setPaging({
            ...paging,
            page: newPage,
        });
    };

    const changeRowsPerPageHandler = (event) => {
        setPaging({
            rows: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    const onSelectFolder = row => {
        dispatch(setActiveFolder(row.title));
        navigate(`/folders/${row.uuid}`);
    };

    useEffect(() => {
        dispatch(setFinderMode('folders'));
        dispatch(setExportEnabled(false));
    }, [dispatch]);

    useEffect(() => {
        if (ownerUniqueId && userData.uniqueId) {
            dispatch(requestFolders(ownerUniqueId, userData.uniqueId, order.by, order.descending ? 'desc' : 'asc', paging.page * paging.rows, paging.rows));
        }
    }, [dispatch, ownerUniqueId, userData.uniqueId, order.by, order.descending, paging.page, paging.rows]);

    useEffect(() => {
        if (folderIds) {
            dispatch(requestWikimediaExportStatuses(ownerUniqueId, folderIds));
        }
    }, [dispatch, ownerUniqueId, folderIds]);

    if (!ready) {
        return <ProgressIndicator />;
    }
    
    if (error) {
        return <Error />;
    }

    return (
        <WithProgressOverlay loading={fetching}>
            <h1>{rowCount} mapper</h1>
            <Paper className={classes.root}>
                <TableContainer>
                    <Table>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell key="emptyCell" />
                                {['Title', 'createdAt', 'updatedAt', 'Status', 'Wikimedia', ''].map((header) => (
                                    <TableCell key={header}>
                                        <TableSortLabel
                                            active={order.by === header}
                                            direction={getDirection(header)}
                                            onClick={() => orderHandler(header)}
                                            disabled={fetching}
                                        >
                                            {t(header)}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.dimu_unique_id}>
                                    <TableCell>
                                        <Link
                                            href={`https://digitaltmuseum.org/${row.dimu_unique_id}`}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {row.image_id && <img src={`${dmsUrl({ image_id: row.image_id })}?dimension=57x57-crop`} alt={row.image_id} />}
                                        </Link>
                                    </TableCell>
                                    {['title', 'createdAt', 'updatedAt', 'status'].map((field, index) => (
                                        <TableCell key={index} className={classes.row} onClick={() => onSelectFolder(row)}>
                                            {['createdAt', 'updatedAt'].includes(field)
                                                ? formatDate(row[field])
                                                : row[field]}
                                        </TableCell>
                                    ))}
                                    <TableCell key="wikimedia" className={classes.row} onClick={() => onSelectFolder(row)}>
                                        {row['exportStatus'] === 'in progress' && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        {row['exportStatus'] === 'failed' && <ErrorIcon size={24} className={`${classes.buttonProgress} failed`} />}
                                        {row['exportStatus'] === 'finished' && <CheckIcon size={24} className={`${classes.buttonProgress} finished`} />}
                                    </TableCell>
                                    <TableCell key={`chevron-${row.id}`} className={classes.row} onClick={() => onSelectFolder(row)}>
                                        <ChevronRightIcon color='action' />
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={rowCount}
                    rowsPerPage={paging.rows}
                    page={paging.page}
                    onPageChange={(event, newPage) => changePageHandler(newPage)}
                    onRowsPerPageChange={changeRowsPerPageHandler}
                    backIconButtonProps={{ disabled: fetching || paging.page === 0 }}
                    nextIconButtonProps={{ disabled: fetching || isLastPage(rowCount, paging) }}
                    SelectProps={{ disabled: fetching }}
                    labelRowsPerPage="Rader per side:"
                    labelDisplayedRows={displaysRowsText}
                />
            </Paper>
        </WithProgressOverlay>
    );
};
