import React from 'react';
import { useSelector } from 'react-redux';

import { useHeaderState } from '@ekultur/header-microfrontend';

import { ProgressIndicator } from '../common/ProgressIndicator';

export const WhenHeaderIsReady = ({ children }) => {
    const { ready } = useHeaderState(),
        { rendered } = useSelector(state => state.app);

    if (!ready || !rendered) {
        return <ProgressIndicator />
    }

    return <>{children}</>;
};
