import { dmSearch } from '../schema/body/dmSearch';

export const buildQuery = (q, f) => {
    const { properties } = dmSearch;
    let qArr = [];

    if (q) {
        qArr.push(q);
    }

    if (f) {
        Object.keys(f).map(k => {
            const idx = qArr.findIndex(x => x.match(`${k}:`));
            let fltrStr = f[k] === '' ? '' : `${k}:${f[k]}`;

            if (f[k] || f[k] === '') {
                if (k !== 'owner') {
                    fltrStr = fltrStr.toLowerCase();
                }

                if (idx < 0 && fltrStr) {
                    qArr.push(fltrStr);
                } else if (idx >= 0) {
                    const propType = properties.filter.properties[k].type,
                        re = new RegExp(`${k}:${propType === 'integer' ? '\\d+' : '[^\\s]+'}`, 'g'),
                        r = qArr[0].match(re);

                    if (r) {
                        qArr[0] = qArr[0].replace(r[0], fltrStr).replaceAll('  ', ' ').trim();
                    }
                }
            }

            return k;
        });
    }

    return qArr.join(' ');
};
