import { Trans } from 'react-i18next';

import { dmBlock } from '../body/dmBlock';
import { dmEntry } from '../body/dmEntry';
import { dmObject } from '../body/dmObject';
import { dmSearch } from '../body/dmSearch';

export const museumTrendingSection = {
    type: 'object',
    title: <Trans ns="dm.article" i18nKey="label_trending">Aktuelt</Trans>,
    required: ['type'],
    properties: {
        type: { enum: ['museum_trending'] },
        _editor: { '$ref': '#/definitions/_editor' },
        searches: {
            type: 'array',
            minItems: 1,
            items: {
                type: dmBlock.type,
                required: dmBlock.required,
                title: dmBlock.title,
                properties: {
                    type: {
                        enum: ['trending_search'],
                        enumNames: [<Trans ns="dm.article" i18nKey="label_trending_search">Aktuelt-søk</Trans>]
                    },
                    title: dmBlock.properties.title,
                    titleHidden: dmBlock.properties.titleHidden,
                    resources: {
                        type: 'object',
                        properties: {
                            entryList: {
                                type: 'array',
                                default: [],
                                items: {
                                    type: dmEntry.type,
                                    required: dmEntry.required,
                                    properties: dmEntry.properties,
                                    dependencies: {
                                        type: {
                                            oneOf: [
                                                dmObject,
                                                {
                                                    type: dmSearch.type,
                                                    required: dmSearch.required,
                                                    properties: {
                                                        type: dmSearch.properties.type,
                                                        filter: dmSearch.properties.filter,
                                                        query: dmSearch.properties.query
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                }
                            }
                        }
                    },
                    settings: {
                        type: 'object',
                        properties: {
                            visibility: { ...dmBlock.properties.settings.properties.visibility }
                        }
                    },
                    showMoreLabel: {
                        type: 'string',
                        title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>,
                        default: 'Show all'
                    },
                    searchUrl: {
                        type: 'string',
                        title: <Trans ns="dm.article" i18nKey="search-more_link">Url</Trans>
                    },
                    _editor: dmBlock.properties._editor
                }
            }
        }
    }
};
