import { Trans } from 'react-i18next';

import { uniqueKey } from '../body/uniqueKey';

export const uuStatementUrlBlock = {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="label_UUDeclarationUrl">Tilgjengelighetserklæring</Trans>,
    properties: {
        type: { enum: ['uu_declaration'] },
        settings: { '$ref': '#/definitions/settings' },
        uu_declaration: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_UUDeclarationUrl">Tilgjengelighetserklæring</Trans>,
            default: ''
        },
        _editor: { '$ref': '#/definitions/_editor' },
        _unique_key: uniqueKey
    }
};
