
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import { MediaItem } from './MediaItem';

export const BodyText = block => {
    const { resources } = block;

    return (
        <div className={`row__grid ${block.type}`}>
            <div className="article__bodytext">
                {
                    (!!block.title && !block.titleHidden) &&
                    <h2><Markdown children={block.title} rehypePlugins={[rehypeRaw, rehypeSanitize]} /></h2>
                }
                {
                    (block.type === 'article__bodytext' && resources.mediaList && resources.mediaList.length)
                        ? MediaItem(block, resources.mediaList[0], 0)
                        : null
                }
                <Markdown children={block.bodytext} rehypePlugins={[rehypeRaw]} />
            </div>
        </div>
    );
};
