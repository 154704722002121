import { config } from '../../config';
import { OWNER_SELECTED_BY_ID } from '../actions/app';

import {
    USER_ACCESS, SET_USER_DATA, USER_LOGGED_IN, USER_LOGGED_OUT,
    MUSEUMS_RECEIVED
} from '../actions/user';

const initialState = {
    root: false,
    statistics: false,
    article: false,
    inbox: false,
    editor: false,
    folders: false,
    folder: false
};

export const userAccess = (state = initialState, {type, roleAccess} = {}) => {
    if (type === USER_ACCESS) {
        return { ...roleAccess };
    }

    return { ...state };
};

const initialUserState = {
    userIsAuthenticated: false,
    userData: {},
    museumData: [],
    activeMuseum: null,
    appAdmin: false
};

export const user = (state = initialUserState, action = {}) => {
    console.debug(action.type);
    switch (action.type) {
        case USER_LOGGED_IN:
            return {
                ...state,
                userIsAuthenticated: true
            };
        case USER_LOGGED_OUT:
            return {
                ...state,
                userIsAuthenticated: false,
                userData: initialUserState.userData
            };
        case SET_USER_DATA:
            const { userData } = action;
            let appAdmin = false;

            if (userData && userData.appAccess && userData.appAccess.apps) {
                const app = userData.appAccess.apps.find(x => x.id === config.get('appId'));
                appAdmin = app && app.role && app.role.name.toLowerCase() === 'admin';
            }

            return {
                ...state,
                userData: {
                    ...userData,
                    appAdmin: appAdmin
                }
            };
        case MUSEUMS_RECEIVED:
            const ids = state.userData.appAccess.museums.map(m => m.id),
                filtered = action.museums.filter(m => ids.includes(m.id));

            return {
                ...state,
                museumData: filtered
            };
        case OWNER_SELECTED_BY_ID:
            return {
                ...state,
                activeMuseum: state.museumData.find(m => m.id === action.mid)
            };
        default:
            return { ...state };
    }
};
