import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

import { setFinderMode, setExportEnabled } from '../../redux/actions/layout';
import { requestPrimusVsDmStatistics } from '../../redux/actions/plausible/statistics';

import { ProgressIndicator } from '../../common/ProgressIndicator';
import { PublishedObjectsPieChart } from './PublishedObjectsPieChart';
import { PublishedObjectsBarChart } from './PublishedObjectsBarChart';
import { PublishedObjectsTable } from './PublishedObjectsTable';


const useStyles = makeStyles(() => ({
    grid: {
        flexGrow: 1
    }
}));

export const PublishedObjects = () => {
    const classes = useStyles(),
        dispatch = useDispatch(),
        { ownerUniqueId } = useSelector(state => state.app),
        { reports } = useSelector(state => state.statistics),
        report = reports && reports.published,
        { t, ready } = useTranslation('statsApp', { useSuspense: false });

    useEffect(() => {
        dispatch(requestPrimusVsDmStatistics(ownerUniqueId));
    }, [dispatch, ownerUniqueId]);
    
    useEffect(() => {
        dispatch(setFinderMode('published'));
        dispatch(setExportEnabled(false));
    }, [dispatch]);

    if(!report.rows.length || !ready) {
        return (
            <ProgressIndicator/>
        )
    }

    return (
        <Grid className={classes.grid} container spacing={2}>
            <Grid item xs={12} md={6}>
                <PublishedObjectsPieChart published={report.published} notPublished={report.notPublished} fetching={report.fetching} t={t} />
            </Grid>
            <Grid item xs={12} md={6}>
                <PublishedObjectsBarChart rows={report.rows} fetching={report.fetching} t={t} />
            </Grid>
            <Grid item xs={12} md={12}>
                <PublishedObjectsTable rows={report.rows} fetching={report.fetching} t={t} />
            </Grid>
        </Grid>
    );
};
