import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Checkbox } from '@mui/material';

export const OwnerWidget = props => {
    const { formContext: { owner }, onChange, value } = props,
        { t } = useTranslation(['dm.article'], { useSuspense: false });

    const onToggle = e => {
        if (e.currentTarget.checked) {
            onChange(owner.short_name);
        } else {
            onChange('');
        }
    }

    return (
        <FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={typeof value === 'undefined' || value === '' ? false : value === owner.short_name}
                        onChange={onToggle}
                    /*id={id}
                    required={required}
                    disabled={disabled || readonly}
                    autoFocus={autofocus}
                    onBlur={_onBlur}
                    onFocus={_onFocus}*/
                    />
                }
                label={`${t('search-filter_owner', 'Egen samling')} (${owner.short_name})`}
            />
        </FormControl>
    )
};
