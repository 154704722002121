import PropTypes from 'prop-types';

import { kitFetch } from '@ekultur/fetch';
import { config } from '../../../../config';

function updateKnavField(field, oid, uuid, changeHandler) {
    kitFetch(`${config.get('articleApi')}knav/update/${oid}/${uuid}/${field}`, { method: 'POST' })
        .then(data => {
            if (data && data[field]) {
                changeHandler(data[field]);
            }
        })
        .catch(error => console.error(error));
}

updateKnavField.propTypes = {
    field: PropTypes.string.isRequired,
    oid: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    changeHandler: PropTypes.func.isRequired
};

export default updateKnavField;
