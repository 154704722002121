import React from 'react';
import {useTranslation} from "react-i18next";


export const Error = () => {
    const { t } = useTranslation(['dm.common'], { useSuspense: false }),
        msg = t('data_error', 'En feil har oppstått. Vennligst prøv igjen senere');

    return (
        <h3 style={{ paddingLeft: '16px' }}>{msg}</h3>
    );
};