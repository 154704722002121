import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';

import { getQueryProps, buildQueryString } from '../redux/functions/query';
import { setDateFilter, setCustomDates } from '../redux/actions/layout';
import { MENU_CLOSED } from '../redux/actions/app';

import { ProgressIndicator } from '../common/ProgressIndicator';
import { ButtonExport } from '../common/ButtonExport';
import { Menu } from '../App/Menu';
import { Breadcrumb } from '../App/Breadcrumb';
import { NoOwners } from '../common/NoOwners';
import { AppHeader } from '../header/AppHeader';
import { WhenHeaderIsReady } from '../header/WhenHeaderIsReady';

const useStyles = makeStyles(({spacing}) => ({
    root: {
        zIndex: 1,
        backgroundColor: grey[100],
    },
    scrollWrapper: {
        display: 'flex',
        overflow: 'scroll',
        '.dashboard.owners & > main': {
            padding: 0,
            '& > div': {
                height: '100%',
                position: 'relative'
            }
        },
        '& main': {
            height: 'calc(100vh - 64px)',
            paddingRight: spacing(2),
            paddingLeft: spacing(2),
            boxSizing: 'border-box',

            '& article, & > div:not(.exportButton)': {
                flex: '0 0 100%'
            }
        }
    },
    editor: {},
    main: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        alignItems: 'center',

        '& > nav': {
            position: 'relative',
            flex: '100 0 auto',
            zIndex: 1
        },

        '& > article': {
            width: '100%'
        }
    },
    content: {
        paddingBottom: spacing(10)
    }
}));

export const Admin = ({children}) => {
    const dispatch = useDispatch(),
        location = useLocation(),
        navigate = useNavigate(),
        classes = useStyles(),
        { t, ready } = useTranslation(['navigation', 'dm.menu'], { useSuspense: false }),
        { userIsAuthenticated, userData } = useSelector(state => state.user),
        { ownerUniqueId, menuOpen } = useSelector(state => state.app),
        { filters, inboxFilters, view, reportExportEnabled, } = useSelector(state => state.layout);

    const shouldRedirect = path => {
        return (path.startsWith('/?museum_id=') || path.startsWith('https://'));
    };

    const onSelect = e => {
        const path = e.url,
            query = Object.assign(getQueryProps(location.search), getQueryProps(path));

        if (shouldRedirect(path)) {
            if (e.type !== 'museum') {
                window.location.href = path;
            }
        } else {
            let newPath = location.pathname + buildQueryString(query);
            dispatch(setDateFilter(e.value));
            navigate(newPath);
        }
    };

    const onDateChange = ({ start, end }) => {
        dispatch(setCustomDates(start, end));
    }

    const setFilter = () => {
        const quarter = Math.floor((new Date().getMonth() + 3) / 3),
            reg = /q[1-4]/;

        return filters.filter(f => {
            if (reg.test(f.value)) {
                return f.value[1] <= quarter;
            } else {
                return true;
            }
        });
    };

    let f = null;

    if (view) {
        if (view === 'dashboard' || view === 'statistics') {
            f = [{type: 'date', filter: setFilter()}];
        } else if (view === 'downloads') {
            f = [{type: 'date', filter: setFilter()}];
        } else if (location.pathname.search('/inbox') === 0 && view !== 'artifact-mashups') {
            f = [{type: 'inbox', filter: inboxFilters}, {type: 'date', filter: setFilter()}];
        }
    }

    if(!ready) {
        return <ProgressIndicator />
    }

    return (
        <div className={`${view}${location.pathname !== '/' ? '' : ' owners'}`}>
            <AppHeader />
            <WhenHeaderIsReady>
                <div className={`${classes.root} ${!['articles', 'homepage'].includes(view) ? classes.scrollWrapper : classes.editor}`}>
                    {(userIsAuthenticated && !userData.appAccess.museums.length) && <NoOwners />}
                    {ownerUniqueId &&
                        <Menu
                            path={location.path}
                            mobileOpen={menuOpen}
                            closeHandler={() => dispatch({ type: MENU_CLOSED })}
                            visible={userIsAuthenticated && location.pathname !== '/'}
                            forceMobile={['articles', 'homepage'].includes(view)}
                        />}
                    <main className={`${classes.main} ${!['articles', 'homepage'].includes(view) ? '' : 'editor'}`}>
                        {(userIsAuthenticated && ownerUniqueId && location.pathname !== '/') && (
                            <Breadcrumb
                                filters={f}
                                onSelect={onSelect}
                                onDateChange={onDateChange}
                            />
                        )}
                        {reportExportEnabled && <ButtonExport title={t('dm.menu:export', 'Eksporter')} />}
                        <article>
                            {children}
                        </article>
                    </main>
                </div>
            </WhenHeaderIsReady>
        </div>
    );
};
