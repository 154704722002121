import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

export const themeOptions = {
    palette: {
        primary: {
            main: '#d94353',
            dark: '#ae3552',
            light: '#ee7e8f'
        },
        secondary: {
            main: 'rgb(224 224 224)'
        },
        action: {
            selectedOpacity: .15
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: 'small'
            },
            styleOverrides: {
                root: {
                    width: '100%'
                }
            }
        },
        // MuiOutlinedInput: {
        MuiFormControl: {
            defaultProps: {
                size: 'small'
            }
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '& > .MuiGrid-item': {   
                        paddingTop: 0
                    }
                }
            }
        }
    }
};

export const Theme = ({ children }) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={createAppTheme()}>
                <Box sx={{ zIndex: 1, top: 0, right: 0, left: 0, bottom: 0, }}>
                    {children}
                </Box>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const createAppTheme = () => createTheme(themeOptions);
