import React from 'react';
import { useTranslation } from "react-i18next";
import { ListItem, Typography, Chip, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { useMashupStyles } from './mashupStyles';

const useStyles = makeStyles(({spacing}) => ({
    typeChip: props => ({ ...props.typeChip }),
    meta: props => ({ ...props.meta }),
    secondary: props => ({ ...props.secondary }),
    message: props => ({ ...props.message }),
    listItem: props => ({ ...props.listItem, flexFlow: 'nowrap' }),
    media: {
        minWidth: '75px',
        marginTop: 0,
        marginRight: spacing(1),
        height: 0,
        paddingBottom: '75px',
        backgroundColor: '#ddd',
        position: 'relative'
    },
    // mediaNoImage: {
    //     filter: 'grayscale(100%)'
    // }
}));

export const MashupInboxCard = ({ artifact, onSelect }) => {
    const theme = useTheme(),
        { ...mashupStyles } = useMashupStyles(theme),
        classes = useStyles(mashupStyles),
        { t } = useTranslation(['dm.mashup', 'dm.mashupTypes'], { useSuspense: false }),
        mashup = artifact.messages[0],
        { imageUrl } = artifact.attachments[0],
        author = `${mashup.author.name}${mashup.author.email ? ' (' + mashup.author.email + ')' : ''}`,
        created = new Date(mashup.createdAt),
        dateTime = `${created.toLocaleDateString()} ${created.toLocaleTimeString()}`;

    const handleSelect = (id) => {
        onSelect && onSelect(id);
    };

    return (
        <ListItem
            button
            component="li"
            className={classes.listItem}
            onClick={() => handleSelect(artifact.uniqueId)}
        >
            <CardMedia
                className={classes.media}
                image={imageUrl ? `${imageUrl}?dimension=167x167` : 'https://dms-cf-09.dimu.org/image/02349w5hFUHV?mediaType=image/svg'}
            />
            <div>
                <Typography className={classes.secondary}>
                    {artifact.attachments[0].identifier} - {artifact.title}
                </Typography>
                <div className={classes.meta}>
                    <Chip size="small" variant="outlined" label={t(`dm.mashupTypes:${mashup.documentType}`)} className={classes.typeChip} />
                    <Typography component={'span'}>{author}</Typography>
                    {mashup.createdAt && <Typography component={'span'} >{dateTime}</Typography>}
                </div>
                <Typography className={classes.message}>
                    {mashup.message}
                </Typography>
            </div>
        </ListItem>
    );
};
