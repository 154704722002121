export function getQueryProps(queryString = window.location.search) {
    let query = {};

    if (!queryString) {
        return query;
    }

    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

    for (var i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

export function getValueFromQueryString(key, queryString = window.location.search) {
    if (!key || !queryString) {
        return null;
    }

    return getQueryProps(queryString)[key];
}

export function buildQueryString(props) {
    if (!props) {
        return '';
    }

    const queries = Object.entries(props).filter(q => q[0] !== 'museum_id').map(q => `${q[0]}=${q[1]}`);

    if (!!queries.length) {
        return '?' + queries.join('&');
    }

    return '';
}