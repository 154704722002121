import { uniqueKey } from './uniqueKey';
import { veObject } from './veObject';

export const veBlock = {
    type: 'object',
    required: ['type'],
    title: 'VirtueltMuseum',
    properties: {
        type: { enum: ['virtualExperience'] },
        title: {
            type: 'string',
            title: 'Tittel',
        },
        titleHidden: {
            type: 'boolean',
            title: 'Skjul tittel',
            enum: [true, false],
            default: false,
        },
        settings: {
            type: 'object',
            properties: {
                visibility: {
                    type: 'string',
                    enum: ['visible', 'hidden'],
                    enumNames: ['Synlig', 'Skjult'],
                    default: 'visible',
                },
            },
        },
        resources: {
            type: 'object',
            properties: {
                vmEntryList: {
                    type: 'array',
                    default: [],
                    items: veObject,
                },
            },
        },
        _editor: { $ref: '#/definitions/_editor' },
        _unique_key: uniqueKey,
    },
};
