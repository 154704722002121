import React, { useState } from 'react';
import { Chip, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const StatusChip = ({label, filter, onSelect = null, size = 'small', className = ''}) => {
    const [anchorEl, setAnchorEl] = useState(null),
        indexFromState = filter.findIndex(f => f.label === label),
        [selectedIndex, setSelectedIndex] = useState(indexFromState < 0 ? 0 : indexFromState);

    const showStatusMenu = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleMenuItemClick = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        setSelectedIndex(index);
        setAnchorEl(null);
        onSelect && onSelect(filter[index].key);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={className}>
            <Chip clickable size={size} label={filter[selectedIndex].label} onClick={showStatusMenu} onDelete={showStatusMenu} deleteIcon={<ArrowDropDownIcon />} />
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {filter.map((option, index) => (
                    <MenuItem
                        key={option.key}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}