import React, { Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Slider from 'react-slick';

import { filterStyle, dmsUrl } from '@ekultur/ui-schema-components';

import { Dm } from './Dm';
import { DmSchool } from './DmSchool';
import { License } from './License';

import '../../slick_styles/slick.css';
import '../../slick_styles/slick-theme.css';
import { BodyText } from './BodyText';
import { MediaItem } from './MediaItem';
import { VirtualExperience } from './VirtualExperience';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
    articleBody: {
        paddingBottom: spacing(1),
        '& .article__bodytext': {
            '& .row': {
                '&.large': {
                    width: '100%'
                },
                '&.medium': {
                    width: '50%',
                },
                '&.small': {
                    width: '25%'
                },
                '&.row--img-left': {
                    float: 'left',
                    maxWidth: '50%',
                    paddingTop: spacing(1),
                    paddingRight: spacing(2),
                    paddingBottom: spacing(2)
                },
                '&.row--img-right': {
                    float: 'right',
                    maxWidth: '50%',
                    paddingTop: spacing(1),
                    paddingLeft: spacing(2),
                    paddingBottom: spacing(2)
                }
            },
            '& blockquote': {
                '& > p:before': {
                    content: '"«"'
                },
                '& > p:after': {
                    content: '"»"'
                }
            }
        },
        '& .section--auto': {
            margin: '1.5em inherit !important'
        },
        '& .row__grid': {
            '& .row--grid': {
                paddingTop: spacing(1),
                paddingRight: spacing(2),
                paddingBottom: spacing(1),
                boxSizing: 'border-box',
            },
            '&.article__bodytext:after': {
                content: '""',
                display: 'table',
                clear: 'both'
            },
            '&.article__media': {
                display: 'flex',
                flexFlow: 'wrap'
            },
            '& .large': {
                width: '100%'
            },
            '& .medium': {
                width: '50%',
            },
            '& .small': {
                width: '25%'
            }
        },
        '& img, & video': {
            width: '100%'
        },
        '& .dm': {
            '& h2': {
                width: '704px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },
        '.anchorMode & [data-unique-key]': {
            '&:hover': {
                background: 'yellow'
            },
            '&.active': {
                background: 'green'
            },
            '& *': {
                pointerEvents: 'none'
            }
        }
    },
    slideshow: {
        '& .slick-prev': {
            'z-index': 1
        }
    },
    sliderItem: {
        paddingBottom: '56.25%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: '#808080'
    }
}));


export const ArticleBody = ({ blocks, anchorObj }) => {
    const classes = useStyles();

    const renderMedia = block => {
        const { settings } = block;

        if (!block.resources || !block.resources.mediaList || !block.resources.mediaList.length) {
            return null;
        }

        const renderSlideShow = (item, index) => {
            return (
                <Fragment key={index}>
                    <figure
                        className={`slick ${classes.sliderItem}`}
                        // focuspoint
                        // data-focus="false"
                        // data-focus-x="{{ item.media.focuspoint.x }}"
                        // data-focus-y="{{ item.media.focuspoint.y }}"
                        // data-focus-w="{{ item.media.focuspoint.imgattr.width }}"
                        // data-focus-h="{{ item.media.focuspoint.imgattr.height }}"
                        // id="block-{{ block.unique_key }}--media--{{ $index }}-media"
                        style={{
                            ...filterStyle(item.media),
                            backgroundImage: `url(${(item.mimetype && item.mimetype.indexOf('png') > -1) ? dmsUrl(item, 10) + '?mediaType=image/png' : dmsUrl(item, 10)})`
                        }}
                    >
                    </figure>
                    <div className="module__content">
                        {item.local && item.local.description && item.local.description !== '' && <span className="media__caption">{item.local.description}</span>}
                        <span className="media__credit">{item.local && item.local.credit}</span>
                        {item.licenses && item.licenses[0] && <License code={item.licenses[0]} />}
                    </div>
                </Fragment>
            );
        };

        if (settings.layout === 'slideshow') {
            const conf = {
                infinite: false,
                variableWidth: false,
                adaptiveHeight: false,
                arrows: true,
                initialSlide: 0,
                pauseOnHover: true,
                pauseOnFocus: false
            };

            return (
                <div className={classes.slideshow}>
                    <Slider {...conf}>
                        {block.resources.mediaList.map((m, index) => renderSlideShow(m, index))}
                    </Slider>
                </div>
            )
        }

        return (
            <div className={`row__grid ${block.type}`}>
                {block.resources.mediaList.map((item, index) => MediaItem(block, item, index))}
            </div>
        );
    };

    const renderBlock = (block, index) => {
        const id = `@section_1.block_${index}`;

        if (block.settings.visibility !== 'visible') {
            return null;
        }

        if (block.type === 'article__bodytext') {
            return (
                <section
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`article__bodytext-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`article__content${anchorObj && anchorObj.value === block._unique_key ? ' active' : ''}`}
                >
                    {BodyText(block)}
                </section>
            );
        } else if (block.type === 'article__media') {
            return (
                <div
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`article__media-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`${anchorObj && anchorObj.value === block._unique_key ? 'active' : ''}`}
                >
                    {
                        ((block.title && !block.titleHidden) || block.bodytext) &&
                        <section className="article__content">
                            {BodyText(block)}
                        </section>
                    }
                    <section className="article__content section--auto">
                        {renderMedia(block)}
                    </section>
                </div>
            );
        } else if (block.type === 'digitaltMuseum' ) {
            return (
                <div
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`digitaltMuseum-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`${anchorObj && anchorObj.value === block._unique_key ? 'active' : ''}`}
                >
                    {
                        ((block.title && !block.titleHidden) || block.bodytext) &&
                        <section className="article__content">
                            {BodyText(block)}
                        </section>
                    }
                    <section className="section--auto dm">
                        <Dm id={`article__dm-${index}`} block={block} />
                    </section>
                </div>
            );
        } else if (block.type === 'virtualExperience') {
            return (
                <div
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`virtualExperience-${index}`}
                    onClick={() => anchorObj && anchorObj?.handler(block._unique_key)}
                    className={`article__content${anchorObj && anchorObj?.value === block._unique_key ? 'active' : ''
                        }`}
                >
                    {((block.title && !block.titleHidden) || block.bodytext) && (
                        <section className="article__content">{BodyText(block)}</section>
                    )}
                    <section className={`${classes.section__auto} ${classes.dm}`}>
                        <VirtualExperience id={`article__vm-${index}`} block={block} />
                    </section>
                </div>
            );
        } else if (block.type === 'school' ) {
            return (
                <div
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`school-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`${anchorObj && anchorObj.value === block._unique_key ? 'active' : ''}`}
                >
                     {
                        ((block.title && !block.titleHidden) || block.bodytext) &&
                        <section className="article__content">
                            {BodyText(block)}
                        </section>
                    }
                    <section className="section--auto dm">
                        <DmSchool id={`article__school-${index}`} block={block} />
                    </section>
                </div>
            );
        }

        return null;
    };

    return (
        <div id="articleBody" className={classes.articleBody}>
            {blocks.map((b, index) => renderBlock(b, index))}
        </div>
    );
};
