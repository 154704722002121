import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Tab, Tabs, useTheme } from '@mui/material';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { requestStatistics } from '../redux/actions/statistics';
import { setTimePeriod } from '../redux/functions/statistics';
import { getValueFromQueryString } from '../redux/functions/query';

import { MetricsTooltip } from './MetricsTooltip';
import { parseDate, parseGADimension } from './parsers';
import { TabLabel } from './TabLabel';
import { WithProgressOverlay } from '../common/WithProgressOverlay';
import { secondsToMinutesSeconds } from './time/timeFormatter';

const useStyles = makeStyles(theme => ({
    root: {
        height: 420 + parseInt(theme.spacing(4)),
        '& text': {
            fontSize: '.8rem'
        }
    },
    container: {
        padding: theme.spacing(2)
    },
    tabs: {
        marginBottom: theme.spacing(2)
    },
    tabPanel: {
      padding: theme.spacing(2)
    },
    tabIndicator: {
        top: 0
    }
}))

export const StatisticsReportMetrics = ({ viewType }) => {
    const dispatch = useDispatch(),
        classes = useStyles(),
        { palette } = useTheme(),
        [selectedTab, setSelectedTab] = useState(0),
        { t } = useTranslation(['googleAnalytics'], { useSuspense: false }),
        period = getValueFromQueryString('period'),
        { reports } = useSelector(state => state.gaStatistics),
        report = reports && reports[viewType].metric,
        { fetching, rows, maximums, totals, dateRanges } = report,
        data = rows.map(row => ({
            name: row.dimensions[0],
            thisPeriod: row.metrics[0].values[selectedTab]
        })),
        { ownerUniqueId } = useSelector(state => state.app),
        { filters, currentDetail } = useSelector(state => state.layout),
        timePeriod = setTimePeriod(filters, currentDetail, period, false, true);

    useEffect(() => {
        if (timePeriod.start && timePeriod.end) {
            dispatch(requestStatistics(ownerUniqueId, timePeriod.start, timePeriod.end, 0, viewType, 0,
                timePeriod.orderBy, 'uniquePageviews,users,avgTimeOnPage,bounceRate', timePeriod.orderBy,
                'ascending', true));
        }
    }, [dispatch, ownerUniqueId, timePeriod.start, timePeriod.end, viewType, timePeriod.orderBy]);

    const yMax = maximums.length ? Math.max(maximums[0].values[selectedTab]) : 1000

    const currentPeriod = {
        startDate: dateRanges.length ? parseDate(dateRanges[0].startDate) : '',
        endDate: dateRanges.length ? parseDate(dateRanges[0].endDate) : ''
    }

    const handleTabChange = (event, newValue) => setSelectedTab(newValue)

    return (
        <WithProgressOverlay loading={fetching}>
            <Paper className={classes.root}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label='metrics tabs'
                    indicatorColor={'primary'}
                    className={classes.tabs}
                    classes={{
                        indicator: classes.tabIndicator
                    }}
                >
                    <Tab
                        label={
                            <TabLabel
                                primaryText={t('uniquePageViews', 'Unike sidevisninger')}
                                totals={totals.length ? parseInt(totals[0].values[0]) : 0}
                            />
                        }
                        className={classes.tabPanel}
                    />
                    <Tab
                        label={
                            <TabLabel
                                primaryText={t('users', 'Brukere')}
                                totals={totals.length ? parseInt(totals[0].values[1]) : 0}
                            />
                        }
                        className={classes.tabPanel}
                    />
                    <Tab
                        label={
                            <TabLabel
                                primaryText={t('avgTimeOnPage', 'Gjenomsnitt tid/side')}
                                totals={`${totals.length ? secondsToMinutesSeconds(totals[0].values[2]) : 0}`}
                            />
                        }
                        className={classes.tabPanel}
                    />
                </Tabs>
                <div className={classes.container}>
                    <ResponsiveContainer width='99%' height={300} >
                        <LineChart data={data}>
                            <CartesianGrid vertical={false}/>
                            <XAxis
                                dataKey='name'
                                tickFormatter={(tickItem) => parseGADimension(currentPeriod, timePeriod.orderBy, tickItem, t)}
                                axisLine={false}
                                interval={'preserveStartEnd'}
                                padding={{right: 40}}
                            />
                            <YAxis
                                domain={[0, yMax]}
                                orientation={'right'}
                                axisLine={false}
                                tickLine={false}
                                mirror={true}
                            />
                            <Tooltip
                                content={
                                    <MetricsTooltip
                                        parser={parseGADimension}
                                        dimension={timePeriod.orderBy}
                                        period={currentPeriod}
                                        t={t}
                                    />
                                }
                            />
                            <Line
                                type='monotone'
                                dataKey='thisPeriod'
                                stroke={palette.primary.main}
                                activeDot={{r: 8}}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </Paper>
        </WithProgressOverlay>
    )
};
