import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Markdown from 'react-markdown';

const useStyles = makeStyles(() => ({
    footer: {
        '& .article__bodytext': {
            width: '704px',
            margin: '0 auto'
        },
        '& #map': {
            height: '480px'
        }
    }
}));

export const OwnerFooter = ({ block }) => {
    const classes = useStyles(),
        { title, latitude, longitude } = block;
    
    useEffect(() => {
        if (latitude && longitude) {
            if (window.google) {
                const lat = typeof latitude === 'string' ? parseFloat(latitude) : latitude,
                    lng = typeof longitude === 'string' ? parseFloat(longitude) : longitude;

                let map = {
                    markers: [],
                    container: document.getElementById('map')
                };

                map.map = new window.google.maps.Map(map.container, {
                    center: {
                        lat: lat,
                        lng: lng
                    },
                    zoom: 8,
                    styles: [
                        {
                            "featureType": "water",
                            "stylers": [
                                { "saturation": -80 }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "stylers": [
                                { "saturation": -50 }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "stylers": [
                                { "saturation": -50 }
                            ]
                        },
                        {
                            "featureType": "road",
                            "stylers": [
                                { "saturation": -100 },
                                { "gamma": 2.29 }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "labels.text",
                            "stylers": [
                                { "saturation": -100 },
                                { "gamma": 2.29 }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "labels.icon",
                            "stylers": [
                                { "saturation": -100 },
                                { "gamma": 0.95 }
                            ]
                        }
                    ],
                    scrollwheel: false
                });

                map.bounds = new window.google.maps.LatLngBounds();

                const marker = {
                    marker: new window.google.maps.Marker({
                        position: {
                            lat: +lat || 61.1119063,
                            lng: +lng || 10.4770562
                        },
                        map: map.map
                    })
                };

                map.bounds.extend(marker.marker.position);
                map.markers.push(marker);
                // map.map.fitBounds(map.bounds);
                // map.map.setZoom(8);
            } else {
                let mapScript = document.createElement('script'),
                    head = document.getElementsByTagName('head')[0],
                    key = 'AIzaSyDTv_rJ1ZC2OPSQnhItzFeu8eMsZ1Q920g';
            
                mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${key}`;
                head.appendChild(mapScript);
            }
        }
    }, [latitude, longitude]);

    return latitude && longitude
        ? (
            <section className={classes.footer}>
                <div className="article__bodytext">
                    <h2><Markdown children={title} /></h2>
                </div>
                <div id="map"></div>
            </section>
        ) : null
};
