import { Trans } from 'react-i18next';

import { uniqueKey } from '../body/uniqueKey';

export const museumMapBlock = {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="owner.footerblock_title">Koordinater</Trans>,
    properties: {
        type: { enum: ['museum_coordinates'] },
        coordinates: {
            type: 'object',
            readOnly: true,
            properties: {
                latitude: { type: 'integer' },
                longitude: { type: 'integer' }
            }
        },
        override: {
            type: 'boolean',
            title: 'Egendefinerte koordinater',
            default: false
        },
        _editor: { '$ref': '#/definitions/_editor' },
        _unique_key: uniqueKey
    },
    dependencies: {
        override: {
            oneOf: [{
                properties: {
                    override: {
                        enum: [true],
                    },
                    customCoordinates: {
                        type: 'object',
                        properties: {
                            latitude: { type: 'integer' },
                            longitude: { type: 'integer' }
                        },
                        title: null
                    }
                },
                required: ['customCoordinates']
            },
            {
                properties: {
                    override: {
                        enum: [false],
                    }
                }
            }]
        }
    }
};
