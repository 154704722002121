import { kitFetch } from '@ekultur/fetch';
import { config } from '../../config';

export const OWNER_FEATURES_REQUESTED = 'OWNER_FEATURES_REQUESTED';
export const OWNER_FEATURES_SUCCEEDED = 'OWNER_FEATURES_SUCCEEDED';
export const OWNER_FEATURES_FAILED = 'OWNER_FEATURES_FAILED';

export const requestOwnerFeatures = ownerId => dispatch => {
    dispatch({
        type: OWNER_FEATURES_REQUESTED
    });

    kitFetch(`${config.get('museumApi')}${ownerId}/features/`)
        .then(data => dispatch({
            type: OWNER_FEATURES_SUCCEEDED,
            data: data,
            owner: ownerId
        }))
        .catch(error => dispatch({
            type: OWNER_FEATURES_FAILED,
            owner: ownerId,
            data: error
        }));
}