import React, { useState, useEffect } from 'react';

let focusedBlock,
    scrolling = false;

export const WithFocus = ({ focusId, children }) => {
    const [focusedId, setFocusedId] = useState();

    useEffect(() => {
        if (focusedId !== focusId) {
            const id = focusId;

            try {
                const idArr = id.split('_'),
                    idx = parseInt(idArr[idArr.findIndex(x => x === 'blocks') + 1]),
                    block = document.getElementById(`@section_${idArr[3]}.block_${idx}`);

                if (!block) {
                    return;
                }

                scrolling = true;
                focusedBlock = block;
            } catch (error) {
                // console.log(error);
            }

            setFocusedId(focusId);
        } else if (focusedBlock) {
            focusedBlock.scrollIntoView({ behavior: scrolling ? 'smooth' : 'auto' });
            scrolling = false;
            focusedBlock = null;
        }
    }, [focusedId, focusId]);

    return <>{children}</>;
};
