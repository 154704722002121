import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { MuseumHeader } from './MuseumHeader';
import { MuseumAbout } from './MuseumAbout';

import './article.scss';
import { MuseumTrending } from './MuseumTrending';

const useStyles = makeStyles(({ breakpoints }) => ({
    content: {
        '& .article': {
            color: '#3a2e2d',
            '& *': {
                boxSizing: 'border-box'
            },
            '& ol, ul': {
                paddingLeft: 0,
                listStyle: 'none'
            },
            '& .meta__list': {
                margin: '1em 0',
                padding: '1em 0',
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: '#4F4F4F',

                '& > li:not(first-of-type)': {
                    marginTop: '.75em'
                },

                '& .meta__label': {
                    marginRight: '.5em',
                    fontSize: '0.75em',
                    textTransform: 'uppercase',
                    letterSpacing: '0.075em',
                    color: '#676767',

                    '&:after': {
                        content: '":"'
                    }
                },

                '& .meta__link': {
                    color: 'inherit',
                    textDecoration: 'none',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.5)'
                }
            },
            '& .article__content': {
                [breakpoints.up('md')]: {
                    maxWidth: '1064px',
                }
            },
            '& .museum-cards a': {
                color: 'inherit'
            }
        }
    }
}));

export const MuseumPreview = ({ data, onPreviewClick, anchorObj, museum }) => {
    const classes = useStyles(),
        { content } = data,
        { sections } = content,
        header = sections.find(s => s.type === 'museum_head'),
        [activeSection, setActiveSection] = useState('museum_about');

    useEffect(() => {
        if (!document.getElementById('dm4-article-css')) {
            const head = document.head,
                link = document.createElement('link');

            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.id = 'dm4-article-css'
            link.href = `${window._env_.REACT_APP_DM4_URL}/assets/css/article.css?v=${window._env_.REACT_APP_ENV === 'dev' ? Date.now() : window._env_.REACT_APP_VERSION}`;

            head.appendChild(link);
        }
    
        // return () => { head.removeChild(link); }
    }, []);

    const handlePreviewClick = e => {
        const { target } = e;
        let id = target.id,
            p = target;

        while (id.substr(0, 8) !== '@section' && p.parentElement) {
            p = p.parentElement;
            id = p.id;
        }

        id = id.split('.');

        if (id.length > 1 && id[1] !== 'ignore') {
            const [blockType, idx] = id[1].split('_');
            onPreviewClick && onPreviewClick(id[0].replace('@section_', ''), idx, blockType);
        }
    };

    const renderSection = () => {
        const section = sections.find(s => s.type === activeSection);

        switch (activeSection) {
            case 'museum_about':
                return !!section.blocks.length && <MuseumAbout key={section.type} blocks={section.blocks} />;
            case 'museum_trending':
                return !!section.searches.length && <MuseumTrending key={section.type} searches={section.searches} museum={museum} />;
            default:
                return null;
        }
    };

    return <>
        <main className={`${classes.content} content`} onClick={handlePreviewClick}>
            <article className='article'>
                {header && <MuseumHeader key={header.type} museum={museum} blocks={header.blocks} activeSection={activeSection} setActiveSection={setActiveSection} />}
                {renderSection()}
            </article>
        </main>
    </>
};
