import React from 'react';
import { Trans } from 'react-i18next';

import { artifactTypes } from '../searchFilters/artifactTypeEnum';
import { sort } from '../searchFilters/sortEnum';
import { order } from '../searchFilters/sortOrderEnum';

export const dmSearch = {
    type: 'object',
    required: ['type'],
    properties: {
        type: { enum: ['search'] },
        filter: {
            type: 'object',
            title: <Trans ns="dm.article" i18nKey="search_title">Begrens søket til:</Trans>,
            properties: {
                owner: { type: 'string' },
                type: {
                    type: 'string',
                    title: <Trans ns="dm.article" i18nKey="search-filter_type">Type</Trans>,
                    enum: artifactTypes.map(t => t.value),
                    enumNames: artifactTypes.map(t => t.label),
                    default: ''
                },
                sort: {
                    type: 'string',
                    title: <Trans ns="dm.article" i18nKey="search-filter_sort">Sortering</Trans>,
                    enum: sort.map(t => t.value),
                    enumNames: sort.map(t => t.label),
                    default: 'relevance'
                },
                sort_order: {
                    type: 'string',
                    title: <Trans ns="dm.article" i18nKey="search-filter_order">Rekkefølge</Trans>,
                    enum: order.map(t => t.value),
                    enumNames: order.map(t => t.label),
                    default: ''
                },
                rows: {
                    type: 'integer',
                    title: <Trans ns="dm.article" i18nKey="search-filter_count">Antall objekter</Trans>,
                    minimum: 1,
                    maximum: 100,
                    default: 4
                }
            }
        },
        query: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="search-filter_query">Søkekriterier</Trans>,
            default: ''
        },
        settings: {
            type: 'object',
            title: null,
            required: ['size'],
            properties: {
                size: {
                    type: 'string',
                    enum: [
                        'small',
                        'medium',
                        'large'
                    ],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="search-card_small">Liten</Trans>,
                        <Trans ns="dm.article" i18nKey="search-card_medium">Medium</Trans>,
                        <Trans ns="dm.article" i18nKey="search-card_large">Stor</Trans>
                    ],
                    default: 'small'
                }
            }
        }
    }
};
