export const defaultConfig = {
    appId: '2cdc3799-bbc1-4a46-9529-2bdd7f3e0694',
    api: '/dm-admin-api/v1/',
    articleApi: '/dm-admin-api/v1/',
    wikimediaApi: '/wikimedia-api/v1/',
    museumApi: '/museum-api/museums/',
    placeApi: 'https://apigateway.dimu.org/place-api/api/search',
    recentMashupsUrl: 'mashup/recent/<TYPE>/<SITE>',
    mashupsUrl: 'mashup/<TYPE>/<SITE>',
    plausibleApi: 'https://apigateway.dimu.org/dm-statistics-api/api/v1/'
};
