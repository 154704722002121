import React from 'react';
import { Trans } from 'react-i18next';

import { uniqueKey } from './uniqueKey';
import { schoolObject } from './schoolObject';

export const schoolBlock = {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="schoolBlock_title">Skole</Trans>,
    properties: {
        type: { enum: ['school'] },
        title: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>
        },
        titleHidden: {
            type: 'boolean',
            title: <Trans ns="dm.article" i18nKey="label_hide-title">Skjul tittel</Trans>,
            enum: [true, false],
            default: false
        },
     
        settings: {
            type: 'object',
            properties: {
                layout: {
                    type: 'string',
                    enum: [
                        'grid',
                        'grid-wide'
                    ],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="grid">Grid</Trans>,
                        <Trans ns="dm.article" i18nKey="grid_wide">Grid wide</Trans>
                    ],
                    default: 'grid'
                },
                visibility: {
                    type: 'string',
                    enum: [
                        'visible',
                        'hidden'
                    ],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="visible">Synlig</Trans>,
                        <Trans ns="dm.article" i18nKey="hidden">Skjult</Trans>
                    ],
                    default: 'visible'
                }
            }
        },
        resources: {
            type: 'object',
            properties: {
                schoolEntryList: {
                    type: 'array',
                    default: [],
                    items: schoolObject
                }
            }
        },
       
        _editor: { '$ref': '#/definitions/_editor' },
        _unique_key: uniqueKey
    }
};
