import React from 'react';
import { Trans } from 'react-i18next';

import { museumNameBlock } from './nameBlock';

export const museumHeaderSection = {
    type: 'object',
    title: <Trans ns="dm.article" i18nKey="section_header">Header</Trans>,
    required: ['type'],
    properties: {
        type: { enum: ['museum_head'] },
        _editor: { '$ref': '#/definitions/_editor' },
        blocks: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
            items: museumNameBlock
        }
    }
};
