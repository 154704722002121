import React, { useState, useEffect } from 'react';

import { baseSchema, ownerSchema, buildUiSchema } from './schema';

import { ProgressIndicator } from '../common/ProgressIndicator';
import { Preview } from './preview/Preview';
import { PageLoader } from './PageLoader';

export const HomePage = () => {
    const [uiSchema, setUiSchema] = useState(),
        [schema, setSchema] = useState(baseSchema);

    useEffect(() => {
        console.log('initializing Homepage');
        setSchema(s => {
            s.properties.content.properties = ownerSchema;
            return s;
        });

        setUiSchema(buildUiSchema());
    }, []);
    
    return (
        <>
            {schema && uiSchema ? (
                <PageLoader
                    schema={schema}
                    uiSchema={uiSchema}
                    Preview={Preview}
                    pageType='owner'
                    editUrl='homepage'
                />
            ) : (
                <ProgressIndicator />
            )}
        </>
    );
};
