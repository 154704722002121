import download from 'downloadjs';
import queryString from 'query-string';
import {
    GA_STATISTICS_REQUESTED,
    GA_STATISTICS_SUCCEEDED,
    GA_STATISTICS_HOME_REQUESTED,
    GA_STATISTICS_HOME_SUCCEEDED,
    GA_STATISTICS_REALTIME_REQUESTED,
    GA_STATISTICS_REALTIME_SUCCEEDED,
    GA_STATISTICS_DOWNLOAD_REQUESTED,
    GA_STATISTICS_DOWNLOAD_SUCCEEDED,
    GA_REPORT_EXPORT_REQUESTED,
    GA_REPORT_EXPORT_STARTED,
    GA_REPORT_EXPORT_SUCCEEDED,
    GA_REPORT_EXPORT_FAILED,
    GA_STATISTICS_FAILED
} from '../types';
import { config } from '../../config';
import { kitFetch } from '@ekultur/fetch';

const handleExportResponse = (response, endpointUrl) => {
    if(response.ok) {
        return response;
    } else {
        return Promise.reject(response.status)
    }
};

const exportResponse = (endpointUrl, configuration, maxTries) => {
    return fetch(endpointUrl, configuration)
        .then(response => {
            if (503 === response.status || 504 === response.status) {
                return Promise.reject('retry')
            } else {
                return handleExportResponse(response, endpointUrl)
            }
        })
        .catch(error => {
            if ('retry' === error && 0 !== maxTries) {
                return exportResponse(endpointUrl, configuration, maxTries - 1);
            } else {
                return Promise.reject(error)
            }
        })
};

const exportRequest = (url) => {
    const token = localStorage.getItem('kit.access_token'),
        headers = new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }),
        configuration = { headers };

    return exportResponse(url, configuration, 3);
};

export const requestDashboardStatistics = (owner) => dispatch => {
    const q = {
        owner: owner.uniqueId,
        startDate: '6daysAgo,13daysAgo',
        endDate: 'today,7daysAgo',
        dimensions: 'nthDay',
        metrics: 'uniquePageviews',
        orderBy: 'nthDay',
        sort: 'ascending'
    };
    
    dispatch({
        type: GA_STATISTICS_HOME_REQUESTED,
        fetching: true,
    });

    kitFetch(`${config.get('api')}statistics/playground/reports/?${queryString.stringify(q)}`)
        .then(data => dispatch({
            type: GA_STATISTICS_HOME_SUCCEEDED,
            data: { ...data, owner: owner.name }
        }));
};

export const requestStatistics = (owner, startDate, endDate, reportType = 0,
        viewType = 'dashboard', start = 0, dim = null, met = null, orderBy = null,
        sort = 'ascending', includeEmptyRows = false, filters = null, rows = 50) => dispatch => {
    const q = {
        owner: owner,
        reportType: reportType,
        rows: rows,
        start,
        startDate,
        endDate,
        dimensions: dim,
        metrics: met,
        orderBy: orderBy,
        sort: sort,
        emptyRows: includeEmptyRows,
        filters: filters
    };

    dispatch({
        type: GA_STATISTICS_REQUESTED,
        viewType: viewType,
        reportType: reportType
    });

    kitFetch(`${config.get('api')}statistics/playground/reports/?${queryString.stringify(q)}`)
        .then(data => dispatch({
            type: GA_STATISTICS_SUCCEEDED,
            viewType: viewType,
            data: { ...data, reportType: reportType }
        }))
        .catch(() => dispatch({
            type: GA_STATISTICS_FAILED,
            viewType: viewType,
            reportType: reportType
        }));
};

export const requestRealtimeStatistics = (viewType = 'realtime') => dispatch => {
    dispatch({
        type: GA_STATISTICS_REALTIME_REQUESTED
    });

    kitFetch(`${config.get('api')}statistics/playground/realtime/`)
        .then(data => dispatch({
            type: GA_STATISTICS_REALTIME_SUCCEEDED,
            viewType: viewType,
            data: data
        }))
        .catch(() => dispatch({
            type: GA_STATISTICS_FAILED,
            viewType: viewType
        }));
};

export const requestDownloadStatistics = (owners, timePeriod, startDate, endDate,
        rows = 100, start = 0) => dispatch => {
    const q = {
        owner: owners,
        rows,
        timePeriod,
        startDate,
        endDate
    };

    dispatch({
        type: GA_STATISTICS_DOWNLOAD_REQUESTED
    });

    kitFetch(`${config.get('api')}statistics/downloads/?${queryString.stringify(q)}`)
        .then(data => dispatch({
            type: GA_STATISTICS_DOWNLOAD_SUCCEEDED,
            data: data,
            reportType: 'downloads'
        }))
        .catch(() => dispatch({
            type: GA_STATISTICS_FAILED,
            reportType: 'downloads'
        }));
};

export const requestGAExport = () => dispatch => {
    dispatch({
        type: GA_REPORT_EXPORT_REQUESTED
    })
};

export const exportReport = (owner, startDate, endDate, dim = null,
        met = null, orderBy = null, reportType = '', sort = 'descending', includeEmptyRows = false,
        filters = null) => dispatch => {
    const q = {
        owner: owner,
        startDate,
        endDate,
        dimensions: dim,
        metrics: met,
        orderBy: orderBy,
        sort: sort,
        emptyRows: includeEmptyRows,
        filters: filters
    },
        url = `${window._env_.REACT_APP_API_GATEWAY}${config.get('api')}statistics/playground/reports/export/?${queryString.stringify(q)}`;

    dispatch({
        type: GA_REPORT_EXPORT_STARTED
    });

    exportRequest(url)
        .then(data => data.blob())
        .then(data => {
            dispatch({
                type: GA_REPORT_EXPORT_SUCCEEDED
            });

            download(data, `dm_statistics_${reportType}_${startDate}-${endDate}.xlsx`);
        })
        .catch(() => dispatch({
            type: GA_REPORT_EXPORT_FAILED
        }));
};

export const exportDownloadStatistics = (owners, timePeriod, startDate, endDate) => dispatch => {
    const q = {
        owner: owners,
        timePeriod,
        startDate,
        endDate
    },
        url = `${window._env_.REACT_APP_API_GATEWAY}${config.get('api')}statistics/downloads/export/?${queryString.stringify(q)}`;

    dispatch({
        type: GA_REPORT_EXPORT_STARTED,
    });

    exportRequest(url)
        .then(data => data.blob())
        .then(data => {
            dispatch({
                type: GA_REPORT_EXPORT_SUCCEEDED
            });

            download(data, `dm_statistics_downloads_${timePeriod}.xlsx`);
        })
        .catch(() => dispatch({
            type: GA_REPORT_EXPORT_FAILED
        }));
};
