import React, { useState, useEffect, Fragment/* , useRef  */} from 'react';
import { useTranslation } from "react-i18next";
import { List, IconButton, ListItem, Chip, Input, Typography, Collapse } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { useMashupStyles } from './mashupStyles';

import { StatusChip } from './StatusChip';
import { useTheme } from '@mui/material/styles';


import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(({spacing, palette, breakpoints}) => ({
    root: props => ({ ...props.root }),
    typeChip: props => ({ ...props.typeChip }),
    meta: props => ({ ...props.meta }),
    secondary: props => ({ ...props.secondary }),
    message: props => ({ ...props.message }),
    listItem: props => ({ ...props.listItem }),
    listItemActive: {
        background: '#fbfbfb'
    },
    listItemExpanded: {
        borderBottom: '1px solid #fbfbfb !important'
    },
    listItemIcon: {
        minWidth: 'initial'
    },
    listItemSecondaryAction: {
        top: spacing(1) * .75,
        right: spacing(2),
        position: 'absolute',
        [breakpoints.down('xs')]: {
            position: 'relative'
        },
        '& > button': {
            width: spacing(6)
        }
    },
    input: {
        marginTop: spacing(1),
        width: '100%'
    },
    replyInfo: {
        marginTop: spacing(1),
        fontSize: '.875rem',
        color: palette.primary.main
    },
    collapse: {
        paddingLeft: spacing(2),
        background: '#fbfbfb'

    }
}));

export const MashupEditor = ({ artifact, user, onUpdate, onDelete, onReply }) => {
    const theme = useTheme(),
        { ...mashupStyles } = useMashupStyles(theme),
        classes = useStyles(mashupStyles),
        { messages } = artifact,
        [mashups, setMashups] = useState(messages),
        [draft, setDraft] = useState(''),
        [editing, setEditing] = useState(false),
        [open, setOpen] = useState(null),
        { t } = useTranslation(['dm.mashup', 'dm.mashupTypes'], { useSuspense: false }),
        replyInfo = {
            default: t('replyInfo_default'),
            comment: t('replyInfo_comment'),
            inquiry: t('replyInfo_inquiry'),
            correction: t('replyInfo_correction')
        };

    useEffect(() => {
        setMashups(artifact.messages);
    }, [artifact]);

    const deleteMashup = mashup => e => {
        e.preventDefault();
        e.stopPropagation();

        if (mashup.status === 'new') {
            setEditing(false);
            setMashups(mashups.filter(m => m.timeStamp !== mashup.timeStamp));
        } else {
            onDelete && onDelete(mashup);
        }
    };

    const addMashup = mashup => e => {
        e.preventDefault();
        e.stopPropagation();

        const parent = mashups.find(m => m.uniqueId === mashup.uniqueId),
            m = {
                dimuCode: artifact.uniqueId,
                message: '',
                site: parent.site,
                documentType: parent.documentType,
                status: 'new',
                subject: parent.subject,
                parentId: parent.uniqueId,
                timeStamp: Date.now(),
                author: {
                    name: user.name,
                    imageUrl: null,
                    email: user.email
                }
            };
        
        setMashups(mashups.concat([m]));
        setEditing(true);
    };

    const replyMashup = mashup => e => {
        e.preventDefault();
        setEditing(false);
        setMashups(mashups.filter(m => m.timeStamp !== mashup.timeStamp))

        onReply && onReply({ ...mashup, message: draft });
    };

    const statusChange = (mashup, status) => {
        onUpdate && onUpdate({ ...mashup, status: status });
    };

    const handleSelectMashup = (id) => {
        if (!editing) {
            setOpen(id !== open ? id : null);
        }
    };

    const createMashupTree = () => {
        let res = [],
            parents = {};

        mashups.forEach(m => {
            if (m.parentId && mashups.findIndex(p => p.uniqueId === m.parentId) >= 0) {
                if (parents[m.parentId]) {
                    parents[m.parentId].push(m);
                } else {
                    parents[m.parentId] = [m];
                }
            } else {
                res.push(m);
            }
        });

        res = res.map(m => {
            if (parents[m.uniqueId]) {
                return {
                    ...m,
                    children: parents[m.uniqueId].sort((a, b) => new Date(b.createdAt || b.timeStamp) - new Date(a.createdAt || a.timeStamp))
                };
            } else {
                delete m.children;
            }

            return m;
        });

        return res.map(mashup => renderMashup(mashup));
    };

    const renderMashupActions = (mashup, isChild, hasChildren = false, selected = false, isNew = false) => {
        return (
            <div className={classes.listItemSecondaryAction}>
                {!isChild && selected && <IconButton
                    disabled={editing}
                    aria-label="comment"
                    onClick={addMashup(mashup)}
                    size="large">
                    <ReplyIcon />
                </IconButton>}
                {isNew && draft.length > 9 && <IconButton aria-label="done" onClick={replyMashup(mashup)} size="large">
                    <DoneIcon />
                </IconButton>}
                {(isChild || (!isChild && !hasChildren && selected)) &&
                    <IconButton
                        disabled={editing && !isNew}
                        edge="end"
                        aria-label="delete"
                        onClick={deleteMashup(mashup)}
                        size="large">
                    {isNew ? <CancelIcon /> : <DeleteIcon />}
                    </IconButton>
                }
                {hasChildren &&
                    <IconButton edge="end" aria-label="expand" size="large">
                    {!selected ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                }
            </div>
        );
    };

    const renderMashup = (mashup, isChild = false) => {
        const author = `${mashup.author.name}${mashup.author.email ? ' (' + mashup.author.email + ')' : ''}`,
            created = new Date(mashup.createdAt),
            dateTime = `${created.toLocaleDateString()} ${created.toLocaleTimeString()}`,
            replInfo = replyInfo[mashup.documentType] || replyInfo.default,
            expanded = open === mashup.uniqueId;

        return (
            <Fragment key={`frag-${dateTime}`}>
                <ListItem
                    button={!isChild && !expanded}
                    component="li"
                    key={`${mashup.uniqueId}-${mashup.status}`}
                    role={undefined}
                    className={`${expanded && mashup.children ? classes.listItemExpanded : ''} ${expanded ? classes.listItemActive : ''} ${classes.listItem}`}
                    onClick={!isChild ? () => handleSelectMashup(mashup.uniqueId) : () => { return false; }}
                >
                    <Typography className={classes.secondary}>
                        {author}
                    </Typography>
                    <div className={classes.meta}>
                        <Chip size="small" variant="outlined" label={t(`dm.mashupTypes:${mashup.documentType}`)} className={classes.typeChip} />
                        {mashup.status !== 'new' &&
                            <StatusChip
                                size="small"
                                label={t(`status_${mashup.status}`)}
                                filter={[
                                    { key: 'open', label: t('status_open', 'åpen') },
                                    { key: 'archived', label: t('status_archived', 'arkivert') }
                                ]}
                                onSelect={status => statusChange(mashup, status)}
                                className={classes.typeChip}
                            />
                        }
                        {mashup.createdAt && <Typography component={'span'} >
                            {dateTime}
                        </Typography>}
                    </div>
                    {mashup.status !== 'new'
                        ? <>
                            <Typography className={classes.message}>
                                {mashup.message}
                            </Typography>
                            {renderMashupActions(mashup, isChild, !!mashup.children, expanded)}
                        </>
                        : <>
                            <Typography className={classes.replyInfo}>
                                {replInfo}
                            </Typography>
                            <Input autoFocus placeholder="Ditt svar..." className={classes.input} onChange={e => setDraft(e.target.value)} />
                            {renderMashupActions(mashup, true, false, true, true)}
                        </>}
                    {mashup.children &&
                        <Typography className={classes.secondary}>{t('replyCount', { count: mashup.children.length })}
                            {!expanded ? <ExpandMore /> : <ExpandLess />}
                        </Typography>
                    }
                </ListItem>
                {mashup.children && (
                    <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapse}>
                        <List>
                            {mashup.children.map(mashup => renderMashup(mashup, true))}
                        </List>
                    </Collapse>
                )}
            </Fragment>
        );
    };

    return (
        <List className={classes.root}>
            {createMashupTree()}
        </List>
    );
}
