import React, { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Form from '@rjsf/material-ui/v5';

import { Button, Chip, IconButton, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircle from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';

import { formatDate } from '../common/Date';
import { MediaLib } from './mediaLib/MediaLib';
import { SaveActions } from './SaveActions';

import { updateArticle } from '../redux/actions/article';
import { WithFocus } from './WithFocus';
import { SlugEditor } from './SlugEditor';

const NoWrapperFieldTemplate = props => {
    return props.children;
};


const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '150%',
        height: 'calc(100vh - 120px)',
        marginLeft: '-50%',
        boxSizing: 'border-box',
        transition: 'margin-left .3s',
        '&.mediaLib': {
            marginLeft: 0
        }
    },
    actions: {
        display: 'flex',
        position: 'fixed',
        height: spacing(7),
        top: spacing(8),
        right: 0,
        alignItems: 'center',
        zIndex: 1,
        color: 'rgba(0, 0, 0, .87)',
        '& > *': {
            marginRight: spacing(2),
            flex: '0 0 auto',
            flexDirection: 'row'
        },
        '& > a': {
            margin: '0 auto 0 1em',
            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    saved: {
        width: 122,
        display: 'flex',
        justifyContent: 'space-around',
        color: 'green'
    },
    finder: {
        display: 'flex',
        flexDirection: 'column',
        width: '50vw',
        height: '100%',
        backgroundColor: '#e2e2e2',
        borderTop: '1px solid #ddd'
    },
    form: {
        width: '50vw',
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        border: '1px solid #ddd',
        boxSizing: 'border-box',
        '& .MuiButton-root': {
            minWidth: 'auto',
        },
        '& > .MuiFormControl-root > .MuiGrid-root': {
            marginTop: 0,
            '& > .MuiGrid-item': {
                paddingTop: 0
            }
        },
        '& .MuiInputBase-inputMultiline': {
            resize: 'vertical'
        }
    },
    preview: {
        width: '50vw',
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        backgroundColor: '#fff',
        fontSize: '18px',
        boxSizing: 'border-box',
        '&:not(.owner)': {
            borderTop: '1px solid #ddd',
            borderBottom: '1px solid #ddd',
        },
        '& h2': {
            margin: '1.5em 0 1em',
            fontSize: '1.25em',
            lineHeight: '1.25'
        },
        '&.article': {
            marginBottom: '.25em',
            paddingTop: '.75em'
        },
        '& .body': {
            position: 'relative',
            minWidth: '200%',
            height: '100%',
            top: 'calc(-100% / 4)',
            left: '-50%',
            transform: 'scale(0.5, 0.5)',
            '& .article': {
                paddingBottom: '2em'
            }
        },
        '& .article__content': {
            margin: '0 auto 0',
            minHeight: spacing(4),
            lineHeight: '1.5em',
            [breakpoints.up('md')]: {
                maxWidth: '704px',
            }
        },
        '& .article__bodytext': {
            '& p + p': {
                margin: '.5em 0',
            }
        },
        '& figure:not(.slick)': {
            margin: 0,
            padding: 0
        },
        '&.anchorMode': {
            cursor: 'alias'
        },
        '& .media__caption': {
            fontSize: '.875em',
            fontWeight: 600,
            lineHeight: 1.35
        },
        '& .media__credit': {
            fontSize: '.75em',
            fontWeight: 400,
            lineHeight: 1.35
        },
        '& .license': {
            display: 'inline-block',
            '& > img': {
                width: 'auto'
            }
        },
        '& .media__caption, & .media__credit': {
            paddingRight: '.3em',
            display: 'inline'
        }
    },
    help: {
        position: 'fixed',
        right: spacing(2),
        bottom: spacing(1)
    }
}));


export const ArticleEditor = memo(({ dbPage, schema, uiSchema, Preview }) => {
    const dispatch = useDispatch(),
        navigate = useNavigate(),
        { ownerUniqueId } = useSelector(state => state.app),
        { activeMuseum } = useSelector(state => state.user),
        { saved } = useSelector(state => state.article),
        { userData } = useSelector(state => state.user),
        [page, setPage] = useState(dbPage),
        [mediaLibIdPath, setmediaLibIdPath] = useState(),
        [mediaLibConfig, setMediaLibConfig] = useState({ dmOnly: false, items: [], open: null, schoolFilter:false, virtualFilter: false}),
        [pageChange, setPageChange] = useState(0),
        [focusedId, setFocusedId] = useState(),
        [anchorMode, setAnchorMode] = useState(false),
        [isMuseumUrlDialogOpen, setIsMuseumUrlDialogOpen] = useState(false),
        { t } = useTranslation(['dm.article'], { useSuspense: false }),
        classes = useStyles();

    // const validate = (formData, errors) => errors;

    const onFormChanged = form => {
        console.log('form changed');

        setPage(form.formData);
        setPageChange(pageChange + 1);
    };

    const onFocus = (idPath /*, value*/) => {
        setFocus(idPath);
    };
    
    const setFocus = idPath => {
        if (!mediaLibConfig.open) {
            setFocusedId(idPath);
        }
    }

    const onSave = newStatus => {
        const p = {
            ...page,
            status: newStatus,
            active_revision_editor_version: window._env_.REACT_APP_VERSION,
        };

        setPageChange(0);
        dispatch(updateArticle(ownerUniqueId, p));
    };

    const onCancel = () => {
        switch (page.page_type) {
            case 'owner':
                navigate('/homepage');
                break;
            case 'museum':
                navigate('/museumpage');
                break;
            default:
                navigate('/article');
        }
    };

    const idPathToPropPath = idPath => {
        return idPath.replace('root_', '').replace(/_/g, '.');
    }

    const setPropInPage = (path, value, action = 'add', last = true) => {
        const pathArr = path.split('.'),
            [key] = pathArr.slice(-1);

        let p = { ...page };

        const updated = pathArr.reduce(function (a, b) {
            if (a?.hasOwnProperty(b) && b === key) {
                if (Array.isArray(a[b])) {
                    if (['remove', 'update'].includes(action)) {
                        const idx = a[b].findIndex(x => x.image_id === value[0].image_id);
                        if (idx >= 0) {
                            if (action === 'remove') {
                                a[b].splice(idx, 1);
                            } else {
                                a[b][idx] = value[0];
                            }
                        }
                    } else {
                        a[b] = a[b].concat(value);
                    }
                } else {
                    a[b] = value;
                }
            } else if (action === 'add' && b === key) {
                a[b] = value;
            }
            return a?.[b];
        }, p);

        if (last && typeof updated !== 'undefined') {
            setPage(p);
            setPageChange(pageChange + 1);
            console.log('form changed from outside');
        }

        return updated;
    };

    const onMediaLibOpen = (idPath, items, changeHandler, dmOnly = false, schoolFilter = false, virtualFilter = false) => {
        setMediaLibConfig({
            dmOnly: dmOnly,
            items: items,
            changeHandler: changeHandler,
            open: Date.now(),
            mediaTypeFilter: parseInt(idPath.split('_')[3]) === 0 ? 'image' : false,
            schoolFilter : schoolFilter,
            virtualFilter: virtualFilter
        });
        setmediaLibIdPath(idPath);
    };

    const onMediaLibRemoveIndex = idx => {
        let list = [...mediaLibConfig.items];
        list.splice(idx, 1);
        setMediaLibConfig({ ...mediaLibConfig, items: list });
    };

    const onMediaLibReorder = (from, to) => {
        let list = [...mediaLibConfig.items];
        list.splice(to, 0, list.splice(from, 1)[0]);
        setMediaLibConfig({ ...mediaLibConfig, items: list });
    };

    const onMediaLibClose = () => {
        setMediaLibConfig({ ...mediaLibConfig, open: false });
    };

    const onMediaSelected = (mediaList, remove = false, update = false) => {
        const path = mediaLibIdPath && idPathToPropPath(mediaLibIdPath);

        if (remove) {
            const idx = mediaLibConfig.items.findIndex(x => x.image_id === remove.image_id);
            mediaLibConfig.changeHandler(idx);
            onMediaLibRemoveIndex(idx);
        } else if (path) {
            setMediaLibConfig({ ...mediaLibConfig, items: mediaLibConfig.items.concat(mediaList) });
            setPropInPage(path, mediaList, update ? 'update' : 'add');
        } else {
            alert('Du må velge hvor i artikkelen du vil ha bildet ved å klikke "Legg til bilde"');
        }
    };

    const onPreviewClick = (section, block, blockType = 'blocks') => {
        const path = 'content.sections.' + section,
            sectionCollapsed = page.content.sections[section]._editor._collapse,
            blockCollapsed = page.content.sections[section][blockType][block]._editor._collapse;

        let scrollEl = document.getElementById(`root_${path.replace(/\./g, '_')}_${blockType}_${block}__editor__collapse`);

        if (sectionCollapsed) {
            try {
                const s = document.getElementById(`sections_${section}`);
                s.firstElementChild.click();
            } catch (err) { }
        }

        if (blockCollapsed) {
            try {
                setTimeout(() => {
                    const s = document.getElementById(`sections_${section}_${blockType}_${block}`);
                    s.firstElementChild.click();
                }, sectionCollapsed ? 300 : 0);
            } catch (err) { }
        }

        while (scrollEl.tagName !== 'LI' && scrollEl.parentElement) {
            scrollEl = scrollEl.parentElement;
        }

        setTimeout(() => {
            scrollEl?.scrollIntoView({ behavior: 'smooth' });
        }, sectionCollapsed && blockCollapsed ? 800 : blockCollapsed ? 500 : 0);
    };

    const onAddPlace = (place, idPath) => {
        if (idPath) {
            setPropInPage(idPath, place);
        }
    };

    const openPreview = () => {
        const getPreviewUrl = () => {
            switch (page.page_type) {
                case 'article':
                    return page.dimu_unique_id;
                case 'museum':
                    return page.slug;
                default:
                    return `owners/${page.owner_identifier}`;
            }
        };

        const url = `${window._env_.REACT_APP_DM4_URL}/${page.status === 'draft'
            ? 'article/' + page.page_id
            : getPreviewUrl()}`;

        window.open(url);
    };

    const renderPreviewButton = () => {
        if (page.page_type === 'museum' && page.status === 'draft') {
            return <Chip label={t(`${page.status}`)} />;
        }

        return <Chip label={t(`${page.status}`)} onDelete={() => openPreview()} deleteIcon={<VisibilityIcon />} />
    };

    const openSlugEditor = () => {
        setIsMuseumUrlDialogOpen(true);
    };

    const onAnchorMode = (idPath, value, onChange) => {
        if (!idPath) {
            setAnchorMode(false);
            return;
        }

        const handler = id => {
            setAnchorMode({ value: id, handler: handler });
            onChange(id);
        };

        setAnchorMode({ value: value, handler: handler });
    };

    useEffect(() => {
        console.log('initializing Article Editor');
    }, []);

    return (
        <article className={`article-editor ${classes.root}${mediaLibConfig.open ? ' mediaLib' : ''}`}>
            {/* <Prompt when={!!pageChange} message={t('unsaved-alert', 'Du har ulagrede endringer som vil gå tapt. Er du sikker på at du vil avslutte?')} /> */}
            <section className={classes.actions}>
                {renderPreviewButton()}
                <Typography>{formatDate(page.updated_at)}</Typography>
                {page.page_type === 'museum' && page.dimu_unique_id && userData.appAdmin && (
                    <>
                        <Button onClick={openSlugEditor} disabled={isMuseumUrlDialogOpen} variant='contained'>Museum url</Button>
                        <SlugEditor
                            ownerUniqueId={ownerUniqueId}
                            dimuCode={page.dimu_unique_id}
                            open={isMuseumUrlDialogOpen}
                            openHandler={setIsMuseumUrlDialogOpen}
                        />
                    </>
                )}
                <Button variant='contained' disabled={!pageChange} onClick={onCancel} className={classes.cancelBtn} color='primary'>{t('exit-without-saving', 'Avbryt')}</Button>
                {(saved && !pageChange)
                    ? (
                        <div className={classes.saved}>
                            <Typography>Lagret</Typography>
                            <CheckCircle />
                        </div>
                    )
                    : <SaveActions
                        disabled={!pageChange}
                        status={page.status}
                        onSave={onSave}
                        allowed={page.page_type === 'museum'
                            ? ['save', 'publish']
                            : undefined
                        }
                    />
                }
            </section>
            <section className={classes.finder}>
                <MediaLib onMediaSelected={onMediaSelected} onMediaLibClose={onMediaLibClose} config={mediaLibConfig} />
            </section>
            <Form
                className={classes.form}
                schema={schema}
                uiSchema={uiSchema}
                formData={page}
                formContext={{
                    owner: { ...activeMuseum, knavUUID: page.knav_uuid },
                    pageType: page.page_type,
                    t: t,
                    theme: {
                        primary: activeMuseum.primary_color,
                        dark: activeMuseum.dark_color,
                        light: activeMuseum.light_color
                    },
                    mediaLib: {
                        isOpen: mediaLibConfig?.open,
                        open: onMediaLibOpen,
                        remove: onMediaLibRemoveIndex,
                        reorder: onMediaLibReorder
                    },
                    onAddPlace: onAddPlace,
                    setFocus: setFocus,
                    onAnchorMode: onAnchorMode
                }}
                // onCancel={this.onCancel}
                // onSubmit={this.onSubmit}
                // onUpload={this.onUpload}
                onChange={onFormChanged}
                onFocus={onFocus}
                children={true}
                // validate={validate}
                FieldTemplate={NoWrapperFieldTemplate}
            // submitOnEnter
            // activityIndicatorEnabled
            />
            <div id='preview' className={`${classes.preview} template--${page.page_type} ${page.page_type}${anchorMode ? ' anchorMode' : ''}`}>
                <div className='body'>
                    <WithFocus focusId={focusedId}>
                        <Preview data={page} onPreviewClick={!anchorMode && onPreviewClick} anchorObj={!!anchorMode && anchorMode} museum={activeMuseum} />
                    </WithFocus>
                </div>
            </div>
            <div className={classes.help}>
                <IconButton
                    aria-label='help'
                    color='primary'
                    onClick={() => window.open('https://dok.digitaltmuseum.org/en/editor')}
                    size='large'>
                    <HelpIcon />
                </IconButton>
            </div>
        </article>
    );
});
