import "./button.css"

export const cardButton = ({
    label,
    onClick,
}) => {
    const btn = document.createElement('button');
    btn.className = 'card-button';
    btn.type = 'button';
    btn.addEventListener('click', onClick);

    const divLabel = document.createElement('div');
    divLabel.innerText = label;
    divLabel.className = 'card-button-label';

    btn.appendChild(divLabel);

    btn.appendChild(renderRightArrow())

    return btn;
};

function renderRightArrow() {
    const svgWrapper = document.createElement('div');
    svgWrapper.className = 'card-button-svg';

    const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');

    iconSvg.setAttribute('width', '24');
    iconSvg.setAttribute('height', '24');
    iconSvg.setAttribute('viewBox', '0 0 24 24');
    iconSvg.setAttribute('fill', 'none');

    iconPath.setAttribute('d', 'M5 12H19 M12 5L19 12L12 19');
    iconPath.setAttribute('stroke', '#0C0B0F');
    iconPath.setAttribute('stroke-width', '2');
    iconPath.setAttribute('stroke-linecap', 'round');
    iconPath.setAttribute('stroke-linejoin', 'round');

    iconSvg.append(iconPath);

    svgWrapper.append(iconSvg);

    return svgWrapper;
}
