import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Input, List } from '@mui/material';
import Pagination from '@mui/material/Pagination';

import { requestArticles } from '../redux/actions/article';
import { setExportEnabled, setFinderMode } from '../redux/actions/layout';

import { Error } from '../common/Error';
import { NoData } from '../common/NoData';
import { ProgressIndicator } from '../common/ProgressIndicator';
import { ArticleListCard } from './ArticleListCard';

import { useMashupStyles } from '../Mashup/mashupStyles';

const useStyles = makeStyles(() => ({
    list: props => ({ ...props }),
    addBtn: { marginBottom: '1em' }
}));

let timer;

export const ArticlesList = () => {
    const dispatch = useDispatch(),
        navigate = useNavigate(),
        location = useLocation(),
        { t } = useTranslation(['dm.article'], { useSuspense: false }),
        theme = useTheme(),
        { root } = useMashupStyles(theme),
        classes = useStyles(root),
        [ready, setReady] = useState(false),
        [page, setPage] = useState(1),
        [query, setQuery] = useState(),
        [noOfPages, setNoOfPages] = useState(0),
        { userData } = useSelector(state => state.user),
        { ownerUniqueId } = useSelector(state => state.app),
        { view } = useSelector(state => state.layout),
        { rows, countFound, fetching, error } = useSelector(state => state.articleList),
        itemsPerPage = 20;

    useEffect(() => {
        if (location.pathname === '/article') {
            dispatch(setFinderMode('articles-list'));
        } else if (location.pathname === '/homepage') {
            dispatch(setFinderMode('homepage-list'));
        } else {
            dispatch(setFinderMode('museumpage-list'));
        }

        dispatch(setExportEnabled(false));
    }, [dispatch, location.pathname]);

    useEffect(() => {
        dispatch(requestArticles(ownerUniqueId, location.pathname, itemsPerPage * (page - 1), itemsPerPage, query));
    }, [dispatch, ownerUniqueId, location.pathname, itemsPerPage, page, query]);
    
    useEffect(() => {
        if (fetching) {
            setReady(false);
        } else if (fetching === null || (!error && !fetching)) {
            if (rows) {
                setReady(true);
            }
        }
    }, [dispatch, fetching, error, rows, ownerUniqueId, userData.uniqueId]);

    useEffect(() => {
        setNoOfPages(Math.ceil(countFound / itemsPerPage));
    }, [countFound]);

    const onSelect = pageId => {
        let pageType;

        if (view === 'articles-list') {
            pageType = 'article';
        } else if (view === 'homepage-list') {
            pageType = 'homepage'
        } else {
            pageType = 'museumpage'
        }

        navigate(`/${pageType}/edit/${pageId}`);
    };

    const setQueryString = query => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            setQuery(query);
            setPage(1);
        }, 1000);
    };

    const renderArticleCard = (item, index) => {
        if (item.page_type === 'owner') {
            return null;
        }

        return (
            <ArticleListCard article={item} onSelect={onSelect} key={`${index}`} />
        )
    };

    const newArticleLabel = () => {
        if (view === 'articles-list') {
            return t('article-list.new_article', 'Opprett ny artikkel');
        } else if (view === 'homepage-list') {
            return 'Opprett ny hjemmeside';
        } else {
            return t('article-list.new_museumpage', 'Opprett ny museumsside');
        }
    };

    const searchLabel = () => {
        if (view === 'articles-list') {
            return t('article-list.filter_placeholder', 'Søk i artikkeltittel');
        } else if (view === 'homepage-list') {
            return t('homepage-list.filter_placeholder', 'Søk i hjemmesider');
        } else {
            return t('museumpage-list.filter_placeholder', 'Søk i museumsider');
        }
    };

    const handleChangePagination = (_, value) => {
        setPage(value);
    };

    const renderCreateArticleButton = () => (
        <Button className={classes.addBtn} variant='contained' color='primary' onClick={() => onSelect('new')}>
            {t(`article-list.add.${location.pathname}`, newArticleLabel())}
        </Button>
    );

    const renderResult = () => {
        return (
            rows?.length
                ? <List className={classes.list}>{rows?.map(renderArticleCard)}</List>
                : <NoData />
        );
    };

    if (error) {
        return <Error />;
    }

    return (
        <>
            {(view === 'articles-list' || (view === 'museumpage-list' && countFound === 0)) && renderCreateArticleButton()}
            {(query || countFound > itemsPerPage) && (
                <Input
                    fullWidth
                    autoFocus
                    defaultValue={query}
                    placeholder={searchLabel()}
                    onChange={e => setQueryString(e.target.value)}
                    sx={{ marginBottom: !ready ? '.5em' : 0 }}
                />
            )}
            {!ready
                ? <ProgressIndicator />
                : renderResult()
            }
            {(ready && countFound > itemsPerPage) && (
                <Pagination
                    count={noOfPages}
                    page={page}
                    onChange={handleChangePagination}
                    defaultPage={1}
                    color='primary'
                    size='large'
                    showFirstButton
                    showLastButton
                    classes={{ ul: classes.paginator }}
                />
            )}
        </>
    );
};
