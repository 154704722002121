import { Trans } from 'react-i18next';

import { uniqueKey } from '../body/uniqueKey';

export const museumIngressBlock = {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="label_ingress">Ingress</Trans>,
    properties: {
        type: { enum: ['museum_ingress'] },
        ingress: {
            type: 'string',
            title: 'Tekst fra KulturNav',
            readOnly: true
        },
        override: {
            type: 'boolean',
            title: 'Egendefinert ingress',
            default: false
        },
        _editor: { '$ref': '#/definitions/_editor' },
        _unique_key: uniqueKey
    },
    dependencies: {
        override: {
            oneOf: [{
                properties: {
                    override: {
                        enum: [true],
                    },
                    bodytext: {
                        type: 'string',
                        title: <Trans ns="dm.article" i18nKey="label_description">Ingress</Trans>
                    }
                },
                required: ['bodytext']
            },
            {
                properties: {
                    override: {
                        enum: [false],
                    }
                }
            }]
        }
    }
};
