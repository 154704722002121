import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';

import { activateMuseumSlug, addMuseumSlug, requestMuseumSlugs } from '../redux/actions/slugs';
import { ProgressIndicator } from '../common/ProgressIndicator';
import { Input } from '@mui/material';


const useStyles = makeStyles(({ spacing }) => ({
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: spacing(1),
        flex: 1
    },
    info: {
        display: 'block',
        marginBottom: spacing(1),

        '&.error': {
            color: 'red'
        }
    },
    addSlug: {
        marginTop: spacing(2),
        '& button': {
            marginLeft: spacing(1)
        }
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />;
});

export const SlugEditor = ({ ownerUniqueId, dimuCode, open, openHandler }) => {
    const classes = useStyles(),
        dispatch = useDispatch(),
        { slugs, error, fetching } = useSelector(state => state.slugs),
        [newSlug, setNewSlug] = useState();

    const editDialogClose = () => {
        openHandler(false);
    };

    const onSubmit = () => {
        dispatch(addMuseumSlug(ownerUniqueId, dimuCode, newSlug));
    };

    const onSelect = slug => {
        dispatch(activateMuseumSlug(ownerUniqueId, slug.slug));
    };

    const renderSlug = slug => {
        return (
            <Chip
                key={slug.slug}
                label={slug.slug}
                onDelete={() => slug.archived_at && onSelect(slug)}
                color={'secondary'}
                deleteIcon={slug.archived_at ? <RadioButtonUncheckedIcon /> : <RadioButtonCheckedIcon />}
            />
        );
    };

    const renderError = () => {
        const msg = () => {
            switch (error) {
                case 400:
                    return 'Det oppsto en feil ved endring av aktiv url!';
                case 409:
                    return 'Det oppsto en konflikt. Finnes url fra før?';
                default:
                    return 'Det oppsto en ukjent feil';
            }
        }

        return <Typography variant='h6' className={`${classes.info} error`}>{msg()}</Typography>;
    };

    useEffect(() => {
        dispatch(requestMuseumSlugs(ownerUniqueId));
    }, [dispatch, ownerUniqueId]);

    return (
        <Dialog
            open={open}
            onClose={editDialogClose}
            TransitionComponent={Transition}
            color='primary'
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant='h6' className={classes.title}>Endre aktiv url i portal</Typography>
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={editDialogClose}
                        aria-label='close'
                        size='large'>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box m={3}>
                {slugs === null
                    ? <ProgressIndicator />
                    : (
                        <>
                            {error && renderError()}
                            <Typography variant='p' className={classes.info}>Inaktive url'er videresendes til aktiv url</Typography>
                            {fetching && <ProgressIndicator />}
                            {slugs.map(renderSlug)}
                            <Box className={classes.addSlug} m={0}>
                                <Input onChange={e => setNewSlug(e.target.value)} />
                                <Button type='submit' variant='contained' color='primary' className={classes.button} onClick={onSubmit}>Legg til & Aktiver</Button>
                            </Box>
                        </>
                    )
                }
            </Box>
        </Dialog>
    );
};
