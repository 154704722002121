import { licenseMapper } from '../../helpers/licenseMapper';

export const dmObjMapper = item => {
    return {
        objectId: item.identifier,
        identifier: item.identifier,
        type: 'DM',
        image_id: item.image_id,
        published: item.published,
        dimuId: item.dimu_code,
        source: 'digitaltmuseum',
        url: item.image_url,
        urls: item.image_urls,
        title: item.title,
        description: item.description,
        artifactType: item.artifact_type,
        subtype: item.subtype,
        uuid: item.uuid,
        image_count: item.picture_count,
        producer: item.producer,
        owner_name: item.owner_name,
        owner_identifier: item.owner_identifier,
        licenses: (item.licenses && licenseMapper(item.licenses[0])) || [],
        local: {
            credit: item.producer ? `${item.producer} / ${item.owner_name}` : item.owner_name,
            description: item.description || item.titledesc
        },
        media: {
            width: null,
            height: null,
            ratio: item.image_ratio
        },
        settings: {
            size: 'small'
        }
    }
};
