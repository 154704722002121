import { Trans } from 'react-i18next';

import { uniqueKey } from '../body/uniqueKey';

export const museumUrlBlock = {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="label_url">Hjemmeside</Trans>,
    properties: {
        type: { enum: ['museum_url'] },
        settings: { '$ref': '#/definitions/settings' },
        url: {
            type: 'string',
            title: 'Hjemmeside fra KulturNav',
            readOnly: true
        },
        override: {
            type: 'boolean',
            title: 'Egendefinert hjemmeside',
            default: false
        },
        _editor: { '$ref': '#/definitions/_editor' },
        _unique_key: uniqueKey
    },
    dependencies: {
        override: {
            oneOf: [{
                properties: {
                    override: {
                        enum: [true],
                    },
                    customUrl: {
                        type: 'string',
                        title: <Trans ns="dm.article" i18nKey="label_url">Hjemmeside</Trans>
                    }
                },
                required: ['customUrl']
            },
            {
                properties: {
                    override: {
                        enum: [false],
                    }
                }
            }]
        }
    }
};
