import { kitFetch } from '@ekultur/fetch';
import { config } from '../../config';

export const ARTICLE_REQUESTED = 'ARTICLE_REQUESTED';
export const ARTICLE_SUCCEEDED = 'ARTICLE_SUCCEEDED';
export const ARTICLES_REQUESTED = 'ARTICLES_REQUESTED';
export const ARTICLES_SUCCEEDED = 'ARTICLES_SUCCEEDED';
export const ARTICLE_UPDATE_REQUESTED = 'ARTICLE_UPDATE_REQUESTED';
export const ARTICLE_UPDATED = 'ARTICLE_UPDATED';
export const ARTICLE_AFTER_UPDATE = 'ARTICLE_AFTER_UPDATE';
export const ARTICLE_DELETE_REQUESTED = 'ARTICLE_DELETE_REQUESTED';
export const ARTICLE_DELETED = 'ARTICLE_DELETED';
export const ARTICLE_FAILED = 'ARTICLE_FAILED';
export const ARTICLES_FAILED = 'ARTICLES_FAILED';
export const EDITOR_RESET = 'EDITOR_RESET';

export const requestArticles = (ownerId, pageType = 'article', offset = 0, limit = 100, q = null) => dispatch => {
    dispatch({
        type: ARTICLES_REQUESTED
    });

    if (pageType !== '/homepage') {
        pageType = pageType.replace('page', '');
    }

    kitFetch(`${config.get('articleApi')}page${pageType}/${ownerId}/?offset=${offset}&limit=${limit}${q ? '&q=' + q : ''}`)
        .then(data => dispatch({
            type: ARTICLES_SUCCEEDED,
            data: data.pages,
            countFound: data.total_count,
            owner: ownerId
        }))
        .catch(error => dispatch({
            type: ARTICLES_FAILED,
            owner: ownerId,
            data: error
        }));
};

export const requestArticle = (ownerId, pageType, pageId) => dispatch => {
    dispatch({
        type: ARTICLE_REQUESTED
    });

    kitFetch(`${config.get('articleApi')}page/${pageType}/${ownerId}/${pageId}/`)
        .then(page => dispatch({
            type: ARTICLE_SUCCEEDED,
            data: page
        }))
        .catch(error => dispatch({
            type: ARTICLE_FAILED,
            data: error,
            pageId: pageId
        }));
};

export const resetEditorState = () => dispatch => {
    dispatch({
        type: EDITOR_RESET
    });
};

export const updateArticle = (owner, data) => dispatch => {
    dispatch({
        type: ARTICLE_UPDATE_REQUESTED
    });

    const postConfig = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    kitFetch(`${config.get('articleApi')}page/update/?owner=${owner}`, postConfig)
        .then(page => dispatch({
            type: page['error-id'] ? ARTICLE_FAILED : ARTICLE_UPDATED,
            data: page['error-id'] ? page.message : page
        }))
        .catch(error => dispatch({
            type: ARTICLE_FAILED,
            data: error
        }));
};

export const requestNewArticle = (owner, pageType) => dispatch => {
    dispatch({
        type: ARTICLE_REQUESTED
    });

    kitFetch(`${config.get('articleApi')}page/new/${pageType}/?owner=${owner}`)
        .then(page => dispatch({
            type: page['error-id'] ? ARTICLE_FAILED : ARTICLE_SUCCEEDED,
            data: page['error-id'] ? page.message : page,
            created: true
        }))
        .catch(error => dispatch({
            type: ARTICLE_FAILED,
            data: error
        }));
};

export const deleteArticle = (pageId, owner) => dispatch => {
    dispatch({
        type: ARTICLE_DELETE_REQUESTED
    });

    const postConfig = { method: 'DELETE' };

    kitFetch(`${config.get('articleApi')}page/${pageId}/?owner=${owner}`, postConfig)
        .then(page => dispatch({
            type: page['error-id'] ? ARTICLE_FAILED : ARTICLE_DELETED,
            data: page['error-id'] ? page.message : pageId
        }))
        .catch(error => dispatch({
            type: ARTICLE_FAILED,
            data: error
        }));
};
