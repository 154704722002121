import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from './ConfirmDialog';

const useStyles = makeStyles(({ spacing }) => ({
    actions: {
        width: '50px',
        height: '100%',
        paddingLeft: spacing(1),
        paddingRight: spacing(1)
    }
}));

export const ArticleListActions = ({ onDelete, openPreview }) => {
    const [confirmOpen, setConfirmOpen] = useState(false),
        { t } = useTranslation(['dm.common'], { useSuspense: false }),
        classes = useStyles();

    return (
        <div className={classes.actions}>
            <IconButton aria-label="preview" onClick={openPreview} size="large">
                <VisibilityIcon fontSize='small' />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => setConfirmOpen(true)} size="large">
                <DeleteIcon fontSize='small' />
            </IconButton>
            <ConfirmDialog
                title={t('delete-confirm_title', 'Slett artikkel')}
                confirmLabel={t('delete-confirm_yes', 'Ja')}
                cancelLabel={t('delete-confirm_no', 'Nei')}
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={onDelete}
            >
                {t('delete-confirm_message', 'Er du sikker?')}
            </ConfirmDialog>
        </div>
    );
};
