import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';

const useMuseumStyles = makeStyles(theme => ({
    avatar: {
        color: props => theme.palette.getContrastText(props.backgroundColor || theme.palette.primary.main),
        backgroundColor: props => props.backgroundColor || theme.palette.primary.main,
        fontSize: '1rem'
    },
}));

export const MuseumItem = ({ museum, onSelect }) => {
    const museumClasses = useMuseumStyles({ backgroundColor: museum.primary_color });

    return (
        <ListItem button={true} onClick={() => onSelect(parseInt(museum.id))}>
            <ListItemAvatar>
                <Avatar className={museumClasses.avatar}>
                    {museum.short_name}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={museum.name} />
        </ListItem>
    );
};
