import React from 'react';
import { Trans } from 'react-i18next';

export const sort = [
    { value: 'published', label: <Trans ns="dm.article" i18nKey="search-filter_published">Publiseringsdato</Trans> },
    { value: 'updated', label: <Trans ns="dm.article" i18nKey="search-filter_updated">Sist oppdatert</Trans> },
    { value: 'relevance', label: <Trans ns="dm.article" i18nKey="search-filter_relevance">Relevanse</Trans> },
    { value: 'title', label: <Trans ns="dm.article" i18nKey="search-filter_title">Tittel</Trans> },
    { value: 'place', label: <Trans ns="dm.article" i18nKey="search-filter_place">Sted</Trans> },
    { value: 'production', label: <Trans ns="dm.article" i18nKey="search-filter_production">Produksjonsdato</Trans> },
    { value: 'producer', label: <Trans ns="dm.article" i18nKey="search-filter_producer">Produsent</Trans> },
    { value: 'identifier', label: <Trans ns="dm.article" i18nKey="search-filter_identifier">ID</Trans> }
];
