import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Link from '@mui/material/Link';
import TableSortLabel from '@mui/material/TableSortLabel';
import { TableContainer } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';

import { requestStatistics, exportReport } from '../redux/actions/statistics';
import { setTimePeriod } from '../redux/functions/statistics';
import { getValueFromQueryString } from '../redux/functions/query';
import { setFinderMode, setExportEnabled } from '../redux/actions/layout';

import { Error } from '../common/Error';
import { NoData } from '../common/NoData';
import { ProgressIndicator } from '../common/ProgressIndicator';
import { WithProgressOverlay } from '../common/WithProgressOverlay';
import { StatisticsSwitchMessage } from './StatisticsSwitchMessage';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        padding: 10,
        boxSizing: 'border-box'
    },
    totalRow: {
        background: palette.action.selected
    },
    detailRow: {
        cursor: 'pointer',
        '&:hover .title': {
            textDecoration: 'underline'
        }
    }
}));

export const StatisticsReportMetricsTable = ({ viewType }) => {
    const dispatch = useDispatch(),
        classes = useStyles(),
        location = useLocation(),
        navigate = useNavigate(),
        {t, ready} = useTranslation('googleAnalytics', {useSuspense: false}),
        pathArr = location.pathname.split('/'),
        reportType = pathArr[2] || 'pageViews',
        {objectType} = useParams(),
        [order, setOrder] = useState({ by: 'uniquePageviews', descending: true }),
        [paging, setPaging] = useState({rows: 10, page: 0}),
        period = getValueFromQueryString('period'),
        { reports, error } = useSelector(state => state.gaStatistics),
        pageViews = reports && reports[viewType][reportType],
        exportRequested = reports.export.requested,
        { ownerUniqueId } = useSelector(state => state.app),
        { filters, currentDetail } = useSelector(state => state.layout),
        timePeriod = setTimePeriod(filters, currentDetail, period, false, true),
        dim = (reportType !== 'objectTypes' || !!objectType) ? 'dimension2,dimension3' : 'dimension4',
        met = 'uniquePageviews,users,avgTimeOnPage,bounceRate',
        fltr = objectType ? 'dimension4:' + objectType : null;

    useEffect(() => {
        if (timePeriod.start && timePeriod.end) {
            dispatch(requestStatistics(ownerUniqueId, timePeriod.start, timePeriod.end, reportType,
                viewType, paging.page * paging.rows, dim, met,
                order.by, order.descending ? 'desc' : 'asc', false, fltr, paging.rows));
        }
    }, [dispatch, ownerUniqueId, timePeriod.start, timePeriod.end, viewType, order.by, order.descending,
        paging.page, paging.rows, reportType, dim, met, fltr]);

    useEffect(() => {
        if (exportRequested && timePeriod.start && timePeriod.end) {
            dispatch(exportReport(ownerUniqueId, timePeriod.start, timePeriod.end,
                dim, met, 'uniquePageviews', reportType));
        }
    }, [dispatch, ownerUniqueId, timePeriod.start, timePeriod.end, reportType, dim, met, exportRequested]);

    useEffect(() => {
        dispatch(setFinderMode('statistics', period));
        dispatch(setExportEnabled(true));
    }, [dispatch, period]);

    const orderHandler = headerName => {
        if(headerName === order.by) {
            setOrder({
                ...order,
                descending: !order.descending
            })
        } else {
            setOrder({
                by: headerName,
                descending: true
            })
        }
    };

    const getDirection = headerName => {
        if(order.by === headerName) {
            return order.descending ? 'desc': 'asc'
        } else {
            return 'desc'
        }
    };

    const changePageHandler = newPage => {
        setPaging({
            ...paging,
            page: newPage
        })
    };

    const changeRowsPerPageHandler = event => {
        setPaging({
            rows: parseInt(event.target.value, 10),
            page: 0
        })
    };

    const onExpand = path => {
        navigate(`${location.pathname}/${path}`);
    };

    const renderHeaderRow = row => {
        return (
            row.cells.map((cell, index) => (
                <TableCell align="right" key={index}>
                    {cell}
                </TableCell>
            ))
        );
    };

    if (error) {
        return <Error />;
    } else if (!pageViews.rowCount) {
        return pageViews.fetching ? <ProgressIndicator /> : <NoData />;
    } else if (!pageViews.headers.length || !ready) {
        return <ProgressIndicator />;
    }

    return (
        <WithProgressOverlay loading={pageViews.fetching}>
            <StatisticsSwitchMessage />
            <Paper className={classes.root} elevation={3}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow key='headerRow'>
                                <TableCell key="emptyCell" />
                                {pageViews.headers.map(header => (
                                    <TableCell align="right" key={header}>
                                        <TableSortLabel
                                            active={order.by === header}
                                            direction={getDirection(header)}
                                            onClick={() => orderHandler(header)}
                                            disabled={pageViews.fetching}
                                        >
                                        {t(header)}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow key='totalRow' className={classes.totalRow}>
                                <TableCell key="headerCell">Totalt:</TableCell>
                                {pageViews.totals.map((total, index) => (
                                    <TableCell align="right" key={index}>
                                        {total}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageViews.rows.map(row => (
                                (reportType === 'objectTypes' && !objectType) ? (
                                    <TableRow key={row.header.title} className={classes.detailRow} onClick={() => onExpand(row.header.title)}>
                                        <TableCell component="th" scope="row" key="headerCell">
                                            <Typography variant="subtitle2" className="title">
                                                {(reportType === 'objectTypes' && !objectType)
                                                    ? t(`dm.objectTypes:${row.header.title.toLowerCase()}`)
                                                    : row.header.title}
                                            </Typography>
                                        </TableCell>
                                        {renderHeaderRow(row)}
                                    </TableRow>
                                ) : (
                                    <TableRow key={row.header.id}>
                                        <TableCell component="th" scope="row" key="headerCell">
                                            <Link
                                                href={`https://digitaltmuseum.org/${row.header.id}`}
                                                rel="noreferrer"
                                                target="_blank"
                                                color="inherit"
                                            >
                                            {row.header.title}
                                            </Link>
                                        </TableCell>
                                        {renderHeaderRow(row)}
                                    </TableRow>
                                )
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={pageViews.rowCount}
                    rowsPerPage={paging.rows}
                    page={paging.page}
                    onPageChange={(event, newPage) => changePageHandler(newPage)}
                    onRowsPerPageChange={changeRowsPerPageHandler}
                    backIconButtonProps={{disabled: pageViews.fetching || paging.page === 0}}
                    nextIconButtonProps={{disabled: pageViews.fetching || isLastPage(pageViews, paging)}}
                    SelectProps={{disabled: pageViews.fetching}}
                    labelRowsPerPage="Rader per side:"
                    labelDisplayedRows={displaysRowsText}
                />
            </Paper>
        </WithProgressOverlay>
    );
};

const isLastPage = (pageViews, paging) => (Math.floor(pageViews.rowCount / paging.rows)) === paging.page;

const displaysRowsText = ({from, to, count}) => {
    const calculatedTo = to === -1 ? count : to;
    return `${from}-${calculatedTo} av ${count}`;
};
