// import { Trans } from 'react-i18next';
import {
    daysAgo, onlyHours, today, withAddedDays, withAddedMonths, withAddedWeeks,
    formatDays, formatHours, formatMonths, formatWeeks
} from './date/dateFormatter';

export const parseDate = gaDate => {
    const gaDateLowered = gaDate.toLowerCase();

    if (gaDateLowered.includes('daysago')) {
        return daysAgo(parseInt(gaDateLowered));
    } else if (gaDateLowered.includes('yesterday')) {
        return daysAgo(1);
    } else if (gaDateLowered === 'today') {
        return today();
    } else {
        return gaDate;
    }
};

export const parseGADimension = (period, dimension, value, t) => {
    if (!period.startDate || !period.endDate) {
        return value;
    }

    switch (dimension) {
        case 'nthHour':
            return onlyHours(value)
        case 'nthDay':
            return withAddedDays(period.startDate, value)
        case 'nthMonth':
            return withAddedMonths(period.startDate, value)
        case 'nthWeek':
            // const label = <Trans ns="dm.common" i18nKey="week" defaults="Uke" />;
            return `${t('dm.common:week', 'Uke')} ${withAddedWeeks(period.startDate, value)}`;
        default:
            return value
    }
};

export const parsePlausibleDimension = (period, dimension, value, t) => {
    if (!period.startDate || !period.endDate) {
        return value;
    }

    switch (dimension) {
        case 'nthHour':
            return formatHours(value)
        case 'nthDay':
            return formatDays(value)
        case 'nthMonth':
            return formatMonths(value)
        case 'nthWeek':
            // const label = <Trans ns="dm.common" i18nKey="week" defaults="Uke" />;
            return `${t('dm.common:week', 'Uke')} ${formatWeeks(value)}`;
        default:
            return value
    }
};
