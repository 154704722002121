import { Trans } from 'react-i18next';

import { uniqueKey } from '../body/uniqueKey';

export const phoneBlock = {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="label_phone">Telefon</Trans>,
    properties: {
        type: { enum: ['museum_phone'] },
        settings: { '$ref': '#/definitions/settings' },
        text: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_phone">Telefon</Trans>,
        },
        _editor: { '$ref': '#/definitions/_editor' },
        _unique_key: uniqueKey
    }
};
