import React from 'react';
import { useTheme } from '@mui/material';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

export const CountriesBar = ({ data, dataMax, t }) => {
    const { palette } = useTheme();

    if (!data.length) {
        return null;
    }

    return (
        <ResponsiveContainer
            height={130}
            id="countriesBarResponsive"
        >
            <BarChart
                layout="vertical"
                data={data}
            >
                <XAxis
                    type="number"
                    domain={[0, dataMax]}
                    axisLine={false}
                    tickLine={false}
                    tick={false}
                    padding={{ right: 100 }}
                />
                <YAxis
                    dataKey="label"
                    type="category"
                    axisLine={false}
                    tickLine={false}
                    width={100}
                />
                <Bar dataKey='value' name={t('uniquePageViews', 'Unike sidevisninger')} barSize={10} fill={palette.primary.main} label={{ position: 'right' }} />
            </BarChart>
        </ResponsiveContainer>
    )
};
