import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import makeStyles from '@mui/styles/makeStyles';

import { Filter } from './Filter';
import { DatePickers } from '../common/DatePickers';
import { useMenu } from './Menu';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    root: {
        height: spacing(7),
        '& ol': {
            height: '100%'
        },
        '& a': {
            cursor: 'pointer'
        },
        [breakpoints.down('xs')]: {
            height: spacing(16)
        },
        '&.editor': {
            paddingLeft: spacing(2)
        }
    },
    datePickers: {
        position: 'relative'
    }
}));

const useParents = () => {
    const findHiddenChild = (children, path) => {
        const p = path.split('/').slice(0, -1);
        return children.find(c => {
            if (c.children) {
                return findHiddenChild(c.children, path);
            }

            if (c && c.url) {
                return c.url.includes('#') ? c.url === `${p.join('/')}/#` : c.url === path;
            }

            return false;
        });
    };

    const findMenuItem = (path, m) => {
        return m.find(x => {
            if (!x) {
                return false;
            }

            let res = false;

            if (x.children) {
                res = x.children.find(c => c.url === path);
                
                if (!res) {
                    res = !!findHiddenChild(x.children, path);
                }
            }

            return res || x.url === path;
        });
    };

    const { activeMuseum } = useSelector(state => state.user),
        location = useLocation(),
        pathname = location.pathname,
        basePath = `/${pathname.split('/')[1]}`,
        menu = useMenu(),
        menuItem = menu && findMenuItem(basePath, menu);
    
    let parents = activeMuseum ? [{ title: activeMuseum.name, url: '/start' }] : [];

    if (menuItem && menuItem.url !== '/start') {
        parents.push(menuItem);

        if (menuItem.url !== pathname && menuItem.children) {
            let child = menuItem.children.find(c => c.url === pathname) || findHiddenChild(menuItem.children, pathname);
            
            while (child && child.children) {
                parents.push(child);
                child = findHiddenChild(child.children, pathname)
            }
         
            child && parents.push(child);
        }
    }

    return parents;
};

export const Breadcrumb = ({ filters, onSelect, onDateChange, visible = true }) => {
    const setStoredCustomDates = detail => {
        if (detail === 'custom' && filters) {
            const dateFilter = filters.find(f => f.type === 'date'),
                custom = dateFilter && dateFilter.filter.find(d => d.value === 'custom');
            
            return [
                custom.start ? new Date(custom.start) : null,
                custom.end ? new Date(custom.end) : null
            ];
        }

        return [null, null]
    };

    const navigate = useNavigate(),
        classes = useStyles(),
        { view, currentDetail, currentInboxFilter } = useSelector(state => state.layout),
        [showDatePicker, setShowDatePicker] = useState(false),
        customDates = setStoredCustomDates(currentDetail),
        parents = useParents(),
        [currentFilter, setCurrentFilter] = useState();

    useEffect(() => {
        const inboxViews = ['all', 'comment', 'inquiry'],
            dateViews = inboxViews.concat(['dashboard', 'statistics', 'downloads']),
            isInbox = inboxViews.includes(view);

        if (isInbox) {
            if (!currentFilter || currentFilter.inboxFilter !== currentInboxFilter) {
                setCurrentFilter({ ...currentFilter, inboxFilter: currentInboxFilter });
            }

            if (!currentFilter || currentFilter.filter !== currentDetail) {
                setCurrentFilter({ ...currentFilter, filter: currentDetail });
            }
        } else {
            if (currentFilter !== currentDetail) {
                setCurrentFilter(currentDetail);
            }

            setShowDatePicker(currentFilter === 'custom');
        }

        if (!dateViews.includes(view)) {
            setShowDatePicker(false);
        }
    }, [view, currentInboxFilter, currentDetail, currentFilter]);

    const filterChange = item => {
        if (item.value) {
            setShowDatePicker(item.value === 'custom');
        }

        onSelect && onSelect(item);
    };

    const onSelectDates = dates => {
        onDateChange && onDateChange(dates);
    };

    const renderFilter = filter => {
        const f = filter instanceof Object ? filter.filter : filter,
            type = filter instanceof Object ? filter.type : null,
            curr = type === 'inbox' ? currentInboxFilter : currentDetail;

        return <Filter key={curr || type} filter={f} current={curr} onSelect={filterChange} />
    };

    if (!visible) {
        return null;
    }

    return (
        <Breadcrumbs aria-label='breadcrumb' className={`${classes.root}${['articles', 'homepage'].includes(view) ? ' editor' : ''}`}>
            {parents.length && (
                parents.slice(0, -1).map((p, index) => (
                    p.url
                        ? (
                            <Link key={index} underline='hover' color='inherit' onClick={() => navigate(p.url)}>
                                {p.title}
                            </Link>
                        ) : (
                            <Typography key={index} color='textPrimary'>{p.title}</Typography>
                        )
                ))
            )}
            {parents.length && (
                <Typography color='textPrimary'>{parents.slice(-1)[0].title}</Typography>
            )}
            {filters && filters.map(renderFilter)}
            {showDatePicker && (
                <div className={classes.datePickers}>
                    <DatePickers onSelect={onSelectDates} dates={customDates} />
                </div>
            )}
        </Breadcrumbs>
    );
};
