export const LAYOUTMODE_CHANGED = 'LAYOUTMODE_CHANGED';
export const REPORT_EXPORT_ENABLE = 'REPORT_EXPORT_ENABLE';
export const CUSTOM_DATES_CHANGE = 'CUSTOM_DATES_CHANGE';
export const DATE_FILTER_CHANGE = 'DATE_FILTER_CHANGE';
export const INBOX_FILTER_CHANGE = 'INBOX_FILTER_CHANGE';

export const setFinderMode = (view = null, detail = null, parent = null) => dispatch => {
    dispatch({
        type: LAYOUTMODE_CHANGED,
        layout: 'finder',
        view: view,
        detail: detail,
        parent: parent
    })
};

export const setExportEnabled = (reportExport = false) => dispatch => {
    dispatch({
        type: REPORT_EXPORT_ENABLE,
        reportExport: reportExport
    })
}

export const setEditorMode = () => dispatch => {
    dispatch({
        type: LAYOUTMODE_CHANGED,
        layout: 'editor'
    })
};

export const setCustomDates = (start, end) => dispatch => {
    dispatch({
        type: CUSTOM_DATES_CHANGE,
        start: start,
        end: end
    })
};

export const setDateFilter = period => dispatch => {
    dispatch({
        type: DATE_FILTER_CHANGE,
        detail: period
    })
};

export const setInboxFilter = filter => dispatch => {
    dispatch({
        type: INBOX_FILTER_CHANGE,
        filter: filter
    })
};
