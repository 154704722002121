import React from 'react';
import { Trans } from 'react-i18next';

import { textBlock } from './textBlock';
import { mediaBlock } from './mediaBlock';
import { dmBlock } from './dmBlock';
import { veBlock } from './veBlock';
import { schoolBlock } from './schoolBlock';
import { departmentsBlock } from '../ownerDepartments/departmentsBlock';

export const bodyBlock = type => {
    const fields = [
        'article__bodytext',
        'article__media',
        'digitaltMuseum',
        'virtualExperience',
        'school'
    ],
        fieldNames = [
            <Trans ns="dm.article" i18nKey="textblock_title">Tekst</Trans>,
            <Trans ns="dm.article" i18nKey="mediablock_title">Media</Trans>,
            <Trans ns="dm.article" i18nKey="dmblock_title">DigitaltMuseum</Trans>,
            <Trans ns="dm.article" i18nKey="veblock_title" key="dm-db-tk">Virtuelle Opplevelser</Trans>,
            <Trans ns="dm.article" i18nKey="schoolBlock_title">Skole</Trans>
        ];

    return {
        type: 'object',
        required: ['type'],
        properties: {
            type: {
                type: 'string',
                readOnly: true,
                enum: fields.concat(type === 'owner' ? ['owner_museums'] : []),
                enumNames: fieldNames.concat(type === 'owner' ? [<Trans ns="dm.article" i18nKey="departmentblock_title">Avdelinger</Trans>] : []),
                default: 'article__bodytext'
            }
        },
        dependencies: {
            type: {
                oneOf: [
                    textBlock,
                    mediaBlock,
                    dmBlock,
                    veBlock,
                    schoolBlock,
                    departmentsBlock
                ]
            }
        }
    }
};
