import {
    OWNER_FEATURES_REQUESTED, OWNER_FEATURES_SUCCEEDED, OWNER_FEATURES_FAILED
} from '../actions/features';

const initialState = {
    owner: null,
    features: null,
    fetching: null,
    error: false
}

const features = (state = initialState, action) => {
    switch (action.type) {
        case OWNER_FEATURES_REQUESTED:
            return {
                ...state,
                features: null,
                fetching: true,
                error: false
            };
        case OWNER_FEATURES_SUCCEEDED:
            return {
                features: action.data.filter(f => f.enabled).map(f => f.name),
                owner: action.owner,
                fetching: false,
                error: false
            };
        case OWNER_FEATURES_FAILED:
            return {
                ...state,
                features: null,
                fetching: false,
                owner: action.owner,
                error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default features;
