import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import { dmsUrl, filterStyle } from '@ekultur/ui-schema-components';

import { blockVisibility } from '../helpers/blockVisibility';
import { locationObjToStr } from '../helpers/locationObjToStr';
import { MuseumSecondaryMedia } from './MuseumSecondaryMedia';

export const MuseumAbout = ({ blocks }) => {
    const { t } = useTranslation(['dm.article'], { useSuspense: false }),
        adrBlock = blocks.find(b => b.type === 'museum_address'),
        phoneBlock = blocks.find(b => b.type === 'museum_phone'),
        urlBlock = blocks.find(b => b.type === 'museum_url'),
        uudBlock = blocks.find(b => b.type === 'uu_declaration'),
        mapBlock = blocks.find(b => b.type === 'museum_coordinates');

    const renderHeaderMedia = b => {
        const { resources } = b || null,
            { mediaList } = resources,
            { coordinates, customCoordinates, override } = mapBlock;

        const renderHeaderImage = m => {
            return (
                <div className='header-media__media'>
                    <div style={{ ...filterStyle(m.media), backgroundImage: `url(${dmsUrl(m, 10)})` }}></div>
                </div>
            );
        };



        return (
            <>
                <div className='header-media'>
                    {(mediaList && !!mediaList.length) && (
                        <>
                            <div className='header-media__primary'>
                                {renderHeaderImage(mediaList[0])}
                            </div>
                            <MuseumSecondaryMedia mediaList={mediaList}
                                                  coordinates={coordinates}
                                                  customCoordinates={customCoordinates}
                                                  show={Boolean(mapBlock)}
                                                  override={override}
                            />
                        </>
                    )}
                </div>
                <div className='header-facets'>
                    <ul>
                        <li>
                            <span>99999</span>
                            <span>objekter</span>
                        </li>
                        <li>
                            <span>9</span>
                            <span>samlinger</span>
                        </li>
                        <li>
                            <span>99</span>
                            <span>utstillinger</span>
                        </li>
                        <li>
                            <span>9</span>
                            <span>artikler</span>
                        </li>
                        <li>
                            <span>99</span>
                            <span>mapper</span>
                        </li>
                    </ul>
                </div>
            </>
        );
    };

    const renderIngress = b => {
        const { bodytext, ingress, override } = b || null;
        return <p id='@section_1.blocks_2'><Markdown children={override ? bodytext : ingress} rehypePlugins={[rehypeRaw, rehypeSanitize]} /></p>;
    };

    const renderDescription = b => {
        const { title, titleHidden, bodytext } = b || null;

        return (
            <div id='@section_1.blocks_3'>
                {title && !titleHidden ? <h2>{title}</h2> : null}
                {bodytext ? <p><Markdown children={bodytext} rehypePlugins={[rehypeRaw, rehypeSanitize]} /></p> : null}
            </div>
        );
    };

    const renderAddress = b => {
        const { address, override, customAddress } = b;

        if (!address) {
            return null;
        }

        let adrStr = '';

        if (override && customAddress && customAddress._locationObject &&
                (customAddress._locationObject.constructor === Object && Object.keys(customAddress._locationObject).length > 0)) {
            adrStr = locationObjToStr(customAddress);
        } else {
            const { street, postalPlace, county, country } = address;
            adrStr = `${street ? street + ', ' : ''}${postalPlace ? postalPlace + ', ' : ''}${county ? county + ', ' : ''}${country ? country + ', ' : ''}`;
        }

        return (
            <li>
                <span className='meta__label'>{t('label_address', 'Adresse')}</span>
                <span>{adrStr}</span>
            </li>
        );
    };

    const renderPhone = b => {
        const { text } = b || null;
        return (
            <li id='@section_1.blocks_5'>
                <span className='meta__label'>{t('label_phone', 'Telefon')}</span>
                <span>{text}</span>
            </li>
        );
    };

    const renderUrl = b => {
        const { url, override, customUrl } = b || null;
        return (
            <li id='@section_1.blocks_6'>
                <span className='meta__label'>{t('label_url', 'Hjemmeside')}</span>
                <a className='meta__link' href={override ? customUrl : url}>{override ? customUrl : url}</a>
            </li>
        );
    };

    const renderUUDUrl = b => {
        const { uu_declaration } = b || null;
        return (
            <li id='@section_1.blocks_7'>
                <span className='meta__label'>{t('label_UUDeclarationUrl', 'Tilgjengelighetserklæring')}</span>
                <a className='meta__link' href={uu_declaration}>{uu_declaration}</a>
            </li>
        );
    };

    return (
        <section id='@section_1.blocks_0' className='museum-body'>
            {renderHeaderMedia(blocks.find(b => b.type === 'museum_images'))}
            {renderIngress(blocks.find(b => b.type === 'museum_ingress'))}
            {renderDescription(blocks.find(b => b.type === 'museum_description'))}
            {((adrBlock && blockVisibility(adrBlock)) || (phoneBlock && blockVisibility(phoneBlock)) || (urlBlock && blockVisibility(urlBlock))) && (
                <div id='@section_1.blocks_4'>
                    <h2>{(adrBlock && adrBlock.header) || 'Besøk oss'}</h2>
                    <ul className='meta__list'>
                        {(adrBlock && blockVisibility(adrBlock)) && (
                            renderAddress(adrBlock)
                        )}
                        {(phoneBlock && blockVisibility(phoneBlock)) && (
                            renderPhone(phoneBlock)
                        )}
                        {(urlBlock && blockVisibility(urlBlock)) && (
                            renderUrl(urlBlock)
                        )}
                        {(uudBlock && blockVisibility(uudBlock)) && (
                            renderUUDUrl(uudBlock)
                        )}
                    </ul>
                </div>
            )}
        </section>
    );
};
