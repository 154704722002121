import { secondsToMinutes } from 'date-fns';

export const secondsToMinutesSeconds = sec => {
    const s = parseInt(sec);

    if (s > 60) {
        const mins = secondsToMinutes(s),
            secs = s - mins * 60;
        return `${mins}m ${secs}s`;
    }

    return `${s} sekunder`;
}
