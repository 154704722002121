import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme, Paper, Typography } from '@mui/material';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { requestStatistics } from '../../redux/actions/plausible/statistics';
import { setTimePeriod } from '../../redux/functions/statistics';
import { getValueFromQueryString } from '../../redux/functions/query';

import { WithProgressOverlay } from '../../common/WithProgressOverlay';

const useStyles = makeStyles(theme => ({
    root: {
        height: 420,
        padding: theme.spacing(2),
        '& text': {
            fontSize: '.9rem'
        }
    }
}));

export const StatisticsReportObjectTypes = ({ viewType }) => {
    const dispatch = useDispatch(),
        { t, ready } = useTranslation('plausibleAnalytics', { useSuspense: false }),
        { palette } = useTheme(),
        classes = useStyles(),
        period = getValueFromQueryString('period'),
        { reports } = useSelector(state => state.statistics),
        objectTypes = reports && reports[viewType].objectTypes,
        { fetching, response, max } = objectTypes,
        dataMax = max.pageviews ? parseInt(max.pageviews) : 0,
        data = response.results.map(row => ({
            name: t(`${row.object_type.toLowerCase()}`),
            pageViews: parseInt(row.pageviews)
        })),
        { ownerUniqueId } = useSelector(state => state.app),
        { filters, currentDetail } = useSelector(state => state.layout),
        timePeriod = setTimePeriod(filters, currentDetail, period, false, true);

    useEffect(() => {
        if (timePeriod.start && timePeriod.end) {
            dispatch(
                requestStatistics(
                    ownerUniqueId, timePeriod.start, timePeriod.end, 'objectTypes', viewType
                )
            );
        }
    }, [dispatch, ownerUniqueId, timePeriod.start, timePeriod.end, viewType]);

    return (
        <WithProgressOverlay loading={fetching || !ready}>
            <Paper className={classes.root}>
                <Typography variant='h6' align='center'>
                    {t('pageViewsByObjectType', 'Sidevisninger per objekttype')}
                </Typography>
                <ResponsiveContainer id='pageViewsByObjectResponsive' width='99%' height={380}>
                    <BarChart layout='vertical'
                        data={data}
                        margin={{ top: 10, left: 30 }}
                    >
                        <CartesianGrid stroke='#f5f5f5' />
                        <XAxis type='number' domain={[0, dataMax]} />
                        <YAxis dataKey='name' type='category' reversed={true} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey='pageViews' name={t('uniquePageViews', 'Unike sidevisninger')} barSize={20} fill={palette.primary.main} />
                    </BarChart>
                </ResponsiveContainer>
            </Paper>
        </WithProgressOverlay>
    );
};
