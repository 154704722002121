import { Trans } from 'react-i18next';

import { uniqueKey } from '../body/uniqueKey';
import { imageWithSettings } from '../media/imageWithSettings';

export const museumImagesBlock = {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="label_images">Bilder</Trans>,
    properties: {
        type: { enum: ['museum_images'] },
        title: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>
        },
        resources: {
            type: 'object',
            properties: {
                mediaList: {
                    type: 'array',
                    default: [],
                    maxItems: 4,
                    items: imageWithSettings
                }
            }
        },
        // settings: { '$ref': '#/definitions/settings' },
        _unique_key: uniqueKey,
        _editor: { '$ref': '#/definitions/_editor' }
    }
};
