import React, { useEffect, useReducer, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import { License } from './License';
import { dmsUrl, filterStyle } from '@ekultur/ui-schema-components';


const HEADER_IMAGE_MAX_WIDTH = 960,
    HEADER_IMAGE_HEIGHT = 500;

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
    articleHead: {
        '.anchorMode &': {
            cursor: 'not-allowed',
            opacity: .5
        },
        '&:not(.owner)': {
            paddingTop: '1.5em'
        },
        '& .article__title': {
            lineHeight: '1.25em',
            fontWeight: 'bold',
            fontSize: '1.5em',
            '& h1': {
                marginBottom: 0
            }
        },
        '& .article__byline': {
            marginTop: '.5em',
            fontSize: '.85em',
            verticalAlign: 'baseline',
            '& p': {
                margin: 0
            }
        },
        '& .article__cover': {
            position: 'relative',
            width: '100%',
            height: '320px',
            overflow: 'hidden',
            backgroundColor: '#423f3c',
            color: '#fff',
            [breakpoints.up('sm')]: {
                height: `${HEADER_IMAGE_HEIGHT}px`
            },
            '&.skin-light': {
                backgroundColor: '#f2f2f2',
                color: '#000'
            },
            '& .mediaWrapper': {
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                '& figure.media--cover': {
                    height: '100%',
                    maxWidth: `${HEADER_IMAGE_MAX_WIDTH}px`,
                    margin: '0 auto',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                },
                '& img': {
                    margin: 'auto',
                    maxHeight: '90%'
                }
            },
            '& .article__content': {
                position: 'relative',
                height: '100%',
                '& .titleWrapper': {
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    left: 0,
                    bottom: 'auto',
                    margin: '0 auto',
                    transform: 'translateY(-50%)',
                    zIndex: 1
                },
                '& .article__title': {
                    '& h1': {
                        margin: 0
                    }
                },
                '& .media__credit': {
                    position: 'absolute',
                    bottom: 0,
                    padding: '.75em',
                    opacity: .5,
                    zIndex: 1
                },
            },
        },
        '&.article .mediaWrapper': {
            display: 'flex'
        },
        '&.owner .article__cover': {
            height: '760px',
            '& .mediaWrapper figure.media--cover': {
                maxWidth: '1680px'
            }
        },
        '& .article__content': {
            '& .article__leadtext': {
                paddingTop: '1.5em'
            }
        },
        '& .owner__intro': {
            width: '80%'
        },
        '& .media_credit': {
            maxWidth: `${HEADER_IMAGE_MAX_WIDTH}px`,
            margin: '0 auto',
            paddingTop: '.3em',
            '& .media__caption': {
                fontWeight: 'normal'
            }
        }
    }
}));


export const ArticleHeader = ({ block, pageType, children }) => {
    const updateImgTopPos = (state, upd) => {
        return upd;
    };

    const classes = useStyles(),
        [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 }),
        [imgTopPos, setImgTopPos] = useReducer(updateImgTopPos, 0),
        { resources } = block,
        { mediaList, backgroundImage } = resources,
        bgImg = mediaList || backgroundImage,
        [fp, setFp] = useState(bgImg && bgImg.length && bgImg[0].media.focuspoint);

    useEffect(() => {
        if (bgImg && bgImg.length && imgDimensions.width && imgDimensions.height) {
            if (bgImg[0].media && bgImg[0].media.focuspoint) {
                const containerCenter = Math.floor(HEADER_IMAGE_HEIGHT / 2),
                    scale = HEADER_IMAGE_MAX_WIDTH / imgDimensions.width,
                    height = scale * imgDimensions.height,
                    focusFactor = (parseFloat(bgImg[0].media.focuspoint.y) + 1) / 2; //Focus point of resize image in px;

                let focusOffset = Math.floor((focusFactor - 1) * height) + containerCenter; //Calculate difference between focus point and center

                if (focusOffset > 0) {
                    focusOffset = 0;
                } else if (focusOffset < HEADER_IMAGE_HEIGHT - imgDimensions.height * scale) {
                    focusOffset = HEADER_IMAGE_HEIGHT - imgDimensions.height * scale;
                }

                setImgTopPos(focusOffset + 'px');
            }
        }
    }, [imgDimensions.width, imgDimensions.height, bgImg, fp]);

    const onLoadedData = e => {
        if (e.target.width !== imgDimensions.width || e.target.height !== imgDimensions.height) {
            setImgDimensions({ width: e.target.width, height: e.target.height });
        }
    };

    if (bgImg && bgImg.length && fp && bgImg[0].media.focuspoint && bgImg[0].media.focuspoint.y !== fp.y) {
        setFp(bgImg[0].media.focuspoint);
    }

    const renderTitleAndCredit = () => {
        if (!block.title && !block.author) {
            return null;
        }

        return (
            <div className="article__content">
                <div className="titleWrapper">
                    {block.title &&
                        <div className="article__title">
                            <h1>{block.title}</h1>
                        </div>
                    }
                    {block.author &&
                        <div className="article__byline">
                            <p>Av {block.author}</p>
                        </div>
                    }
                </div>
            </div>
        );
    };

    return (
        <header id="@section_0.block_0" className={`${classes.articleHead} ${pageType}`}>
            <section className={`article__cover ${block.settings.color}`} style={bgImg.length && bgImg[0].bgColor ? { background: bgImg[0].bgColor } : {}}>
                {!!children && children}
                {
                    !!bgImg.length && (
                        <div className="mediaWrapper">
                            {pageType === 'article'
                                ?
                                <img src={`${dmsUrl(bgImg[0], 10)}?dimension=800x800`} alt={bgImg[0].caption || 'main article image'}
                                    style={{
                                        ...filterStyle(bgImg[0].media),
                                        backgroundPositionY: `${imgTopPos}`
                                    }}
                                />
                                : <figure className="article__media media--cover media--image focuspoint"
                                    id="article__cover-media"
                                    style={{
                                        ...filterStyle(bgImg[0].media),
                                        backgroundImage: `url(${dmsUrl(bgImg[0], 10)}?dimension=1200x1200${bgImg[0].mimetype && bgImg[0].mimetype.indexOf('png') > -1 ? '&mediaType=image/png' : ''})`,
                                        backgroundPositionY: `${imgTopPos}`
                                    }}
                                >
                                    <img src={`${dmsUrl(bgImg[0], 10)}?dimension=1200x1200${bgImg[0].mimetype && bgImg[0].mimetype.indexOf('png') > -1 ? '&mediaType=image/png' : ''}`} alt={bgImg[0].local && bgImg[0].local.description}
                                        style={{ display: 'none' }}
                                        onLoad={onLoadedData}
                                    />
                                </figure>
                            }
                        </div>
                    )
                }
            </section>
            {mediaList && mediaList[0] && (
                <div className="media_credit">
                    {mediaList[0].local && mediaList[0].local.description !== '' && <span className="media__caption">{mediaList[0].local.description}</span>}
                    {mediaList[0].local && mediaList[0].local.credit && <span className="media__credit">{mediaList[0].local.credit}</span>}
                    {mediaList[0].licenses && mediaList[0].licenses[0] && <License code={mediaList[0].licenses[0]} />}
                </div>
            )}
            {pageType === 'article' && (
                <>
                    {renderTitleAndCredit()}
                    <section className="article__content">
                        <div className="article__leadtext">
                            <strong><Markdown children={block.leadtext} rehypePlugins={[rehypeRaw, rehypeSanitize]} /></strong>
                        </div>
                    </section>
                </>
            )}
        </header>
    );
};
