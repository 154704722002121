import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';


export const ConfirmDialog = ({ title, confirmLabel, cancelLabel, children, open, setOpen, onConfirm, onCancel = null }) => {
    const cancel = e => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(false);
        onCancel && onCancel();
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {cancelLabel && (
                    <Button
                        variant="contained"
                        onClick={cancel}
                        color="secondary"
                    >
                        {cancelLabel}
                    </Button>
                )}
                {confirmLabel && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpen(false);
                            onConfirm();
                        }}>
                        {confirmLabel}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
