import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { ProgressIndicator } from '../../common/ProgressIndicator';

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginBottom: 20,
        overflowX: 'auto',
        padding: 10,
        boxSizing: 'border-box'
    },
});

export const PublishedObjectsTable = ({ fetching, rows, t }) => {
    const classes = useStyles();

    if (fetching) {
        return <ProgressIndicator />;
    }

    const translatedRows = rows.map(row => ({
        ...row,
        name: t(`dm.objectTypes:${row.name.toLowerCase()}`),
    }));

    return (
        <Paper className={classes.root} elevation={3}>
            <Typography variant='h6' align='center'>
                {t('splitByObjectType', 'Fordelt på objekttype')}
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('objectType', 'Objekttype')}</TableCell>
                        <TableCell align='right'>
                            {t('registeredInPrimus', 'Registrert i primus')}
                        </TableCell>
                        <TableCell align='right'>{t('published', 'Publiserte')}</TableCell>
                        <TableCell align='right'>
                            {t('notPublished', 'Ikke publiserte')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {translatedRows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component='th' scope='row'>
                                {row.name}
                            </TableCell>
                            <TableCell align='right'>{row.primus}</TableCell>
                            <TableCell align='right'>{row.published}</TableCell>
                            <TableCell align='right'>{row.notPublished}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};
