export const locationObjToStr = location => {
    if (!location || !location._locationObject) {
        return '';
    }

    const locObj = location._locationObject,
        roadAdr = `${locObj.road || ''} ${locObj.house_number || ''}`.trim(),
        municipality = `${locObj.village || locObj.town || locObj.city || locObj.municipality || ''}`;

    return `${roadAdr ? roadAdr + ', ' : ''}` +
        `${locObj.postcode ? locObj.postcode + ' ' : ''}` +
        `${municipality ? municipality + ', ' : ''}` +
        `${ locObj.country || '' }`;
};
