import React from 'react';
import { Trans } from 'react-i18next';

export const folderExportSchema = {
    type: 'object',
    title: null,
    properties: {
        // folderId: {
        //     type: 'string',
        //     title: 'Folder id',
        //     readOnly: true
        // },
        // ownerId: {
        //     type: 'string',
        //     title: 'Museum id',
        //     readOnly: true
        // },
        institution: {
            type: 'string',
            title: <Trans ns="dm.wikimedia" i18nKey="wm_institution_name">Institusjonens navn på Wikimedia</Trans>,
        }
    }
};
