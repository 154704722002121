import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

import { filterStyle } from '@ekultur/ui-schema-components';

import { requestDmSearch } from '../../../redux/actions/media';
import { ProgressIndicator } from '../../../common/ProgressIndicator';

import { buildQuery } from '../../helpers/buildQuery';


const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
    grid: {
        maxWidth: '704px',
        margin: '1.5em auto',
        display: 'flex',
        flexWrap: 'wrap',
        '&.grid-wide': {
            maxWidth: '1064px',
            flexFlow: 'column wrap',
            alignContent: 'center'
        }
    },
    dm: {
        marginLeft: spacing(-1),
        padding: spacing(1),
        fontSize: '14px',
        '& a': {
            color: '#3a2e2d',
            backgroundColor: '#f2f2f2',
            textDecoration: 'none'
        },
        '& .row': {
            position: 'relative',
            boxShadow: '0 1.5px 2px rgba(0, 0, 0, 0.06), 0 1.5px 1.5px rgba(0, 0, 0, 0.06)',
            '&.small': {
                width: '164px',
                height: '246px',
            },
            '&.medium': {
                width: '336px',
                height: '246px',
            },
            '&.large': {
                width: '688px',
                height: '492px',
            },
            '& figure': {
                height: '66.66%',
                display: 'flex',
                '& img': {
                    margin: 'auto',
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '90%',
                    maxHeight: '90%'
                }
            },
            '& .media__caption': {
                maxHeight: '3.9em',
                display: 'block',
                overflow: 'hidden',
                padding: '0 0.625em 1em'
            },
            '& .media__credit': {
                position: 'absolute',
                left: spacing(1),
                bottom: spacing(1),
                fontSize: '12px'
            }
        }
    },
    showMore: {
        marginBottom: '2em',
        textAlign: 'center',
        '& > a': {
            display: 'inline-block',
            border: '2px solid black',
            borderRadius: '2px',
            fontSize: '18px',
            fontWeight: 'bold',
            lineHeight: 1,
            padding: '.75em 1.5em',
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none',
                color: 'white',
                backgroundColor: 'black'
            }
        }
    }
}));

let timers = {}, numListItems;

export const DmSchool = ({ id, block, museum }) => {
    const dispatch = useDispatch(),
        classNamees = useStyles(),
        [showMax, setShowMax] = useState(false),
        dmSearch = useSelector(state => state.previewDmSearch),
        { resources, maxListItems,  labelHidden, settings } = block,
        layout = (settings && settings.layout) || '';

    useEffect(() => {
        setShowMax(false);
    }, [labelHidden]);

    const renderCard = (item, idx) => {
        const size = (item.settings && item.settings.size) || '',
            desc = item.title,
            count = item.image_count;
        
        const cardImageStyle = () => {
            let style = {};

            if (item.media) {
                style = { ...filterStyle(item.media) };
            }

            style.backgroundImage = `url(${item.url}?dimension=400x400)`;
            return style;
        };
        
        if (block.type === 'trending_search') {
            return (
                <a key={`${item.image_id}-${idx}`} className='museum-card museum-card--small--wide' href={`https://digitaltmuseum.org/${item.dimuId}`}>
                    <div className='museum-card__upper'>
                        {item.url && <div className='museum-card__image' style={cardImageStyle()}></div>}
                    </div>
                    <div className='museum-card__lower'>
                        <div>
                            <h2 className='museum-card__title'>{item.title}</h2>
                            <p className='museum-card__byline'>Av {item.local && item.local.credit}</p>
                        </div>
                        <div className='museum-card__footer'>
                            {museum && <div className='museum-avatar museum-avatar--small'><img src={`https://${museum.profile}`} alt={museum.name} title={museum.name} /></div>}
                            <span className='museum-card__metadata'>{museum.name}</span>
                        </div>
                    </div>
                </a>
            );
        } else {
            return (
                <div key={`${item.image_id}-${idx}`} className={`${classNamees.dm} module--dm-object`}>
                    <a href={`https://digitaltmuseum.org/${item.dimuId}`} target='_blank' rel='noreferrer noopener'>
                        <div className={`row ${size}`}>
                            <figure>
                                {item.url && <img src={`${item.url}?dimension=400x400`} alt={desc} style={item.media && { ...filterStyle(item.media) }} />}
                            </figure>
                            <div className='content'>
                                {(desc && desc !== '') && <span className='media__caption'>{desc}</span>}
                                {typeof count !== 'undefined' && <span className='media__credit'>{count} bilder</span>}
                            </div>
                        </div>
                    </a>
                </div>
            );
        }
    };

    const renderLoadingCard = (size, idx) => {
        return (
            <div key={idx} className={classNamees.dm}>
                <div className={`row ${size}`}>
                    <ProgressIndicator />
                </div>
            </div>
        );
    };

    const setRows = r => {
        try {
            return Math.max(parseInt(r), 0);
        } catch (err) {
            return 0;
        }
    };

    const renderSearch = entry => {
        const { filter, query, settings: searchSettings  } = entry,
            size = (searchSettings && searchSettings.size) || 'small',
            f = { ...filter, rows: setRows((showMax || typeof maxListItems === typeof undefined) ? (filter && filter.rows) : maxListItems) || 4 };

        if (!f || !f.rows) {
            return null;
        }

        const q = buildQuery(query, f);

        if (!dmSearch || !dmSearch.hasOwnProperty(q)) {
            if (timers[q]) {
                clearTimeout(timers[q]);
            }

            timers[q] = setTimeout(() => {
                console.log('ExecuteSearch: ', q);
                dispatch(requestDmSearch(q, true, true));
            }, 2500);
        }

        if (!dmSearch.hasOwnProperty(q) || !dmSearch[q].data) {
            numListItems += f.rows;

            if (numListItems > maxListItems && !showMax) {
                f.rows = (f.rows - (numListItems - maxListItems));
            }

            const cards = Array(f.rows).fill(size);

            return cards.map((s, idx) => renderLoadingCard(s, idx));
        }

        const objects = dmSearch[q].data.objects.map(o => ({ ...o, settings: { size: size } }));
        numListItems += objects.length;

        if (numListItems > maxListItems && !showMax) {
            return objects.slice(0, (objects.length - (numListItems - maxListItems))).map((s, idx) => renderCard(s, idx));
        }

        return objects.map((s, idx) => renderCard(s, idx));
    };

    const renderEntry = entry => {
        if (numListItems >= maxListItems) {
            return null;
        }

        if (entry.type === 'search') {
            return renderSearch(entry);
        }

        numListItems++;
        return renderCard(entry);
    };

    if (resources.schoolEntryList && resources.schoolEntryList.length) {
        numListItems = 0;
        let gridH = 0;
        
        if (layout === 'grid-wide') {
            let gridSize = 0;
            resources.schoolEntryList.forEach(r => {
                let count = 1;

                if (r.type === 'search') {
                    count = maxListItems || r.filter.rows;
                }

                if (r.settings && r.settings.size && r.settings.size !== 'small') {
                    gridSize += (r.settings.size === 'medium' ? 2 : 4) * count;
                } else {
                    gridSize += 1 * count;
                }
            });
            
            gridH = 262 * Math.ceil(gridSize / 6);
            console.log('gridH: ', gridH);
        }

        if (block.type === 'trending_search') {
            return (
                <div className='museum-cards'>
                    {resources.schoolEntryList.map(renderEntry)}
                </div>
            )
        } else {
            return (
                <>
                    <div key={id} className={`${classNamees.grid} ${layout}`} style={{ height: `${gridH ? gridH + 'px' : 'auto'}` }}>
                        {resources.schoolEntryList.map(renderEntry)}
                    </div>
                   
                </>
            );
        }
    }

    return null;
};
