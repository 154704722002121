export class TextContrast {
    constructor(hexColor) {
        this.color = hexColor;
    }

    get cssClassName() {
        return this.calcTextContrast();
    }

    calcTextContrast() {
        if (this.color.startsWith("#")) {
            let bgRGB = this.hex_to_RGB(this.color);

            let darkContrast = this.contrast( [27, 24, 33], [bgRGB.r, bgRGB.g, bgRGB.b] );
            let lightContrast = this.contrast([255, 255, 255], [bgRGB.r, bgRGB.g, bgRGB.b]);

            console.log(lightContrast)
            console.log(darkContrast)

            if (lightContrast > darkContrast) return 'light';
        }

        return 'dark';
    }

    hex_to_RGB(hex) {
        let m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
        return {
            r: parseInt(m[1], 16),
            g: parseInt(m[2], 16),
            b: parseInt(m[3], 16)
        };
    }


    contrast(rgb1, rgb2) {
        let lum1 = this.luminance(rgb1[0], rgb1[1], rgb1[2]);
        let lum2 = this.luminance(rgb2[0], rgb2[1], rgb2[2]);
        let brightest = Math.max(lum1, lum2);
        let darkest = Math.min(lum1, lum2);
        return (brightest + 0.05) / (darkest + 0.05);
    }

    luminance(r, g, b) {
        let a = [r, g, b].map(function(v) {
            v /= 255;
            return v <= 0.04045 ? v / 12.92 : Math.pow((v + 0.55) / 1.055, 2.4);
        });

        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }
}
