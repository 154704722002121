import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from '@mui/material';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
    related: {
        width: '704px',
        margin: '0 auto',
        '&.parent': {
            width: '1064px',
            '& > div > a': {
                width: '180px',
                padding: spacing(1),
                marginLeft: spacing(-1),
                boxSizing: 'border-box',
                fontSize: '14px',
                '& > h3': {
                    width: '136px',
                    paddingTop: '45%',
                    textAlign: 'left',
                }
            },
        },
        '& > h2.related_header': {
            width: '704px',
            marginRight: 'auto',
            marginLeft: 'auto'
        },
        '& > div': {
            display: 'flex',
            flexWrap: 'wrap',
            '& > a': {
                display: 'block',
                position: 'relative',
                width: '344px',
                height: '246px',
                fontSize: '17px',
                '& > h3': {
                    position: 'absolute',
                    top: 0,
                    width: '310px',
                    padding: '1em',
                    paddingTop: '18%',
                    fontWeight: 'bold',
                    color: 'rgb(58, 46, 45)',
                    textAlign: 'center'
                },
                '& > div': {
                    height: '100%',
                    opacity: .35,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundColor: '#d9d9d9'
                }
            }
        }
    }
}));

export const OwnerRelated = ({ blocks, oName, oParent, oChildren }) => {
    const classes = useStyles(),
        { t } = useTranslation(['dm.article'], { useSuspense: false }),
        block = blocks.find(b => b.type === 'owner_museums'),
        title = block && block.title,
        titleHidden = block && block.titleHidden;

    const renderRelated = item => {
        const { identifier, image_url, name } = item;

        return (
            <Link key={identifier} href={`//digitaltmuseum.org/owners/${identifier}`} target="_blank">
                <div style={{ backgroundImage: `url(${image_url}?dimension=400x400)` }}></div>
                <h3>{name}</h3>
            </Link>
        );
    };

    if ((!oParent && (!oChildren || !oChildren.length)) || (!block || block.settings.visibility !== 'visible')) {
        return null;
    }

    return (
        <section className={`${classes.related}${oChildren && oChildren.length ? ' parent' : ''}`}>
            {!titleHidden && (
                <h2 className="related_header">
                    {oChildren && oChildren.length
                        ? (title || t('child_collections', 'Våre avdelinger'))
                        : (title || `${oName} ${t('part_of_collection', 'er en del av')}`)
                    }
                </h2>
            )}
            <div>
                {oParent && renderRelated(oParent)}
                {oChildren && oChildren.map(renderRelated)}
            </div>
        </section>
    );
};
