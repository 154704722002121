import React from 'react';
import { Trans } from 'react-i18next';

import image from '../../Article/schema/media/image';

export const imageFileSchema = {
    type: 'object',
    title: <Trans ns='dm.media' i18nKey='edit_dialog_title'>Bilde/fil-tekster, lisens og stikkord</Trans>,
    properties: {
        title: {
            type: 'string',
            default: '',
            title: <Trans ns='dm.media' i18nKey='edit_title'>Tittel</Trans>
        },
        description: {
            type: 'string',
            default: '',
            title: <Trans ns='dm.article' i18nKey='image_text'>Bildetekst</Trans>
        },
        photographer: {
            type: 'string',
            default: '',
            title: <Trans ns='dm.article' i18nKey='image_credit'>Fotograf</Trans>
        },
        licenses: { ...image.properties.licenses },
        tags: {
            type: 'array',
            default: [],
            title: <Trans ns='dm.media' i18nKey='edit_tags'>Stikkord</Trans>,
            maxItems: 10,
            items: {
                type: 'string',
                default: ''
            }
        }
    }
};
