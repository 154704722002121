import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as querystringify from 'querystringify';

import { OWNER_SELECTED_BY_ID } from '../redux/actions/app';

export const WithMuseumSelector = ({ children }) => {
    const dispatch = useDispatch(),
        navigate = useNavigate(),
        location = useLocation(),
        { ownerUniqueId } = useSelector(state => state.app),
        parsed = querystringify.parse(location.search),
        currentOwner = sessionStorage.getItem('museum');


    useEffect(() => {
        if (location.pathname === '/' && parsed.museum_id && parsed.museum_id === currentOwner) {
            navigate('/start');
        } else if (!['/', '/login', '/logout', '/oauth2/callback'].includes(location.pathname) && !parsed.museum_id && !currentOwner) {
            navigate('/');
        }
    }, [navigate, location.pathname, parsed.museum_id, currentOwner]);
        
    useEffect(() => {
        let newOwner = undefined;

        if ((parsed.museum_id && currentOwner && parsed.museum_id !== currentOwner) || (parsed.museum_id && !currentOwner)) {
            newOwner = parseInt(parsed.museum_id);
        }

        if (newOwner) {
            sessionStorage.setItem('museum', parsed.museum_id);
        } else if (currentOwner) {
            newOwner = parseInt(currentOwner);
        }

        if (newOwner && (!ownerUniqueId || ownerUniqueId !== newOwner)) {
            dispatch({ type: OWNER_SELECTED_BY_ID, mid: newOwner });
        }
    }, [dispatch, parsed.museum_id, currentOwner, ownerUniqueId]);

    return <>{ children }</>;
};
