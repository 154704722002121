import React from 'react';
import { Trans } from 'react-i18next';

import { headerBlock } from '../header/headerBlock';

export const headerSection = {
    type: 'object',
    title: <Trans ns="dm.article" i18nKey="section_header">Header</Trans>,
    properties: {
        type: { enum: ['article__head'] },
        _editor: {
            '$ref': '#/definitions/_editor'
        },
        blocks: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
            // title: 'Tittel og ingress',
            title: null,
            items: headerBlock
        }
    }
};
