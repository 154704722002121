import { dmObjMapper } from '../../Article/mediaLib/tpl/dmObj';
import {
    RESOURCES_REQUESTED, RESOURCES_SUCCEEDED, RESOURCES_FAILED, RESOURCE_DELETED,
    SEARCH_REQUESTED, SEARCH_SUCCEEDED, SEARCH_FAILED,
    PREVIEW_SEARCH_REQUESTED, PREVIEW_SEARCH_SUCCEEDED, PREVIEW_SEARCH_FAILED,
    FILE_UPLOAD_SUCCEEDED, FILE_UPLOAD_STATUS, RESOURCE_DATA_UPDATED, RESET_MEDIA_SEARCH, RESET_MEDIA_SEARCH_FLAG
} from '../actions/media';

const initialState = {
    data: null,
    countFound: 0,
    countReturned: 0,
    fetching: null,
    error: false,
    resetSinceLastSuccess: false
};

const initialPreviewState = {};

export const resources = (state = initialState, action) => {
    switch (action.type) {
        case RESOURCES_REQUESTED:
            return {
                ...initialState,
                fetching: true
            };
        case RESOURCES_SUCCEEDED:
            return {
                data: action.data,
                fetching: false,
                error: false,
                countFound: action.data.total_count,
                countReturned: action.data.resources.length
            };
        case FILE_UPLOAD_SUCCEEDED:
            const res = (state.data?.resources) || [];

            return {
                data: {
                    resources: [action.data].concat(res)
                },
                fetching: false,
                error: false
            };
        case FILE_UPLOAD_STATUS:
            return {
                ...state,
                status: action.data
            };
        case RESOURCE_DATA_UPDATED:
            return {
                ...state,
                data: {
                    resources: state.data.resources.map(r => r.id === action.data.id ? action.data : r)
                }
            };
        case RESOURCE_DELETED:
            const a = state.data.resources.filter(x => x.id !== action.data);
            return {
                ...state,
                data: { resources: a },
                countFound: state.countFound - 1,
                countReturned: a.length
            };
        case RESOURCES_FAILED:
        default:
            return {
                ...state
            };
    }
};

export const dmSearch = (state = initialState, action) => {
    const objects = (state.data?.objects) || [];

    switch (action.type) {
        case SEARCH_REQUESTED:
            return {
                ...state,
                fetching: true,
                error: false
            };
        case SEARCH_SUCCEEDED:
            if (state.resetSinceLastSuccess) {
                return initialState;
            }
            return {
                data: {
                    ...action.data,
                    objects: setDmSearchResult(action.resetResult, action.data.objects, objects)
                },
                fetching: false,
                error: false
            };
        case SEARCH_FAILED:
            return {
                ...state,
                error: true
            };
        case RESET_MEDIA_SEARCH_FLAG:
            return {
                ...state,
                resetSinceLastSuccess: false
            };
        case RESET_MEDIA_SEARCH:
            if (state.data) {
                return {
                    ...initialState,
                    resetSinceLastSuccess: true,
                };
            } else {
                return {
                    initialState,
                };
            }
        default:
            return {
                ...state
            }
    }
};

export const previewDmSearch = (state = initialPreviewState, action) => {
    switch (action.type) {
        case PREVIEW_SEARCH_REQUESTED:
            return {
                ...state,
                [action.query]: {
                    ...initialState,
                    fetching: true
                }
            };
        case PREVIEW_SEARCH_SUCCEEDED:
            return {
                ...state,
                [action.query]: {
                    data: {
                        ...action.data,
                        objects: action.data.objects.map(x => dmObjMapper(x))
                    },
                    fetching: false,
                    error: false
                }
            };
        case PREVIEW_SEARCH_FAILED:
            return {
                ...state,
                [action.query]: {
                    error: true
                }
            };
        default:
            return {
                ...state
            }
    }
};

const setDmSearchResult = (reset, newRes, res = null) => {
    if (reset) {
        return newRes;
    } else {
        return res.concat(newRes);
    }
};
