import { headerSection } from '../header/headerSection';
import { bodySection } from '../body/bodySection';

export const articleSections = {
    type: 'object',
    required: ['type'],
    properties: {
        type: {
            type: 'string',
            readOnly: true,
            title: null,
            enum: [
                'article__head',
                'article__body'
            ],
            enumNames: [
                'Head',
                'Body'
            ],
            default: 'article__body'
        }
    },
    dependencies: {
        type: {
            oneOf: [
                headerSection,
                bodySection('article')
            ]
        }
    }
};
