import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AppsIcon from '@mui/icons-material/Apps';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import StatsIcon from '@mui/icons-material/ShowChart';
import MailIcon from '@mui/icons-material/MailOutline';
import ArticleIcon from '@mui/icons-material/Subject'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PermMedia from '@mui/icons-material/PermMedia';

export const useMenu = () => {
    const { t } = useTranslation(['dm.menu'], { useSuspense: false }),
        { museumData, activeMuseum } = useSelector(state => state.user),
        // { features } = useSelector(state => state.features),
        userAccess = useSelector(state => state.userAccess);

    return [
        museumData.length > 1 && {
            title: 'DigitaltMuseum',
            icon: <AppsIcon />,
            url: '/'
        },
        {
            title: (activeMuseum && activeMuseum.name) || 'Home',
            icon: <HomeIcon />,
            url: '/start'
        },
        userAccess.inbox && {
            title: t('inbox', 'Innboks'),
            icon: <MailIcon />,
            children: [
                {
                    title: t('inbox_all', 'Alle'),
                    url: '/inbox/all'
                },
                {
                    title: t('inbox_comments', 'Kommentarer'),
                    url: '/inbox/comment'
                },
                {
                    title: t('inbox_inquiries', 'Tilbakemeldinger'),
                    url: '/inbox/inquiry'
                },
                {
                    title: t('edit_mashup', 'Rediger henvendelser'),
                    url: '/inbox/details/#',
                    hidden: true
                }
            ]
        },
        userAccess.statistics && {
            title: t('statistics', 'Statistikk'),
            icon: <StatsIcon />,
            children: [
                {
                    title: t('statistics_pages', 'Sidevisninger'),
                    url: '/statistics',
                },
                {
                    title: t('statistics_objectTypes', 'Objekttyper'),
                    url: '/statistics/objectTypes',
                    children: [{
                        title: '...',
                        url: '/statistics/objectTypes/#',
                        hidden: true
                    }]
                },
                {
                    title: t('statistics_downloads', 'Nedlastinger'),
                    url: '/statistics/downloads'
                },
                {
                    title: t('dm.graph:PrimusVsDm_title', 'Andel objekter i Primus publisert på DigitaltMuseum'),
                    url: '/statistics/published'
                }
            ]
        },
        userAccess.article && {
            title: t('content', 'Innhold'),
            icon: <ArticleIcon />,
            children: [
                {
                    title: t('articles', 'Artikler'),
                    url: '/article',
                    children: [{
                        title: 'Editor',
                        url: '/article/edit/#',
                        hidden: true
                    }]
                }, {
                    title: t('dm.article:homepage', 'Hjemmeside'),
                    url: '/homepage',
                    children: [{
                        title: 'Editor',
                        url: '/homepage/edit/#',
                        hidden: true
                    }]
                },
                // (!!features && features.includes('MuseumPage')) &&
                {
                    title: t('dm.article:museumpage', 'Museumside'),
                    url: '/museumpage',
                    children: [{
                        title: 'Editor',
                        url: '/museumpage/edit/#',
                        hidden: true
                    }]
                }
            ]
        },
        userAccess.folders && {
            title: t('folders', 'Mapper'),
            icon: <FolderIcon />,
            url: '/folders',
            children: [{
                title: t('dm.menu:folderObjects', 'Mappeobjekter'),
                url: '/folders/#',
                hidden: true
            }]
        },
        {
            title: t('gallery', 'Mediegalleri'),
            url: '/gallery',
            icon: <PermMedia />
        }
    ];
};

export const Menu = ({container, mobileOpen, closeHandler, visible=true, forceMobile=false}) => {
    const navigate = useNavigate(),
        classes = useStyles(),
        theme = useTheme(),
        location = useLocation(),
        { pathname } = location,
        [initiated, setInitiated] = useState(false),
        [open, setOpen] = useState(-1),
        menu = useMenu();

    const handleClick = (idx) => {
        setOpen(idx !== open ? idx : -1);
    };

    if(!visible) {
        return null
    }

    const menuListWithChildren = item => {
        const itemIdx = menu.findIndex(m => m === item),
            expanded = open === itemIdx;

        if (!initiated && open !== itemIdx && item.children.findIndex(c => pathname.startsWith(c.url)) >= 0) {
            setOpen(itemIdx);
        }
        
        if (!initiated) {
            setInitiated(true);
        }
        
        return (
            <Fragment key={`frag-${itemIdx}`}>
                <ListItem
                    button
                    key={`${item.title}-${itemIdx}`}
                    onClick={() => handleClick(itemIdx)}
                >
                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                    <ListItemText primary={item.title} />
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding className={classes.childList}>
                        {item.children.map((child, idx) => menuList(child, idx))}
                    </List>
                </Collapse>
            </Fragment>
        );
    };

    const menuList = (item, idx) => {
        const showChildren = !item.children || (item.children && item.children.find(c => !c.hidden));
        let selected = false;

        if (!item || item.hidden) {
            return null;
        }

        if (showChildren) {
            selected = pathname === item.url;
        } else {
            const p = pathname.split('/').slice(1),
                u = item.url && item.url.split('/').slice(1);

            if (u.length !== p.length) {
                selected =  p.slice(0, -1).join('/') === u.join('/');
            } else {
                selected = pathname === item.url;
            }
        }

        return (
            item.children && showChildren
                ? menuListWithChildren(item)
                : (
                    <ListItem
                        selected={selected}
                        button
                        key={`${item.title}-${idx}`}
                        onClick={() => {
                            navigate(item.url);
                            closeHandler();
                        }}
                    >
                        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                        <ListItemText primary={item.title} />
                    </ListItem>
                )
        );
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <List component='nav' disablePadding>
                {menu.map((item, idx) => item && menuList(item, idx))}
            </List>
        </div>
    );

    return (
        <nav className={classes.drawer} aria-label='admin menu'>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden mdUp implementation='css'>
                <Drawer
                    container={container}
                    variant='temporary'
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={closeHandler}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden mdDown={!forceMobile} xsUp={forceMobile} implementation='css'>
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant='permanent'
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    );
};

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    drawer: {
        zIndex: 99,
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        zIndex: 1000
    },
    childList: {
        '& > div': {
            paddingLeft: theme.spacing(9)
        }
    }
}));