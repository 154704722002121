import { Trans } from 'react-i18next';

import { museumAboutSection } from './aboutSection';
import { museumHeaderSection } from './headerSection';
import { museumTrendingSection } from './trendingSection';

export const museumSchema = {
    page_type: {
        type: 'string',
        default: 'museum',
        readOnly: true
    },
    tags: {
        type: 'array',
        title: <Trans ns="dm.article" i18nKey="topics_title">Nøkkelord (blir søkbare i Dm)</Trans>,
        maxItems: 10,
        items: {
            type: 'object',
            title: null,
            properties: {
                tag: { type: 'string' },
                origin: {
                    type: 'string',
                    default: 'ARTICLE_EDITOR'
                }
            }
        }
    },
    sections: {
        type: 'array',
        title: null,
        items: {
            type: 'object',
            required: ['type'],
            properties: {
                type: {
                    type: 'string',
                    readOnly: true,
                    title: null,
                    enum: [
                        'museum_head',
                        'museum_about',
                        'museum_trending'
                    ]
                }
            },
            dependencies: {
                type: {
                    oneOf: [
                        museumHeaderSection,
                        museumAboutSection,
                        museumTrendingSection
                    ]
                }
            }
        }
    }
};
