import { kitFetch } from '@ekultur/fetch';
import { config } from '../../config';

export const FOLDERS_REQUESTED = 'FOLDERS_REQUESTED',
    FOLDERS_SUCCEEDED = 'FOLDERS_SUCCEEDED',
    FOLDERS_FAILED = 'FOLDERS_FAILED',
    SET_ACTIVE_FOLDER_TITLE = 'SET_ACTIVE_FOLDER_TITLE',
    FOLDER_OBJECTS_REQUESTED = 'FOLDER_OBJECTS_REQUESTED',
    FOLDER_OBJECTS_SUCCEEDED = 'FOLDER_OBJECTS_SUCCEEDED',
    FOLDERS_OBJECTS_FAILED = 'FOLDERS_OBJECTS_FAILED',
    WIKIMEDIA_EXPORT_REQUESTED = 'WIKIMEDIA_EXPORT_REQUESTED',
    WIKIMEDIA_EXPORT_SUCCEEDED = 'WIKIMEDIA_EXPORT_SUCCEEDED',
    WIKIMEDIA_EXPORT_FAILED = 'WIKIMEDIA_EXPORT_FAILED',
    WIKIMEDIA_EXPORT_STATUS_REQUESTED = 'WIKIMEDIA_EXPORT_STATUS_REQUESTED',
    WIKIMEDIA_EXPORT_STATUS_SUCCEEDED = 'WIKIMEDIA_EXPORT_STATUS_SUCCEEDED',
    WIKIMEDIA_EXPORT_STATUS_FAILED = 'WIKIMEDIA_EXPORT_STATUS_FAILED',
    WIKIMEDIA_EXPORT_STATUSES_REQUESTED = 'WIKIMEDIA_EXPORT_STATUSES_REQUESTED',
    WIKIMEDIA_EXPORT_STATUSES_SUCCEEDED = 'WIKIMEDIA_EXPORT_STATUSES_SUCCEEDED',
    WIKIMEDIA_EXPORT_STATUSES_FAILED = 'WIKIMEDIA_EXPORT_STATUSES_FAILED';

export const requestFolders = (ownerId, userId, orderBy, order, start, rows) => dispatch => {
    dispatch({
        type: FOLDERS_REQUESTED
    });

    kitFetch(`${config.get('api')}folder/${ownerId}/?userId=${userId}&orderBy=${orderBy}&order=${order}&start=${start}&rows=${rows}`)
        .then(data => dispatch({
            type: FOLDERS_SUCCEEDED,
            data: data
        }))
        .catch(error => dispatch({
            type: FOLDERS_FAILED,
            data: error
        }));
};

export const requestWikimediaExportStatuses = (ownerId, ids) => dispatch => {
    const postConfig = {
        method: 'POST',
        body: JSON.stringify({
            owner_id: ownerId,
            folder_ids: ids
        })
    };

    dispatch({
        type: WIKIMEDIA_EXPORT_STATUSES_REQUESTED
    });

    kitFetch(`${config.get('wikimediaApi')}upload_status_for_folders`, postConfig)
        .then(data => dispatch({
            type: WIKIMEDIA_EXPORT_STATUSES_SUCCEEDED,
            data: data.folderStatus
        }))
        .catch(error => dispatch({
            type: WIKIMEDIA_EXPORT_STATUSES_FAILED,
            data: error
        }));
}

export const requestFolderObjects = (userId, folderId, start, rows) => dispatch => {
    dispatch({
        type: FOLDER_OBJECTS_REQUESTED
    });

    kitFetch(`${config.get('api')}folder/${folderId}/objects/?userId=${userId}&start=${start}&rows=${rows}`)
        .then(data => dispatch({
            type: FOLDER_OBJECTS_SUCCEEDED,
            data: data
        }))
        .catch(error => dispatch({
            type: FOLDERS_OBJECTS_FAILED,
            data: error
        }));
};

export const requestWikimediaExport = (ownerId, folderId, count, wmName) => dispatch => {
    dispatch({
        type: WIKIMEDIA_EXPORT_REQUESTED
    });

    const postConfig = {
        method: 'POST',
        body: JSON.stringify({
            owner_id: ownerId,
            folder_id: folderId,
            count: count,
            wm_institution: wmName
        })
    };

    kitFetch(`${config.get('wikimediaApi')}prepare_upload`, postConfig)
        .then(data => {
            dispatch({
                type: WIKIMEDIA_EXPORT_SUCCEEDED,
                data: data
            });
            dispatch({
                type: WIKIMEDIA_EXPORT_STATUS_SUCCEEDED,
                data: { status: data.status }
            });
        })
        .catch(error => dispatch({
            type: WIKIMEDIA_EXPORT_FAILED,
            data: error
        }));
};

export const requestWikimediaExportStatus = (ownerId, folderId, status) => dispatch => {
    const postConfig = {
        method: 'POST',
        body: JSON.stringify({
            owner_id: ownerId,
            folder_id: folderId
        })
    };

    if (!status) {
        dispatch({
            type: WIKIMEDIA_EXPORT_STATUS_REQUESTED
        });

        kitFetch(`${config.get('wikimediaApi')}upload_status`, postConfig)
            .then(data => dispatch({
                type: WIKIMEDIA_EXPORT_STATUS_SUCCEEDED,
                data: data
            }))
            .catch(error => dispatch({
                type: WIKIMEDIA_EXPORT_STATUS_FAILED,
                data: error
            }));
    } else {
        let pending = false;
    
        const interval = setInterval(() => {
            if (!pending) {
                kitFetch(`${config.get('wikimediaApi')}upload_status`, postConfig)
                    .then(data => {
                        pending = false;
                
                        if (['finished', 'failed'].includes(data.status)) {
                            clearInterval(interval);
                            
                            dispatch({
                                type: WIKIMEDIA_EXPORT_STATUS_SUCCEEDED,
                                data: data
                            });
                        }
                    })
                    .catch(error => dispatch({
                        type: WIKIMEDIA_EXPORT_STATUS_FAILED,
                        data: error
                    }));
            }
        
            pending = true;
        }, 10000);
    }
};

export const setActiveFolder = title => dispatch => {
    dispatch({
        type: SET_ACTIVE_FOLDER_TITLE,
        title: title
    });
};
