import { nb } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DatePicker, { setDefaultLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles(theme => ({
    datePicker: {
        '& input': {
            border: 0,
            background: 'transparent',
            outline: 'none',
            fontSize: '1rem'
        },

        '& .react-datepicker': {
            border: 'none',
            boxShadow: 'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px'
        },

        '& .react-datepicker__header': {
            backgroundColor: 'white'
        },

        '& .react-datepicker__triangle:before, & .react-datepicker__triangle:after': {
            borderBottomColor: 'white !important'
        },

        '& .react-datepicker__day--in-range': {
            position: 'relative',
            
            '&:not(.react-datepicker__day--selected):not(:first-child):before': {
                position: 'absolute',
                left: '-.6rem',
                content: '""',
                backgroundColor: theme.palette.primary.main,
                width: '.8rem',
                height: '1.7rem',
                zIndex: 1
            },
        },

        '& .react-datepicker__day--selected, & .react-datepicker__day--in-range, & .react-datepicker__day--keyboard-selected': {
            backgroundColor: theme.palette.primary.main
        },

        '& .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range)': {
            backgroundColor: theme.palette.primary.light
        }
    }
}));

export const DatePickers = ({ onSelect, dates }) => {
    const classes = useStyles(),
        [customDates, setDates] = useState([dates[0], dates[1]]);

    useEffect(() => {
        setDefaultLocale(nb);
    }, []);

    const onChange = newDates => {
        setDates(newDates);

        if (newDates[0] instanceof Date && !isNaN(newDates[0]) && newDates[1] instanceof Date && !isNaN(newDates[1])) {
            onSelect && onSelect({ start: newDates[0], end: newDates[1] });
        }
    };

    return (
        <div className={classes.datePicker}>
            <DatePicker
                selected={customDates[0]}
                onChange={onChange}
                dateFormat='dd.MM.yyyy'
                startDate={customDates[0]}
                endDate={customDates[1]}
                selectsRange
                autoFocus={!(customDates[0] && customDates[1])}
            />
        </div>
    );
};
