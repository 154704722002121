import React from 'react';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    progress: {
        position: 'relative',
        top: props => props.top || 0
    }
}));

export const ProgressIndicator = ({ style }) => {
    const classes = useStyles(style);

    return (
        <div className={classes.progress}>
            <Fade in={true} style={{ transitionDelay: '800ms' }} unmountOnExit={true}>
                <LinearProgress />
            </Fade>
        </div>
    );
};
