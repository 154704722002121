import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme, Paper, Typography } from '@mui/material';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { requestStatistics } from '../redux/actions/statistics';
import { setTimePeriod } from '../redux/functions/statistics';
import { getValueFromQueryString } from '../redux/functions/query';

import { WithProgressOverlay } from '../common/WithProgressOverlay';

const useStyles = makeStyles(theme => ({
    root: {
        height: 500,
        padding: theme.spacing(2),
        '& text': {
            fontSize: '.8rem'
        }
    }
}));

const formatLegend = name => {
    if (name.length > 24) {
        return `...${name.substring(name.length - 22)}`;
    }

    return name;
};

export const StatisticsReportAcquisition = ({ viewType }) => {
    const classes = useStyles(),
        dispatch = useDispatch(),
        { palette } = useTheme(),
        { t, ready } = useTranslation('googleAnalytics', { useSuspense: false }),
        period = getValueFromQueryString('period'),
        { reports } = useSelector(state => state.gaStatistics),
        acquisition = reports && reports[viewType].acquisition,
        { fetching, rows, maximums } = acquisition,
        data = rows.map(row => ({
            pageViews: parseInt(row.metrics[0].values[0]),
            name: formatLegend(row.dimensions[0])
        })),
        { ownerUniqueId } = useSelector(state => state.app),
        { filters, currentDetail } = useSelector(state => state.layout),
        timePeriod = setTimePeriod(filters, currentDetail, period, false, true);

    useEffect(() => {
        if (timePeriod.start && timePeriod.end) {
            dispatch(requestStatistics(ownerUniqueId, timePeriod.start, timePeriod.end, 'acquisition', viewType, 0,
                'source', 'uniquePageviews', 'uniquePageviews', 'descending', false, null, 21));
        }
    }, [dispatch, ownerUniqueId, timePeriod.start, timePeriod.end, viewType]);

    return (
        <WithProgressOverlay loading={fetching || !ready}>
            <Paper className={classes.root}>
                <Typography variant="h6" align="center">
                    {t('sources', 'Kilder')}
                </Typography>
                <ResponsiveContainer id="acquisitionResponsive" width='99%' height={460}>
                    <BarChart
                        layout="vertical"
                        data={data}
                        margin={{ top: 10, left: 100 }}
                    >
                        <XAxis type="number" domain={[0, parseInt(maximums[0])]} />
                        <YAxis dataKey="name" type="category" reversed={true} />
                        <Tooltip />
                        <Legend />
                        <Bar
                            dataKey='pageViews'
                            name={t('uniquePageViews', 'Unike sidevisninger')}
                            barSize={20}
                            fill={palette.primary.main}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </Paper>
        </WithProgressOverlay>
    );
};
