import numeral from 'numeral';
import {
    GA_STATISTICS_FAILED,
    GA_STATISTICS_REQUESTED,
    GA_STATISTICS_SUCCEEDED,
    GA_STATISTICS_DOWNLOAD_REQUESTED,
    GA_STATISTICS_DOWNLOAD_SUCCEEDED,
    GA_STATISTICS_HOME_REQUESTED,
    GA_STATISTICS_HOME_SUCCEEDED,
    GA_STATISTICS_REALTIME_REQUESTED,
    GA_STATISTICS_REALTIME_SUCCEEDED,
    GA_REPORT_EXPORT_REQUESTED,
    GA_REPORT_EXPORT_STARTED,
    GA_REPORT_EXPORT_SUCCEEDED,
    GA_REPORT_EXPORT_FAILED
} from '../types';

const reportSet = {
    fetching: null,
    rows: [],
    maximums: 0,
    minimums: 0,
    totals: 0,
    dateRanges: []
};

const tableReportSet = {
    fetching: null,
    headers: [],
    totals: [],
    rows: [],
    rowCount: 0
}

const defaultState = {
    error: false,
    reports: {
        realtime: {
            activeUsersReport: { rows: [] },
            pageviewsReport: { rows: [] },
            fetching: null
        },
        dashboard: {
            metric: reportSet,
            acquisition: reportSet,
            location: reportSet,
            objectTypes: reportSet
        },
        report: {
            pageViews: tableReportSet,
            objectTypes: tableReportSet,
            objects: tableReportSet,
            downloads: tableReportSet
        },
        published: {
            fetching: null,
            rows: [],
            published: 0,
            notPublished: 0,
            dataMin: 0,
            dataMax: 0
        },
        export: {
            ...reportSet,
            requested: false
        }
    }
};

const statistics = (state = defaultState, action) => {
    const viewType = action.viewType || 'report',
        type = action.reportType || 'metric';
    let draft = { ...state, error: false };

    switch (action.type) {
        case GA_STATISTICS_REQUESTED:
            draft.reports[viewType][type].fetching = true;
            break;
        case GA_STATISTICS_FAILED:
            if (action.reportType !== 0 && !action.reportType) {
                draft.reports[viewType].fetching = false;
            } else {
                draft.reports[viewType][type].fetching = false;
            }
            draft.error = true;
            break;
        case GA_STATISTICS_SUCCEEDED:
            if (action.data.reports) {
                draft.error = false;

                if (viewType === 'dashboard') {
                    if (action.data.reportType === 0) {
                        draft.reports.dashboard.metric = {
                            fetching: false,
                            dateRanges: action.data.reports[0].request.dateRanges || [],
                            ...buildData(action.data.reports[0])
                        };
                    } else if (action.data.reportType === 'objectTypes') {
                        draft.reports.dashboard.objectTypes = {
                            fetching: false,
                            ...buildData(action.data.reports[0])
                        };
                    } else if (action.data.reportType === 'location') {
                        draft.reports.dashboard.location = {
                            fetching: false,
                            ...buildData(action.data.reports[0])
                        };
                    } else if (action.data.reportType === 'acquisition') {
                        draft.reports.dashboard.acquisition = {
                            fetching: false,
                            ...buildData(action.data.reports[0])
                        };
                    }
                } else if (viewType === 'report') {
                    if (action.data.reportType === 'pageViews') {
                        draft.reports.report.pageViews = {
                            fetching: false,
                            ...buildTableData(action.data.reports[0])
                        };
                    } else if (action.data.reportType === 'objectTypes') {
                        draft.reports.report.objectTypes = {
                            fetching: false,
                            ...buildTableData(action.data.reports[0])
                        };
                    } else if (action.data.reportType === 'objects') {
                        draft.reports.report.objects = {
                            fetching: false,
                            ...buildTableData(action.data.reports[0])
                        };
                    }
                }
            }

            break;
        case GA_STATISTICS_HOME_REQUESTED:
            draft.error = false;
            break;
        case GA_STATISTICS_HOME_SUCCEEDED:
            if (action.data) {
                const reports = draft.reports.home.reports,
                    res = [{ ...action.data }];

                draft.reports.home = {
                    fetching: false,
                    reports: reports.concat(res)
                };
            }

            break;
        case GA_STATISTICS_REALTIME_REQUESTED:
            draft.reports.realtime.fetching = true;
            draft.error = false;
            break;
        case GA_STATISTICS_REALTIME_SUCCEEDED:
            if (action.data) {
                draft.reports.realtime = {
                    fetching: false,
                    ...action.data
                };
            }
            break;
        case GA_STATISTICS_DOWNLOAD_REQUESTED:
            draft.error = false;
            draft.reports.report.downloads.fetching = true;
            break;
        case GA_STATISTICS_DOWNLOAD_SUCCEEDED:
            if (action.data && action.data.reports.length) {
                draft.reports.report.downloads = {
                    fetching: false,
                    ...buildTableData(action.data.reports[0])
                };
            } else {
                draft.reports.report.downloads = {...tableReportSet, fetching: false};
            }
            break;
        case GA_REPORT_EXPORT_REQUESTED:
            draft.reports.export.requested = true;
            draft.reports.export.error = false;
            break;
        case GA_REPORT_EXPORT_STARTED:
            draft.reports.export.fetching = true;
            break;
        case GA_REPORT_EXPORT_SUCCEEDED:
            draft.reports.export.requested = false;
            draft.reports.export.fetching = false;
            break;
        
            case GA_REPORT_EXPORT_FAILED:
            draft.reports.export.fetching = false;
            draft.reports.export.error = true;
            break;
        default:
            return draft;
    }

    return draft;
};

export default statistics;


const buildData = report => {
    return {
        rows: report.data.rows || [],
        maximums: report.data.maximums || 0,
        minimums: report.data.minimums || 0,
        totals: report.data.totals || 0
    };
};

const buildTableData = report => {
    if (!report) {
        return { rowCount: 0 };
    }

    const headers = report.columnHeader.metricHeader.metricHeaderEntries;

    return {
        headers: headers.map(header => header.name.split(':')[1]),
        totals: report.data.totals[0].values.map((value, index) => formatValue(value, headers[index].type)),
        rows: report.data.rows
            ? (report.data.rows.map(row => ({
                header: {
                    title: row.dimensions[0],
                    id: row.dimensions[1]
                },
                cells: row.metrics[0].values.map((value, index) => formatValue(value, headers[index].type))
        
            }))) : [],
        rowCount: report.data.rowCount || 0
    };
};

const formatValue = (value, type) => {
    switch (type) {
        case 'TIME':
            return numeral(value).format('00:00:00');
        case 'PERCENT':
            return numeral(value / 100).format('0.00%');
        default:
            return value
    }
};
