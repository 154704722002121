import React from 'react';
import { Trans } from 'react-i18next';

export const baseSchema = {
    definitions: {
        _editor: {
            type: 'object',
            title: null,
            properties: {
                _collapse: {
                    type: 'boolean',
                    enum: [true, false],
                    default: false
                }
            }
        },
        settings: {
            type: 'object',
            properties: {
                visibility: {
                    type: 'string',
                    enum: [
                        'visible',
                        'hidden'
                    ],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="visible">Synlig</Trans>,
                        <Trans ns="dm.article" i18nKey="hidden">Skjult</Trans>
                    ],
                    default: 'visible'
                }
            }
        }
    },
    type: 'object',
    properties: {
        content: {
            type: 'object',
            title: null,
            properties: {}
        }
    }
};
