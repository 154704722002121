import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Tab, Tabs, useTheme } from '@mui/material';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { requestTimeseriesStatistics } from '../../redux/actions/plausible/statistics';
import { setTimePeriod } from '../../redux/functions/statistics';
import { getValueFromQueryString } from '../../redux/functions/query';

import { MetricsTooltip } from '../MetricsTooltip';
import { parseDate, parsePlausibleDimension } from '../parsers';
import { TabLabel } from '../TabLabel';
import { WithProgressOverlay } from '../../common/WithProgressOverlay';
import { secondsToMinutesSeconds } from '../time/timeFormatter';
import { formatMonths } from '../date/dateFormatter';

const useStyles = makeStyles(theme => ({
    root: {
        height: 420 + parseInt(theme.spacing(4)),
        '& text': {
            fontSize: '.8rem'
        }
    },
    container: {
        padding: theme.spacing(2)
    },
    tabs: {
        marginBottom: theme.spacing(2)
    },
    tabPanel: {
      padding: theme.spacing(2)
    },
    tabIndicator: {
        top: 0
    }
}))

export const StatisticsReportMetrics = ({ viewType }) => {
    const dispatch = useDispatch(),
        classes = useStyles(),
        { palette } = useTheme(),
        [selectedTab, setSelectedTab] = useState(0),
        { t } = useTranslation(['plausibleAnalytics'], { useSuspense: false }),
        period = getValueFromQueryString('period'),
        { reports } = useSelector(state => state.statistics),
        report = reports && reports[viewType].metric,
        { fetching, response, total, dateRanges } = report,
        { ownerUniqueId } = useSelector(state => state.app),
        { filters, currentDetail } = useSelector(state => state.layout),
        timePeriod = setTimePeriod(filters, currentDetail, period, false, true);

    const getData = () => {
        const displayName = name => timePeriod.interval === 'month' ? formatMonths(name) : name;

        if (selectedTab === 0) {
            return response.results.map(row => ({
                name: displayName(row.date),
                thisPeriod: row.pageviews,
                type: 'uniquePageViews'
            }));
        } else if (selectedTab === 1) {
            return response.results.map(row => ({
                name: displayName(row.date),
                thisPeriod: row.visitors,
                type: 'users'
            }));
        } else if (selectedTab === 2) {
            return response.results.map(row => ({
                name: displayName(row.date),
                thisPeriod: row.visit_duration,
                type: 'avgTimeOnPage'
            }));
        } else if (selectedTab === 3) {
            return response.results.map(row => ({
                name: displayName(row.date),
                thisPeriod: row.bounce_rate,
                type: 'bounceRate'
            }));
        }
    };

    useEffect(() => {
        if (timePeriod.start && timePeriod.end) {
            dispatch(
                requestTimeseriesStatistics(
                    ownerUniqueId, timePeriod.start, timePeriod.end,
                    0, viewType, timePeriod.interval
                )
            );
        }
    }, [dispatch, ownerUniqueId, timePeriod.start, timePeriod.end, viewType, timePeriod.interval]);

    const yMax = () => {
        let data;
        if (selectedTab === 0){
            data = response.results.map(row => {return row.pageviews});
        } else if (selectedTab === 1){
            data = response.results.map(row => {return row.visitors});
        } else if (selectedTab === 2){
            data = response.results.map(row => {return row.visit_duration});
        } else if (selectedTab === 3){
            data = response.results.map(row => {return row.bounce_rate});
        }

        return data.length ? Math.max(data) : 1000;
    }

    const currentPeriod = {
        startDate: dateRanges.length ? parseDate(dateRanges[0].startDate) : '',
        endDate: dateRanges.length ? parseDate(dateRanges[0].endDate) : ''
    };

    const handleTabChange = (event, newValue) => setSelectedTab(newValue);

    return (
        <WithProgressOverlay loading={fetching}>
            <Paper className={classes.root}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label='metrics tabs'
                    indicatorColor={'primary'}
                    className={classes.tabs}
                    classes={{
                        indicator: classes.tabIndicator
                    }}
                >
                    <Tab
                        label={
                            <TabLabel
                                primaryText={t('uniquePageViews', 'Unike sidevisninger')}
                                totals={total.pageviews ? parseInt(total.pageviews) : 0}
                            />
                        }
                        className={classes.tabPanel}
                    />
                    <Tab
                        label={
                            <TabLabel
                                primaryText={t('users', 'Brukere')}
                                totals={total.visitors ? parseInt(total.visitors) : 0}
                            />
                        }
                        className={classes.tabPanel}
                    />
                    <Tab
                        label={
                            <TabLabel
                                primaryText={t('avgTimeOnPage', 'Gjenomsnitt tid/side')}
                                totals={`${total.visit_duration ? secondsToMinutesSeconds(total.visit_duration / response.results.length) : 0}`}
                            />
                        }
                        className={classes.tabPanel}
                    />
                </Tabs>
                <div className={classes.container}>
                    <ResponsiveContainer width='99%' height={300} >
                        <LineChart data={getData()}>
                            <CartesianGrid vertical={false}/>
                            <XAxis
                                dataKey='name'
                                tickFormatter={(tickItem) => parsePlausibleDimension(currentPeriod, timePeriod.orderBy, tickItem, t)}
                                axisLine={false}
                                interval={'preserveStartEnd'}
                                padding={{right: 40}}
                            />
                            <YAxis
                                domain={[0, yMax()]}
                                orientation={'right'}
                                axisLine={false}
                                tickLine={false}
                                mirror={true}
                            />
                            <Tooltip
                                content={
                                    <MetricsTooltip
                                        parser={parsePlausibleDimension}
                                        dimension={timePeriod.orderBy}
                                        period={currentPeriod}
                                        t={t}
                                    />
                                }
                            />
                            <Line
                                type='monotone'
                                dataKey='thisPeriod'
                                stroke={palette.primary.main}
                                activeDot={{r: 8}}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </Paper>
        </WithProgressOverlay>
    )
};
