import React from 'react';
import { useTranslation } from "react-i18next";


export const Unauthorized = () => {
    const { t } = useTranslation(['dm.common'], { useSuspense: false }),
        msg = t('unauthorized', 'Du har ikke tilgang til dette innholdet');

    return (
        <h3>{msg}</h3>
    )
};