import { dmsUrl, filterStyle } from '@ekultur/ui-schema-components';
import { MuseumMap } from './MuseumMap';

export const MuseumSecondaryMedia = ({ mediaList, coordinates, customCoordinates, show, override }) => {

    const hasValidCoordinates = () => {
        return isValidCoordinates(coordinates) || (isValidCoordinates(customCoordinates) && override);
    }
    const isValidCoordinates = (coords) => {
        return typeof coords?.latitude === "number" && typeof coords?.longitude === "number";
    };

    const renderHeaderImage = m => {
        return (
            <div className='header-media__media'>
                <div style={{ ...filterStyle(m.media), backgroundImage: `url(${dmsUrl(m, 10)})` }}></div>
            </div>
        );
    };


    const renderImages = () => {
        if (!mediaList || mediaList.length < 2) {
            return null;
        }

        const renderSingleImage = () => (
            <div id='@section_1.blocks_0' className='header-media__secondary__group'>
                <div className='header-media__secondary__group--single'>
                    {mediaList[1] && renderHeaderImage(mediaList[1])}
                </div>
            </div>
        );

        const renderSingleAndDoubleImages = () => (
            <div id='@section_1.blocks_0' className='header-media__secondary__group'>
                <div className='header-media__secondary__group--single'>
                    {mediaList[1] && renderHeaderImage(mediaList[1])}
                </div>
                <div className='header-media__secondary__group--double'>
                    {mediaList[2] && renderHeaderImage(mediaList[2])}
                    {mediaList[3] && renderHeaderImage(mediaList[3])}
                </div>
            </div>
        );

        return mediaList.length === 2 ? renderSingleImage() : renderSingleAndDoubleImages();
    }

    if (mediaList?.length > 1 || hasValidCoordinates()) {
        return (
            <div className='header-media__secondary'>
                {renderImages()}
                <MuseumMap coordinates={coordinates}
                           customCoordinates={customCoordinates}
                           show={show}
                           override={override}
                />
            </div>
        )
    } else {
        return null;
    }
}
