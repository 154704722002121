import React from 'react';
import { Trans } from 'react-i18next';

export const artifactTypes = [
        { value: '', label: <Trans ns="dm.objectTypes" i18nKey="alle">Alle objekttyper</Trans> },
        { value: 'photograph', label: <Trans ns="dm.objectTypes" i18nKey="photograph">Fotografi</Trans> },
        { value: 'thing', label: <Trans ns="dm.objectTypes" i18nKey="thing">Ting</Trans> },
        { value: 'fineart', label: <Trans ns="dm.objectTypes" i18nKey="fineart">Billedkunst</Trans> },
        { value: 'artdesign', label: <Trans ns="dm.objectTypes" i18nKey="artdesign">Design</Trans> },
        { value: 'person', label: <Trans ns="dm.objectTypes" i18nKey="Person">Person</Trans> },
        { value: 'organization', label: <Trans ns="dm.objectTypes" i18nKey="organization">Organisasjon</Trans> },
        { value: 'navalvessel', label: <Trans ns="dm.objectTypes" i18nKey="navalvessel">Fartøy</Trans> },
        { value: 'architecture', label: <Trans ns="dm.objectTypes" i18nKey="architecture">Arkitektur</Trans> },
        { value: 'folder', label: <Trans ns="dm.objectTypes" i18nKey="folder">Mappe</Trans> },
        { value: 'story', label: <Trans ns="dm.objectTypes" i18nKey="story">Fortelling</Trans> },
        { value: 'building', label: <Trans ns="dm.objectTypes" i18nKey="building">Bygning</Trans> },
        { value: 'video', label: <Trans ns="dm.objectTypes" i18nKey="video">Video</Trans> },
        { value: 'exhibition', label: <Trans ns="dm.objectTypes" i18nKey="exhibition">Utstilling</Trans> },
        { value: 'article', label:  <Trans ns="dm.objectTypes" i18nKey="article">Artikkel</Trans> },
        { value: 'audio', label: <Trans ns="dm.objectTypes" i18nKey="audio">Lydklipp</Trans> },
        { value: 'event', label: <Trans ns="dm.objectTypes" i18nKey="event">Hendelse</Trans> },
        { value: 'timeperiod', label: <Trans ns="dm.objectTypes" i18nKey="timeperiod">Tidsperiode</Trans> },
        { value: 'construction', label: <Trans ns="dm.objectTypes" i18nKey="construction">Konstruksjon</Trans> },
];
