import { Trans } from 'react-i18next';

import { museumImagesBlock } from './imagesBlock';
import { museumMapBlock } from './mapBlock';
import { museumDescriptionBlock } from './descriptionBlock';
import { addressBlock } from './addressBlock';
import { phoneBlock } from './phoneBlock';
import { museumUrlBlock } from './urlBlock';
import { museumIngressBlock } from './ingressBlock';
import { uuStatementUrlBlock } from './uuStatementUrlBlock';

export const museumAboutSection = {
    type: 'object',
    title: <Trans ns="dm.article" i18nKey="label_about">Om</Trans>,
    required: ['type'],
    properties: {
        type: { enum: ['museum_about'] },
        _editor: { '$ref': '#/definitions/_editor' },
        blocks: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
            title: null,
            items: {
                type: 'object',
                required: ['type'],
                properties: {
                    type: {
                        type: 'string',
                        readOnly: true,
                        title: null,
                        enum: [
                            'museum_images',
                            'museum_coordinates',
                            'museum_description',
                            'museum_ingress',
                            'museum_address',
                            'museum_phone',
                            'museum_url',
                            'uu_declaration'
                        ]
                    }
                },
                dependencies: {
                    type: {
                        oneOf: [
                            museumImagesBlock,
                            museumMapBlock,
                            museumDescriptionBlock,
                            museumIngressBlock,
                            addressBlock,
                            phoneBlock,
                            museumUrlBlock,
                            uuStatementUrlBlock
                        ]
                    }
                }
            }
        }
    }
};
