import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { HeaderProvider } from '@ekultur/header-microfrontend';

import { WithMuseumSelector } from './WithMuseumSelector';
import { Features } from './Features';
import { UserAccess } from './UserAccess';
import { Dashboard } from '../Dashboard/Dashboard';
import { UserDashboard } from '../Dashboard/UserDashboard';
import { ArticlesList } from '../Article/ArticlesList';
import StatisticsFinder from '../Statistics';
import { PublishedObjects } from '../Statistics/published/PublishedObjects';
import { Theme } from './AppTheme';
import { Admin } from '../Admin/Admin';
import { MashupInbox, Mashup } from '../Mashup';
import { Article } from '../Article/Article';
import { HomePage } from '../Article/HomePage';
import { MuseumPage } from '../Article/MuseumPage';
import { Folders } from '../Folders/Folders';
import { FolderObjects } from '../Folders/FolderObjects';
import { MediaGallery } from '../MediaGallery/MediaGallery';
import { WithUserdata } from '../header/WithUserdata';
import { AppRoutesAnonymous } from './AppRoutesAnonymous';
import { WithAuthentication } from '../header/WithAuthentication';
import { WithMuseums } from '../header/WithMuseums';

export const App = () => {
    return (
        <Theme>
            <Router>
                <HeaderProvider>
                    <WithAuthentication>
                        <AppRoutesAnonymous />
                        <WithUserdata>
                            <WithMuseums>
                                <WithMuseumSelector>
                                    <Features>
                                        <Admin>
                                            <Routes>
                                                <Route path='/' element={<UserDashboard />} />
                                                <Route path='start' element={<UserAccess><Dashboard /></UserAccess>} />
                                                <Route path='article' element={<UserAccess><ArticlesList /></UserAccess>} />
                                                <Route path='article/edit/:articleId' element={<UserAccess><Article /></UserAccess>} />
                                                <Route path='homepage' element={<UserAccess><ArticlesList /></UserAccess>} />
                                                <Route path='homepage/edit/:articleId' element={<UserAccess><HomePage /></UserAccess>} />
                                                <Route path='museumpage' element={<UserAccess><ArticlesList /></UserAccess>} />
                                                <Route path='museumpage/edit/:articleId' element={<UserAccess><MuseumPage /></UserAccess>} />
                                                <Route path='inbox' element={<UserAccess><MashupInbox /></UserAccess>}>
                                                    <Route path=':mashupType' element={<UserAccess><MashupInbox /></UserAccess>} />
                                                </Route>
                                                <Route path='inbox/details/:dimuCode' element={<UserAccess><Mashup /></UserAccess>} />
                                                <Route path='statistics' element={<UserAccess><StatisticsFinder /></UserAccess>}>
                                                    <Route path='objectTypes' element={<UserAccess><StatisticsFinder /></UserAccess>} />
                                                    <Route path='objectTypes/:objectType' element={<UserAccess><StatisticsFinder /></UserAccess>} />
                                                    <Route path='downloads' element={<UserAccess><StatisticsFinder /></UserAccess>} />
                                                </Route>
                                                <Route path='statistics/published' element={<UserAccess><PublishedObjects /></UserAccess>} />
                                                <Route path='folders' element={<UserAccess><Folders /></UserAccess>} />
                                                <Route path='folders/:folderId' element={<UserAccess><FolderObjects /></UserAccess>} />
                                                <Route path='gallery' element={<UserAccess><MediaGallery /></UserAccess>} />
                                            </Routes>
                                        </Admin>
                                    </Features>
                                </WithMuseumSelector>
                            </WithMuseums>
                        </WithUserdata>
                    </WithAuthentication>
                </HeaderProvider>
            </Router>
        </Theme>
    );
};
