import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

// useMuiComponent is not yet exported as documented. Use MuiComponentContext as workaround.
// import { useMuiComponent } from '@rjsf/material-ui/v5';
import { MuiComponentContext } from '@rjsf/material-ui/v5';

import { Box, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import updateKnavField from './updateKnavField';

function KnavFieldTemplate(props) {
    const { fields: { ObjectField, StringField }, formContext: { t, owner }, /*registry: { widgets: { TextWidget } },*/ schema, uiSchema, rawHelp } = props,
        [loaded, setLoaded] = useState(false),
    // { FormHelperText } = useMuiComponent();
    { FormHelperText } = useContext(MuiComponentContext);
    
    if (!owner || !owner.knavUUID || !owner.id) {
        return null;
    }

    const handleUpdate = () => {
        updateKnavField(uiSchema['ui:KnavFieldName'], owner.id, owner.knavUUID, props.onChange);
        setLoaded(true);
    };

    const renderUpdateBtn = () => {
        return (
            <Button
                disabled={loaded}
                onClick={handleUpdate}
                title={t && t('updateNow', 'Oppdater nå')}
                sx={{ marginLeft: 1 }}
            >
                <RefreshIcon />
            </Button>
        );
    };

    const renderHelpText = () => {
        return (
            <FormHelperText id={`${uiSchema['ui:KnavFieldName|']}-help`} sx={{ width: '100%' }}>
                {rawHelp}
            </FormHelperText>
        );
    };

    if (schema.type === 'object') {       
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {ObjectField && <ObjectField {...props} />}
                    </Box>
                    {renderUpdateBtn()}
                </Box>
                {rawHelp && renderHelpText()}
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
                {/* TitleField && <TitleField {...props} /> */}
                {StringField && <StringField {...props} />}
                {/* {TextField && <TextField {...props} />}
                {TextWidget && <TextWidget {...props} />} */}
                {renderUpdateBtn()}
            </Box>
            {rawHelp && renderHelpText()}
        </Box>
    );
}

KnavFieldTemplate.propTypes = {
    formContext: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.object.isRequired
};

export default KnavFieldTemplate;
