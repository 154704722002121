import React from 'react';
import { Trans } from 'react-i18next';

export const logoDescSettings = {
    type: 'object',
    properties: {
        layout: {
            type: 'string',
            enum: [
                'portrait',
                'landscape'
            ],
            enumNames: [
                <Trans ns="dm.article" i18nKey="owner.headerblock.logo_portrait">Stående</Trans>,
                <Trans ns="dm.article" i18nKey="owner.headerblock.logo_landscape">Liggende</Trans>
            ],
            default: 'portrait'
        },
        colorpicker: {
            type: 'string',
            default: '#cccccc'
        },
        colorpickercontrast: {
            type: 'string',
            enum: [
                'black',
                'white'
            ],
            enumNames: [
                <Trans ns="dm.article" i18nKey="owner.headerblock.textColor_black">Svart</Trans>,
                <Trans ns="dm.article" i18nKey="owner.headerblock.textColor_white">Hvit</Trans>
            ],
            default: 'black'
        }
    }
};
