import { kitFetch } from '@ekultur/fetch';

export const USER_ACCESS = 'USER_ACCESS',
    SET_USER_DATA = 'SET_USER_DATA',
    USER_LOGGED_IN = 'USER_LOGGED_IN',
    USER_LOGGED_OUT = 'USER_LOGGED_OUT',
    MUSEUMS_RECEIVED = 'MUSEUMS_RECEIVED';

export const getUserData = () => dispatch => {
    kitFetch(`/authz/authorize/userData`)
        .then(json => {
            dispatch({
                type: SET_USER_DATA,
                userData: json
            });
        })
        .catch(error => {
            console.error(error)
        });
};

export const setUserAccess = roleAccess => dispatch => {
    dispatch({
        type: USER_ACCESS,
        roleAccess: roleAccess
    });
};
