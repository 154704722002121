import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, {Suspense} from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import i18n from './i18n';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

import { App } from './App/App';
import configureStore from './store';
// import { reportWebVitals } from './reportWebVitals';
import './index.css';


const container = document.getElementById('root'),
    root = createRoot(container);

if(window._env_ && window._env_.SENTRY_ENV !== 'local') {
    Sentry.init({
        dsn: 'https://31b0a64ed53343cfb86e51a532ab37fb@o1289125.ingest.sentry.io/6542957',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.2,
        environment: window._env_.SENTRY_ENV,
        enabled: window._env_.SENTRY_ENV !== 'local',
        release: 'dm-admin@' + window._env_.REACT_APP_VERSION
    });
}

const Routes = () => (
    <Suspense fallback={"loading app"}>
        <Provider store={configureStore()}>
            <App/>
        </Provider>
    </Suspense>
);

i18n.init().then(
    () => root.render(<Routes />)
);

// reportWebVitals();
