import React, { useEffect, useRef, useState } from 'react';
import Form from '@rjsf/material-ui/v5';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';

import { setExportEnabled, setFinderMode } from '../redux/actions/layout';
import { requestResources, updateResources, deleteResource } from '../redux/actions/media';

import { imageFileSchema } from './schema/imageFileSchema';
import { imageFileUiSchema } from './schema/imageFileUiSchema';
import { ProgressIndicator } from '../common/ProgressIndicator';
import { ConfirmDialog } from '../Article/ArticleListActions/ConfirmDialog';
import { Resource } from '../common/Resource';
import { UppyDashboard } from '../common/UppyDashboard';


const useStyles = makeStyles(theme => ({
    wrapper: {
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1)
    },
    root: {
        marginTop: 20,
        padding: '.25em 1em',
        backgroundColor: theme.palette.background.paper
    },
    media: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 120
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)'
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    wrapperSearchAndFormGroup: {
        display: 'flex'
    },
    searchTextfield: {
        width: 400,
        marginBottom: 15,
        marginRight: 40
    },
    button: {
        marginTop: 10
    },
    searchInput: {
        borderRadius: 8,
        width: '100%',
        height: 48,
        [theme.breakpoints.down('md')]: {
            width: 250,
        },
    },
    paginator: {
        padding: 10
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />;
});

let timer = null;


export const MediaGallery = () => {
    const classes = useStyles(),
        theme = useTheme(),
        dispatch = useDispatch(),
        { t } = useTranslation(['dm.media'], { useSuspense: false }),
        { ownerUniqueId } = useSelector(state => state.app),
        { data, fetching, countFound } = useSelector(state => state.resources),
        [selectedTile, setSelectedTile] = useState(null),
        [confirmOpen, setConfirmOpen] = useState(false),
        [isEditDialogOpen, setIsEditDialogOpen] = useState(false),
        [isUppyOpen, setIsUppyOpen] = useState(false),
        lg = useMediaQuery(theme.breakpoints.up('lg')),
        sm = useMediaQuery(theme.breakpoints.down('md')),
        columns = lg ? 6 : (sm ? 2 : 4),
        pageRef = useRef(),
        [q, setQ] = useState(''),
        [searchTerm, setSearchTerm] = useState(''),
        [mediaType, setMediaType] = useState('media'),
        [page, setPage] = useState(1),
        [noOfPages, setNoOfPages] = useState(0),
        itemsPerPage = 24;

    useEffect(() => {
        dispatch(setFinderMode('gallery'));
        dispatch(setExportEnabled(false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(requestResources(ownerUniqueId, searchTerm, itemsPerPage, (page - 1) * itemsPerPage, mediaType));
    }, [dispatch, ownerUniqueId, searchTerm, mediaType, itemsPerPage, page]);

    useEffect(() => {
        setPage(1);
    }, [mediaType]);

    useEffect(() => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setSearchTerm(q);
            setPage(1);
        }, 500);
    }, [q]);

    useEffect(() => {
        setNoOfPages(Math.ceil(countFound / itemsPerPage));
    }, [countFound]);

    const onFormChanged = form => {
        pageRef.current = form.formData;
    };

    const onSave = () => {
        dispatch(updateResources(ownerUniqueId, pageRef.current, selectedTile));
        setIsEditDialogOpen(false);
    };

    const handleUppyDashboardClose = e => {
        setIsUppyOpen(false);
    };

    const uppyUpload = () => {
        setIsUppyOpen(true);
    };

    const editDialogOpen = tile => {
        setIsEditDialogOpen(true);
        setSelectedTile(tile);
    };

    const editDialogClose = () => {
        setIsEditDialogOpen(false);
    };

    const onDeleteOpen = (e, tile) => {
        e.stopPropagation();
        setConfirmOpen(true);
        setSelectedTile(tile);
    }

    const onDeleteConfirm = () => {
        dispatch(deleteResource(ownerUniqueId, selectedTile.id));
        setConfirmOpen(false);
        setSelectedTile();
    }

    const handleChangeRadio = (event) => {
        setMediaType(event.target.value);
    };

    const handleChangePagination = (e, value) => {
        setPage(value);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.wrapperSearchAndFormGroup}>
                <div className={classes.searchTextfield}>
                    <TextField fullWidth id='standard-basic' placeholder='Søk etter media med fritekst og stikkord'
                        variant='outlined'
                        value={q}
                        onChange={e => setQ(e.target.value)}
                        InputProps={{
                            className: classes.searchInput,
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon color='primary' />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={mediaType === 'media'}
                                    onChange={handleChangeRadio}
                                    name='all'
                                    color='primary'
                                    value='media'
                                />
                            }
                            label={t('mediaType_all', 'Alle')}
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={mediaType === 'image'}
                                    onChange={handleChangeRadio}
                                    name='image'
                                    color='primary'
                                    value='image'
                                />
                            }
                            label={t('mediaType_images', 'Bilder')}
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={mediaType === 'audio'}
                                    onChange={handleChangeRadio}
                                    name='audio'
                                    color='primary'
                                    value='audio'
                                />
                            }
                            label={t('mediaType_audio', 'Audio')}
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={mediaType === 'video'}
                                    onChange={handleChangeRadio}
                                    name='video'
                                    color='primary'
                                    value='video'
                                />
                            }
                            label={t('mediaType_video', 'Video')}
                        />
                    </FormGroup>
                </div>
            </div>
            <Input
                id='file-upload'
                type='button'
                onClick={uppyUpload}
                sx={{ display: 'none' }}
            />
            <label htmlFor='file-upload'>
                <Button variant='contained' startIcon={<CloudUploadIcon />} component='span' sx={{ margin: '0 1em 1em 0' }}>
                    {t('upload_file', 'Last opp media')}
                </Button>
            </label>
            {(fetching || (!data?.resources))
                ? <ProgressIndicator />
                : (
                    <div className={classes.root}>
                        <h2>{`${(countFound) || 0} ${t('resource-hits', 'treff i egne medier')}`}</h2>
                        <ImageList cols={countFound < columns ? countFound : columns}>
                            {(data?.resources) && data.resources.map(tile => (
                                <ImageListItem onClick={() => editDialogOpen(tile)} key={tile?.id} sx={{ width: 'auto' }}>
                                    <div className={classes.media}>
                                        <Resource media={tile} />
                                    </div>
                                    <ImageListItemBar
                                        title={tile?.filename}
                                        subtitle={<span>{t('owner', 'eier')}: {tile?.owner_identifier && tile.owner_identifier}</span>}
                                        actionIcon={
                                            <IconButton
                                                aria-label={`info about ${tile?.filename}`}
                                                className={classes.icon}
                                                value={tile?.id}
                                                onClick={e => onDeleteOpen(e, tile)}
                                                size='large'>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        }
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                        <Dialog
                            open={isEditDialogOpen}
                            onClose={editDialogClose}
                            TransitionComponent={Transition}
                            color='primary'
                        >
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <Typography variant='h6' className={classes.title}>
                                        {selectedTile?.filename}
                                    </Typography>
                                    <IconButton
                                        edge='start'
                                        color='inherit'
                                        onClick={editDialogClose}
                                        aria-label='close'
                                        size='large'>
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box m={3}>
                                <Form
                                    schema={imageFileSchema}
                                    uiSchema={imageFileUiSchema}
                                    formData={selectedTile?.data}
                                    onChange={onFormChanged}
                                >
                                    <Button type='submit' variant='contained' color='primary' className={classes.button}
                                        onClick={onSave}>{t('save', 'Lagre')}</Button>
                                </Form>
                            </Box>
                        </Dialog>
                        {(countFound > itemsPerPage) && (
                            <>
                                <Divider />
                                <Box component='span'>
                                    <Pagination
                                        count={noOfPages}
                                        page={page}
                                        onChange={handleChangePagination}
                                        defaultPage={1}
                                        color='primary'
                                        size='large'
                                        showFirstButton
                                        showLastButton
                                        classes={{ ul: classes.paginator }}
                                    />
                                </Box>
                            </>
                        )}
                    </div>
                )}
            {selectedTile?.used_in_pages && selectedTile.used_in_pages.length
                ? (
                    <ConfirmDialog
                        title={t('existing_references_title', 'Det finnes lenker til dette innholdet!')}
                        cancelLabel='Ok'
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                    >
                        <Typography>{t('remove_references', 'Vennligst fjern referanse i disse artiklene før du sletter:')}</Typography>
                        <List disablePadding={true}>
                            {selectedTile.used_in_pages.map(ref => (
                                <ListItem key={ref.page_id} disableGutters={true} dense={true}>
                                    <Link href={`/article/edit/${ref.page_id}?museum_id=${ownerUniqueId}`} target='_blank' rel='noreferrer'>
                                        {ref.title || ref.page_id}
                                    </Link>
                                </ListItem>
                            ))}
                        </List>
                    </ConfirmDialog>
                ) : (
                    <ConfirmDialog
                        title='Slett medie'
                        confirmLabel='Ja'
                        cancelLabel='Nei'
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={onDeleteConfirm}
                    >
                        {t('confirm_delete', 'Er du sikker?')}
                    </ConfirmDialog>
                )}
            {isUppyOpen && <UppyDashboard closeHandler={handleUppyDashboardClose} />}
        </div>
    );
};
