import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Typography } from '@mui/material';

import {WorldMap} from '@ekultur/svgmap';

import { requestStatistics } from '../redux/actions/statistics';
import { setTimePeriod } from '../redux/functions/statistics';
import { getValueFromQueryString } from '../redux/functions/query';

import { WithProgressOverlay } from '../common/WithProgressOverlay';
import { CountriesBar } from './CountriesBar';
import { countryName } from './countries/countryNames';

const useStyles = makeStyles(theme => ({
    root: {
        height: 500,
        padding: theme.spacing(2),
        overflow: 'hidden',
        '& h6': {
            marginBottom: 20
        },
        '& text': {
            fontSize: '.9rem'
        }
    },
    content: {
        width: '99%',
        '& div:not(:first-child)': {
            marginTop: 30
        }
    },
    worldMap: {
        height: '300px',
        maxWidth: '460px',
        margin: 'auto'
    }
}));

export const StatisticsReportLocation = ({ viewType }) => {
    const classes = useStyles(),
        dispatch = useDispatch(),
        { t, ready } = useTranslation('googleAnalytics', { useSuspense: false }),
        period = getValueFromQueryString('period'),
        { reports } = useSelector(state => state.gaStatistics),
        location = reports && reports[viewType].location,
        { rows } = location,
        data = rows.map(row => ({
            id: row.dimensions[1].toLowerCase(),
            label: countryName(row.dimensions[1]),
            value: parseInt(row.metrics[0].values[0])
        })),
        dataMax = location.maximums[0] ? parseInt(location.maximums[0].values[0]) : 0,
        top5 = data.sort((a, b) => b.value - a.value).slice(0, 5),
        { ownerUniqueId } = useSelector(state => state.app),
        { filters, currentDetail } = useSelector(state => state.layout),
        timePeriod = setTimePeriod(filters, currentDetail, period, false, true);

    useEffect(() => {
        if (timePeriod.start && timePeriod.end) {
            dispatch(requestStatistics(ownerUniqueId, timePeriod.start, timePeriod.end, 'location', viewType, 0,
                'country,countryIsoCode', 'uniquePageviews', 'uniquePageviews', 'descending', false, null, 300));
        }
    }, [dispatch, ownerUniqueId, timePeriod.start, timePeriod.end, viewType]);

    return (
        <WithProgressOverlay loading={location.fetching || !ready}>
            <Paper className={classes.root}>
                <Typography variant="h6" align="center">
                    {t('pageViewsByCountry', 'Sidevisninger fordelt på land')}
                </Typography>
                <div className={classes.content}>
                    <div className={classes.worldMap}>
                        <WorldMap data={data} lowRes={true} />
                    </div>
                    <CountriesBar data={top5} dataMax={dataMax} t={t} />
                </div>
            </Paper>
        </WithProgressOverlay>
    );
};
