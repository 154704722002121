import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { requestOwnerFeatures } from '../redux/actions/features';

export const Features = ({ children }) => {
    const dispatch = useDispatch(),
        { ownerUniqueId } = useSelector(state => state.app);

    useEffect(() => {
        if (ownerUniqueId) {
            dispatch(requestOwnerFeatures(ownerUniqueId));
        }
    }, [dispatch, ownerUniqueId]);

    return <>{children}</>;
};
