import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserData } from '../redux/actions/user';

export const WithUserdata = ({ children }) => {
    const { userIsAuthenticated, userData } = useSelector(state => state.user),
        dispatch = useDispatch();

    useEffect(() => {
        if (userIsAuthenticated) {
            dispatch(getUserData());
        }
    }, [dispatch, userIsAuthenticated]);

    if (Object.keys(userData).length) {
        return <>{children}</>;
    } else {
        return null;
    }
};
