import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { baseSchema, articleSchema, buildUiSchema } from './schema';

import { ProgressIndicator } from '../common/ProgressIndicator';
import { Preview } from './preview/Preview';
import { PageLoader } from './PageLoader';

export const Article = () => {
    const { activeMuseum } = useSelector(state => state.user),
        [themeColors, setThemeColors] = useState(),
        [uiSchema, setUiSchema] = useState(),
        [schema, setSchema] = useState(baseSchema);

    useEffect(() => {
        if (themeColors) {
            console.log('initializing Article');
            setSchema(s => {
                s.properties.content.properties = articleSchema;
                return s;
            });

            setUiSchema(buildUiSchema(themeColors));
        }
    }, [themeColors]);

    useEffect(() => {
        let clrs = [];

        if (activeMuseum.primary_color) {
            clrs.push(activeMuseum.primary_color);
        }

        if (activeMuseum.dark_color) {
            clrs.push(activeMuseum.dark_color);
        }

        if (activeMuseum.light_color) {
            clrs.push(activeMuseum.light_color);
        }

        setThemeColors(clrs);
    }, [activeMuseum.primary_color, activeMuseum.dark_color, activeMuseum.light_color]);
    
    return (
        <>
            {schema && uiSchema ? (
                <PageLoader
                    schema={schema}
                    uiSchema={uiSchema}
                    Preview={Preview}
                    pageType='article'
                    editUrl='article'
                />
            ) : (
                <ProgressIndicator />
            )}
        </>
    );
};
