import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';

export const MuseumHeader = ({ museum, blocks, activeSection, setActiveSection }) => {
    const { t } = useTranslation(['dm.menu'], { useSuspense: false }),
        headerBlock = blocks.find(b => b.type === 'museum_name'),
        { name, customName, override } = headerBlock,
        navigationItems = [
            { id: 'museum_about', title: t('museum_about', 'Om') },
            { id: 'feed', title: t('museum_feed', 'Nyhetsfeed') },
            { id: 'museum_trending', title: t('museum_trending', 'Aktuelt') },
            // { id: 'museum_calendar', title: t('museum_calendar', 'Kalender') },
            { id: 'museum_collections', title: t('museum_collections', 'Samlinger') }  
        ];

    return (
        <header id='@section_0.blocks_0' className='museum-head'>
            <div className='museum-head__wrapper'>
                {museum && museum.profile && (
                    <div className='museum-avatar museum-avatar--large'>
                        <img src={`https://${museum.profile}`} alt='avatar' />
                    </div>
                )}
                <h1>{override ? customName : name}</h1>
            </div>
            <nav id='@section_0.ignore' className='museum-nav'>
                <ul>
                    {navigationItems.map(n => {
                        return (
                            <li key={n.title} className={`${n.id === activeSection ? 'active' : ''}`}>
                                <Link color='inherit' sx={{ cursor: 'pointer' }} onClick={() => setActiveSection(n.id)}>{n.title}</Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </header>
    );
};
