import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Paper } from '@mui/material';
// import TrendingDownIcon from '@mui/icons-material/TrendingDown';
// import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import { secondsToMinutesSeconds } from './time/timeFormatter';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    // trendingUp: {
    //     color: theme.palette.success.main
    // },
    // trendingDown: {
    //     color: theme.palette.error.main
    // }
}));

// const percentageChanged = (now, previous) => ((now - previous) / previous) * 100;

// const trendingIcon = (now, previous) => now > previous
//     ? <TrendingUpIcon fontSize={'small'} />
//     : <TrendingDownIcon fontSize={'small'}/>;

// const trendingClass = (now, previous, classes) => now >= previous ? classes.trendingUp : classes.trendingDown;


export const MetricsTooltip = ({ parser, dimension, period, label, payload, t }) => {
    const classes = useStyles();

    if (payload && payload.length) {
        const currentValue = payload[0].payload.type !== 'avgTimeOnPage' ? parseInt(payload[0].value) : secondsToMinutesSeconds(payload[0].value);
        // const previousValue = parseInt(payload[1].value);
        return (
            <Paper className={classes.root} elevation={5}>
                <Box fontWeight={'fontWeightLight'}>
                    {parser(period, dimension !== 'nthWeek' ? dimension : 'nthDay', label, t)}
                    {/* vs {parseDimension(previousPeriod, dimension, label)} */}
                </Box>
                <Box fontWeight={'fontWeightBold'}>
                    {currentValue}
                </Box>
                {/* <Box className={trendingClass(currentValue, previousValue, classes)}>
                    {trendingIcon(currentValue, previousValue)}
                    {percentageChanged(currentValue, previousValue).toFixed(2)}%
                </Box> */}
            </Paper>
        )
    } else {
        return null;
    }
};
