import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setUserAccess } from '../redux/actions/user';
import { Unauthorized } from '../common/Unauthorized';

export const UserAccess = ({ children }) => {
    const dispatch = useDispatch(),
        userAccess = useSelector(state => state.userAccess),
        { ownerUniqueId } = useSelector(state => state.app),
        { userData } = useSelector(state => state.user),
        museum = userData.appAccess.museums.find(m => m.id === ownerUniqueId),
        dmApp = museum && museum.applications.find(a => a.id === '2cdc3799-bbc1-4a46-9529-2bdd7f3e0694'),
        role = dmApp && dmApp.role.name,
        location = useLocation(),
        pathParts = location.pathname.split('/'),
        basePath = pathParts[1] === '' ? 'root' : pathParts[1],
        hasAccess = userAccess[basePath];

    useEffect(() => {
        if (role) {
            dispatch(setUserAccess({
                root: ['Admin', 'Editor', 'User'].includes(role),
                start: ['Admin', 'Editor', 'User'].includes(role),
                statistics: ['Admin', 'Editor', 'User'].includes(role),
                inbox: ['Admin', 'Editor'].includes(role),
                article: ['Admin', 'Editor'].includes(role),
                folders: ['Admin', 'Editor'].includes(role),
                folder: ['Admin', 'Editor'].includes(role),
                homepage: ['Admin', 'Editor'].includes(role),
                museumpage: ['Admin', 'Editor'].includes(role),
                gallery: ['Admin', 'Editor'].includes(role)
            }));
        
        }
    }, [dispatch, role]);

    return hasAccess ? <>{children}</> : <Unauthorized />;
};
