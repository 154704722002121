import {
    ARTICLES_REQUESTED, ARTICLES_SUCCEEDED,
    ARTICLE_DELETED, ARTICLES_FAILED
} from '../actions/article';

const initialState = {
    owner: null,
    rows: null,
    countFound: 0,
    fetching: null,
    error: false
}

const articleList = (state = initialState, action) => {
    switch (action.type) {
        case ARTICLES_REQUESTED:
            return {
                ...state,
                rows: null,
                fetching: true,
                error: false
            };
        case ARTICLES_SUCCEEDED:
            return {
                rows: action.data,
                countFound: action.countFound,
                owner: action.owner,
                fetching: false,
                error: false
            };
        case ARTICLE_DELETED:
            return {
                ...state,
                rows: state.rows.filter(x => x.id !== action.data)
            };
        case ARTICLES_FAILED:
            return {
                ...state,
                rows: null,
                fetching: false,
                owner: action.owner,
                error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default articleList;
