import {
    MASHUPS_FAILED,
    MASHUPS_REQUESTED,
    MASHUPS_SUCCEEDED,
    ARTIFACT_MASHUPS_REQUESTED,
    ARTIFACT_MASHUPS_FAILED,
    ARTIFACT_MASHUPS_SUCCEEDED,
    // MASHUP_SELECTED,
    MASHUP_UPDATE_REQUESTED,
    MASHUP_UPDATE_SUCCEEDED,
    MASHUP_UPDATE_FAILED,
    MASHUP_DELETE_REQUESTED,
    MASHUP_DELETE_SUCCEEDED,
    MASHUP_DELETE_FAILED,
    MASHUP_CHANGE
} from '../types';

const mashupSet = {
    owner: '',
    rowCount: 0,
    start: 0,
    rows: [],
    reportType: 0,
    // groupBy: null,
    fetching: false,
    fetchedAt: null,
    error: false,
    selected: null,
    message: null
};

const defaultState = {
    all: mashupSet,
    comment: mashupSet,
    inquiry: mashupSet,
    active: {
        fetching: false,
        artifact: null,
        error: false
    }
};


export const mashups = (state = defaultState, action) => {
    let draft = { ...state };

    switch (action.type) {
        case MASHUPS_REQUESTED:
            draft[action.mashupType].fetching = true;
            break;
        case MASHUPS_FAILED:
            draft[action.mashupType] = {
                ...draft[action.mashupType],
                error: true,
                data: action.data,
                fetching: false,
                fetchedAt: Math.floor(Date.now() / 1000)
            }
            break;
        case MASHUPS_SUCCEEDED:
            let rows = [...action.data];

            draft[action.mashupType] = {
                ...draft[action.mashupType],
                rows: rows.map(a => setArtifactLabel(a, action.t)).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
                rowCount: rows.length,
                selected: null,
                message: null,
                error: false,
                fetching: false,
                fetchedAt: Math.floor(Date.now() / 1000)
            };

            /* if (action.mashupType && action.mashupType !== 'all') {
                draft.groupBy = 'documentType';
            } */

            break;
        case ARTIFACT_MASHUPS_REQUESTED:
            draft.active.fetching = true;
            draft.active.error = false;
            break;
        case ARTIFACT_MASHUPS_SUCCEEDED:
            draft.active.artifact = {
                ...action.data[0],
                messages: action.data[0].messages.map((m, i) => SetArtifactMashupLabel(m, action.t))
            };
            draft.active.fetching = false;
            draft.active.error = false;
            break;
        case ARTIFACT_MASHUPS_FAILED:
            draft.active.fetching = false;
            draft.active.error = true;
            break;
        /* case MASHUP_SELECTED:
            draft[action.mashupType].selected = action.id;
            break; */
        case MASHUP_UPDATE_REQUESTED:
            console.log(`MASHUP_UPDATE_REQUESTED ${action.id}`);
            break;
        case MASHUP_UPDATE_SUCCEEDED:
            console.log(`MASHUP_UPDATE_SUCCEEDED ${action.data.uniqueId}`);
            draft.active.artifact = updateArtifactMashups({ ...draft.active.artifact }, action);
            break;
        case MASHUP_UPDATE_FAILED:
            console.log(`MASHUP_UPDATE_FAILED ${action.data.message}`);
            break;
        case MASHUP_DELETE_REQUESTED:
            console.log(`MASHUP_DELETE_REQUESTED ${action.id}`);
            break;
        case MASHUP_DELETE_SUCCEEDED:
            console.log(`MASHUP_DELETE_SUCCEEDED ${action.id}`);
            const update = updateArtifactMashups({ ...draft.active.artifact }, action);
            draft.active.artifact = update;
            break;
        case MASHUP_DELETE_FAILED:
            console.log(`MASHUP_DELETE_FAILED ${action.uniqueId}`);
            break;
        case MASHUP_CHANGE:
            const artifact_idx = findModifiedArtifact(draft[action.mashupType].rows, draft[action.mashupType].selected);
            draft[action.mashupType].rows[artifact_idx].comments = action.mashups;
            break;
        default:
            break;
    }

    return draft;
};

const findModifiedArtifact = (rows, selected) => {
    return rows.findIndex(a => a.uniqueId === selected);
};

const findModifiedMashup = (mashups, mashId) => {
    return mashups.findIndex(m => parseInt(m.uniqueId) === mashId);
};

/* const updateMashup = (rows, action) => {
    const art_idx = findModifiedArtifact(rows, action.data.dimuCode),
        mashup_idx = findModifiedMashup(
            rows[art_idx].messages, action.new ? 'new' : action.data.uniqueId);

    rows[art_idx].messages[mashup_idx] = action.data;

    return rows;
}; */

const updateArtifactMashups = (artifact, action) => {
    if (action.new) {
        artifact.messages.push(action.data);
        artifact.messages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (action.deleted) {
        const mashup_idx = findModifiedMashup(artifact.messages, action.uniqueId);
        artifact.messages.splice(mashup_idx, 1);
    } else {
        const mashup_idx = findModifiedMashup(artifact.messages, action.data.uniqueId);
        artifact.messages[mashup_idx] = action.data;
    }

    return artifact;
};

const SetArtifactMashupLabel = (mashup, t) => {
    try {
        mashup.label = t(`${mashup.documentType}`, mashup.documentType);
    } catch (e) {
        mashup.label = mashup.documentType;
    }
    return mashup;
};

const setArtifactLabel = (artifact, t) => {
    try {
        artifact.label = t(artifact.messages[0].documentType, artifact.messages[0].documentType);
    } catch (e) {
        artifact.label = ''
    }
    
    return artifact;
};
