import { Trans } from 'react-i18next';

export const museumNameBlock = {
    type: 'object',
    required: ['type', 'override'],
    title: <Trans ns="dm.article" i18nKey="owner.label_title">Navn</Trans>,
    // description: 'Feltet oppdateres automatisk fra KulturNav. Du kan overstyre ved å legge inn egendefinert navn. Oppdatering vil da stoppe.',
    properties: {
        type: { enum: ['museum_name'] },
        name: {
            type: 'string',
            title: 'Navn fra KulturNav',
            readOnly: true
        },
        override: {
            type: 'boolean',
            title: 'Egendefinert navn',
            default: false
        },
        _editor: { '$ref': '#/definitions/_editor' }
    },
    dependencies: {
        override: {
            oneOf: [{
                properties: {
                    override: {
                        enum: [true],
                    },
                    customName: {
                        type: 'string',
                        title: <Trans ns="dm.article" i18nKey="owner.label_title">Navn</Trans>
                    }
                },
                required: ['customName']
            },
            {
                properties: {
                    override: {
                        enum: [false],
                    }
                }
            }]
        }
    }
};
