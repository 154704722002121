import React from 'react';
import { Trans } from 'react-i18next';

export const headerBlockSettings = {
    type: 'object',
    properties: {
        color: {
            type: 'string',
            enum: [
                'skin-light',
                'skin-dark'
            ],
            enumNames: [
                <Trans ns="dm.article" i18nKey="headerblock_dark">Mørk tekst</Trans>,
                <Trans ns="dm.article" i18nKey="headerblock_light">Lys tekst</Trans>,
            ],
            default: 'skin-dark'
        }
    }
};
