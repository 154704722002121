import svCountries from 'localized-countries/data/sv'
import nbCountries from 'localized-countries/data/nb'
import nnCountries from 'localized-countries/data/nn'
import enCountries from 'localized-countries/data/en'
import localizedCountries from 'localized-countries';
import { getLocale } from '../locale/locale';


const localizedCountry = () => {
    const lng = getLocale();

    if ('sv' === lng.code) {
        return svCountries;
    } else if (['no', 'nb'].includes(lng.code)) {
        return nbCountries;
    } else if ('nn' === lng.code) {
        return nnCountries;
    } else {
        return enCountries;
    }
};

export const countryName = countryCode => {
    return localizedCountries(localizedCountry()).get(countryCode)
};
