import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { kitFetch } from '@ekultur/fetch';

import { MUSEUMS_RECEIVED } from '../redux/actions/user';


export const WithMuseums = ({ children }) => {
    const dispatch = useDispatch(),
        { museumData } = useSelector(state => state.user);

    useEffect(() => {
        kitFetch('/museum-api/museums')
            .then(json => dispatch({
                type: MUSEUMS_RECEIVED,
                museums: json.museums
            }));
    }, [dispatch]);

    if (museumData.length) {
        return <>{children}</>;
    } else {
        return null;
    }
};
