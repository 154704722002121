import React from 'react';
import { Trans } from 'react-i18next';

export const footerSection = {
    type: 'object',
    title: <Trans ns="dm.article" i18nKey="section_footer">Footer</Trans>,
    properties: {
        type: { enum: ['footer_section'] },
        _editor: {
            '$ref': '#/definitions/_editor'
        },
        blocks: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
            title: null,
            items: {
                type: 'object',
                title: <Trans ns="dm.article" i18nKey="owner.footerblock_title">Koordinater</Trans>,
                properties: {
                    type: { type: 'string' },
                    title: { type: 'string' },
                    latitude: { type: 'integer' },
                    longitude: { type: 'integer' },
                    _editor: {
                        '$ref': '#/definitions/_editor'
                    }
                }
            }
        }
    }
};
