import { Card } from './Card/Card';

customElements.define('kit-vm-card', class VmCard extends HTMLElement {
    constructor() {
        super();
    }

    get width() {
        return `${this.getAttribute('width') || 352}px`;
    }

    get height() {
        return `${this.getAttribute('height') || 456}px`;
    }

    get backgroundColor() {
        return this.getAttribute('backgroundColor') || '#82ABA0';
    }

    get imgSrc() {
        return this.getAttribute('imgSrc');
    }

    get museum() {
        return this.getAttribute('museum');
    }

    get tags() {
        return this.getAttribute('tags').split(',');
    }

    get buttonLabel() {
        return this.getAttribute('buttonLabel') || 'Opplev utstillingen';
    }

    get paragraph() {
        return this.getAttribute('paragraph');
    }

    get href() {
        return this.getAttribute('href');
    }

    onClick = () => {
        if (this.href) {
            window.location.href = this.href;
        }
    }

    connectedCallback() {
        console.log('KIT-VmCard @connectedCallback');
        this.ready();
    }

    /**
     * Ensure DOM elements are ready before proceeding
     */
    ready = () => {
        this.appendChild(Card({
            width: this.width,
            height: this.height,
            backgroundColor: this.backgroundColor,
            imgSrc: this.imgSrc,
            title: this.title,
            museum: this.museum,
            tags: this.tags,
            buttonLabel: this.buttonLabel,
            paragraph: this.paragraph,
            onClick: this.onClick
        }));
    };
});
