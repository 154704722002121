import React, { useState, useEffect } from 'react';

import { baseSchema } from './schema';
import { museumSchema } from './schema/museum/museumSchema';
import { buildUiSchema } from './schema/museum/museumUiSchema';

import { ProgressIndicator } from '../common/ProgressIndicator';
import { MuseumPreview } from './museumPreview/Preview';
import { PageLoader } from './PageLoader';

export const MuseumPage = () => {
    const [uiSchema, setUiSchema] = useState(),
        [schema, setSchema] = useState(baseSchema);

    useEffect(() => {
        console.log('initializing MuseumPage');
        setSchema(s => {
            s.properties.content.properties = museumSchema;
            return s;
        });

        setUiSchema(buildUiSchema());
    }, []);

    return (
        <>
            {schema && uiSchema ? (
                <PageLoader
                    schema={schema}
                    uiSchema={uiSchema}
                    Preview={MuseumPreview}
                    pageType='museum'
                    editUrl='museumpage'
                />
            ) : (
                <ProgressIndicator />
            )}
        </>
    );
};
