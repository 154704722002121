import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';

const useStyles = makeStyles({
    heading: props => ({
        fontSize: props.heading.fontSize
    }),
    chip: props => ({
        marginBottom: props.chip.marginBottom
    }),
    labelSmall: props => ({
        overflow: props.labelSmall.overflow
    })
});

export const TextInfoCardContent = ({
    classes = { overline: {}, heading: {}, body: {}, type: {} },
    overline = null,
    heading = null,
    body = null,
    type = null,
    overlineProps = {},
    headingProps = {},
    bodyProps = {}
}) => {
    const css = useStyles(classes);

    return (
        <>
            {type && <><Chip size="small" label={type} className={css.chip} classes={{labelSmall: css.labelSmall}} /></>}
            {overline && (
                <Typography
                    component={'span'}
                    {...overlineProps}
                    // className={css.overline}
                >
                    {overline}
                </Typography>
            )}
            <Typography
                component={'h4'}
                {...headingProps}
                className={css.heading}
            >
                {heading}
            </Typography>
            {body && (
                <Typography {...bodyProps} classes={{body: classes.body}}>
                    {body}
                </Typography>
            )}
        </>
    );
};
