import React, { useMemo } from 'react';
import SimpleMDE from 'react-simplemde-editor';
// import ReactMde from 'react-mde';

import "easymde/dist/easymde.min.css";
// import 'react-mde/lib/styles/css/react-mde-all.css';

export const MarkdownWidget = ({ value, onChange }) => {
    const autofocusNoSpellcheckerOptions = useMemo(() => {
        return {
            autofocus: true,
            spellChecker: false,
            toolbar: [
                'heading-2', 'heading-3', 'bold', 'italic', 'quote', '|',
                'unordered-list', 'ordered-list', '|',
                'link', 'image', '|',
                'guide'
            ]
        };
    }, []);

    const handleChange = value => {
        onChange && onChange(value);
    };

    return <SimpleMDE value={value} onChange={handleChange} options={autofocusNoSpellcheckerOptions} />
};
