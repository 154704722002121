export function setTimePeriod(filter, currentPeriod, period = null, compare = false, convertQuarters = false) {
    let fltr = null;

    if (period && filter) {
        fltr = {
            ...filter.find(f => f.value === period)
        };
    } else if (currentPeriod) {
        fltr = {
            ...filter.find(f => f.value === currentPeriod)
        };
    }

    if (!fltr) {
        fltr = {
            start: compare ? 'today,yesterday' : 'today',
            end: compare ? 'today,yesterday' : 'today',
            orderBy: 'nthHour',
            value: 'today'
        };
    } else if (!compare && fltr.hasOwnProperty('start') && fltr.hasOwnProperty('end')) {
        fltr.start = fltr.start.split(',')[0];
        fltr.end = fltr.end.split(',')[0];
    }

    if (fltr && fltr.timePeriod && convertQuarters) {
        const year = new Date().getFullYear();

        if (fltr.timePeriod === 'q1') {
            fltr.start = `${year}-01-01${compare ? ',' + (year - 1) + '-01-01' : ''}`;
            fltr.end = `${year}-03-31${compare ? ',' + (year - 1) + '-03-31' : ''}`;
        } else if (fltr.timePeriod === 'q2') {
            fltr.start = `${year}-04-01${compare ? ',' + (year - 1) + '-04-01' : ''}`;
            fltr.end = `${year}-06-30${compare ? ',' + (year - 1) + '-06-30' : ''}`;
        } else if (fltr.timePeriod === 'q3') {
            fltr.start = `${year}-07-01${compare ? ',' + (year - 1) + '-07-01' : ''}`;
            fltr.end = `${year}-09-30${compare ? ',' + (year - 1) + '-09-30' : ''}`;
        } else if (fltr.timePeriod === 'q4') {
            fltr.start = `${year}-10-01${compare ? ',' + (year - 1) + '-10-01' : ''}`;
            fltr.end = `${year}-12-31${compare ? ',' + (year - 1) + '-12-31' : ''}`;
        } else if (fltr.timePeriod === 'year') {
            fltr.start = `${year}-01-01${compare ? ',' + (year - 1) + '-01-01' : ''}`;
            fltr.end = `${year}-12-31${compare ? ',' + (year - 1) + '-12-31' : ''}`;
        }
    }

    return fltr;
}
