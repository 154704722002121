import { ownerHeaderSection } from '../header/ownerHeaderSection';
import { bodySection } from '../body/bodySection';
import { footerSection } from '../footer/footerSection';

export const ownerSections = {
    type: 'object',
    required: ['type'],
    properties: {
        type: {
            type: 'string',
            readOnly: true,
            title: null,
            enum: [
                'article__head',
                'article__body',
                'footer_section'
            ],
            enumNames: [
                'Head',
                'Body',
                'Footer'
            ],
            default: 'article__body'
        }
    },
    dependencies: {
        type: {
            oneOf: [
                ownerHeaderSection,
                bodySection('owner'),
                footerSection
            ]
        }
    }
};
