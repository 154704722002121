import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { setFinderMode, setExportEnabled, setInboxFilter } from '../redux/actions/layout';
import { requestMashups } from '../redux/actions/mashup';
import { getValueFromQueryString, getQueryProps, buildQueryString } from '../redux/functions/query';
import { setTimePeriod } from '../redux/functions/statistics';

import { ProgressIndicator } from '../common/ProgressIndicator';
import { MashupInboxCard } from './MashupInboxCard';
import { NoData } from '../common/NoData';
import { Error } from '../common/Error';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        '& > li': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundClip: 'padding-box'
        }
    }
}));


export const MashupInbox = () => {
    const dispatch = useDispatch(),
        classes = useStyles(),
        { t, ready } = useTranslation('dm.mashupTypes', 'dm.common', { useSuspense: false }),
        mashupType = useParams().mashupType || 'all',
        location = useLocation(),
        navigate = useNavigate(),
        { ownerUniqueId } = useSelector(state => state.app),
        { filters, currentDetail, currentInboxFilter } = useSelector(state => state.layout),
        { error, fetching, rows } = useSelector(state => state.mashups[mashupType]),
        period = getValueFromQueryString('period'),
        qsStart = getValueFromQueryString('start'),
        status = getValueFromQueryString('status'),
        [paging, setPaging] = useState({ rows: 10, page: qsStart || 0 }),
        timePeriod = setTimePeriod(filters, currentDetail, period, false, true);

    useEffect(() => {
        dispatch(setFinderMode(mashupType, period));
        dispatch(setExportEnabled(false));
    }, [dispatch, period, mashupType]);

    useEffect(() => {
        dispatch(setInboxFilter(status));
    }, [dispatch, status]);

    useEffect(() => {
        if (mashupType) {
            if (timePeriod.value === 'custom' && (!timePeriod.start || !timePeriod.end)) {
                return;
            } else if (timePeriod.timePeriod) {
                delete timePeriod.start;
                delete timePeriod.end;
            }

            dispatch(requestMashups(t, ownerUniqueId, mashupType, currentInboxFilter, paging.rows, paging.page * paging.rows,
                null, timePeriod.timePeriod, timePeriod.start, timePeriod.end));
        }
    }, [dispatch, t, mashupType, ownerUniqueId, currentInboxFilter, paging.page, paging.rows, timePeriod.value,
        timePeriod.timePeriod, timePeriod.start, timePeriod.end]);

    const onSelect = id => {
        navigate(`/inbox/details/${id}`);
    };

    const onPaging = start => {
        const query = Object.assign(getQueryProps(location.search), { start: start }),
            newPath = location.pathname + buildQueryString(query);

        setPaging({
            ...paging,
            page: start
        });

        navigate(newPath);
    };

    const renderItem = (item, index) => {
        return (
            <MashupInboxCard artifact={item} onSelect={onSelect} key={`${index}`} />
        )
    };

    const renderPrevBtn = () => {
        return <Button onClick={() => onPaging(Math.max(paging.page -1, 0))}>{t('dm.common:Previous', 'Forrige')}</Button>;
    };

    if (error) {
        return <Error />;
    }
    else if (!ready || fetching) {
        return <ProgressIndicator />;
    } else if (!rows.length) {
        return (
            <>
                <NoData />
                {paging.page > 0 && renderPrevBtn()}
            </>
        );
    }

    return (
        <>
            <List className={classes.root}>
                {rows.map(renderItem)}
            </List>
            {paging.page > 0 && renderPrevBtn()}
            {paging.rows === rows.length && (
                <Button onClick={() => onPaging(paging.page + 1)}>{t('dm.common:Next', 'Neste')}</Button>
            )}
        </>
    );
};
