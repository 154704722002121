import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

// import { getValueFromQueryString } from '../redux/functions/query';
import { setFinderMode, setExportEnabled } from '../redux/actions/layout';

import {
    StatisticsReportMetrics,
    StatisticsReportLocation,
    StatisticsReportAcquisition,
    StatisticsReportObjectTypes
} from '../Statistics/plausible'

import {
    StatisticsReportMetrics as GAStatisticsReportMetrics,
    StatisticsReportLocation as GAStatisticsReportLocation,
    StatisticsReportAcquisition as GAStatisticsReportAcquisition,
    StatisticsReportObjectTypes as GAStatisticsReportObjectTypes
} from '../Statistics';
import { shouldUsePlausible } from '../Statistics/apiSelector';
import { StatisticsSwitchMessage } from '../Statistics/StatisticsSwitchMessage';

export const Dashboard = () => {
    const dispatch = useDispatch(),
        { currentDetail } = useSelector(state => state.layout);
    // period = getValueFromQueryString('period');

    useEffect(() => {
        dispatch(setFinderMode('dashboard', currentDetail));
        dispatch(setExportEnabled(false));
    }, [dispatch, currentDetail]);

    return (
        <>
            <StatisticsSwitchMessage />
            {shouldUsePlausible(currentDetail)
                ? (
            
                    <Grid container sx={{ width: 'auto' }} spacing={1}>
                        <Grid item sm={12} md={12} lg={7}>
                            <StatisticsReportMetrics viewType='dashboard' />
                        </Grid>
                        <Grid item sm={12} md={12} lg={5}>
                            <StatisticsReportObjectTypes viewType='dashboard' />
                        </Grid>
                        <Grid item sm={12} md={12} lg={7}>
                            <StatisticsReportAcquisition viewType='dashboard' />
                        </Grid>
                        <Grid item sm={12} md={12} lg={5}>
                            <StatisticsReportLocation viewType='dashboard' />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container sx={{ width: 'auto' }} spacing={1}>
                        <Grid item sm={12} md={12} lg={7}>
                            <GAStatisticsReportMetrics viewType='dashboard' />
                        </Grid>
                        <Grid item sm={12} md={12} lg={5}>
                            <GAStatisticsReportObjectTypes viewType='dashboard' />
                        </Grid>
                        <Grid item sm={12} md={12} lg={7}>
                            <GAStatisticsReportAcquisition viewType='dashboard' />
                        </Grid>
                        <Grid item sm={12} md={12} lg={5}>
                            <GAStatisticsReportLocation viewType='dashboard' />
                        </Grid>
                    </Grid>
                )}
        </>
    );
};
