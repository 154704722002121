import {
    STATISTICS_FAILED,
    STATISTICS_REQUESTED,
    STATISTICS_SUCCEEDED,
    STATISTICS_TOTALS_SUCCEEDED,
    STATISTICS_DOWNLOAD_REQUESTED,
    STATISTICS_DOWNLOAD_SUCCEEDED,
    STATISTICS_HOME_REQUESTED,
    STATISTICS_HOME_SUCCEEDED,
    STATISTICS_REALTIME_REQUESTED,
    STATISTICS_REALTIME_SUCCEEDED,
    STATISTICS_PRIMUS_VS_DM_REQUESTED,
    STATISTICS_PRIMUS_VS_DM_SUCCEEDED,
    REPORT_EXPORT_REQUESTED,
    REPORT_EXPORT_STARTED,
    REPORT_EXPORT_SUCCEEDED,
    REPORT_EXPORT_FAILED
} from '../../types';

const reportSet = {
    fetching: null,
    response: {
        results: []
    },
    max: {
        pageviews: 0
    },
    min: {
        pageviews: 0
    },
    total: {
        pageviews: 0,
        visitors: 0,
        visit_duration: 0,
        bounce_rate: 0
    },
    dateRanges: []
};

const tableReportSet = {
    ...reportSet,
    headers: [],
    rowCount: 0
}

const publishedReportSet = {
    fetching: null,
    rows: [],
    published: 0,
    notPublished: 0,
    dataMin: 0,
    dataMax: 0
};

const defaultState = {
    error: false,
    reports: {
        realtime: {
            response: 0,
            fetching: null
        },
        dashboard: {
            metric: reportSet,
            acquisition: reportSet,
            location: reportSet,
            objectTypes: reportSet
        },
        report: {
            pageViews: tableReportSet,
            objectTypes: tableReportSet,
            objects: tableReportSet,
            downloads: tableReportSet
        },
        published: publishedReportSet,
        export: {
            ...reportSet,
            requested: false
        }
    }
};

const statistics = (state = defaultState, action) => {
    const viewType = action.viewType || 'report',
        type = action.reportType || 'metric';
    let draft = { ...state, error: false };

    switch (action.type) {
        case STATISTICS_REQUESTED:
            draft.reports[viewType][type].fetching = true;
            break;
        case STATISTICS_FAILED:
            if (action.reportType !== 0 && !action.reportType) {
                draft.reports[viewType].fetching = false;
            } else {
                draft.reports[viewType][type].fetching = false;
            }
            draft.error = true;
            break;
        case STATISTICS_SUCCEEDED:
            if (action.data.response.results) {
                draft.error = false;

                if (viewType === 'dashboard') {
                    if (action.data.reportType === 0) {
                        draft.reports.dashboard.metric = {
                            fetching: false,
                            dateRanges: [],
                            ...buildData(action.data)
                        };
                    } else if (action.data.reportType === 'objectTypes') {
                        draft.reports.dashboard.objectTypes = {
                            fetching: false,
                            ...buildData(action.data)
                        };
                    } else if (action.data.reportType === 'location') {
                        draft.reports.dashboard.location = {
                            fetching: false,
                            ...buildData(action.data)
                        };
                    } else if (action.data.reportType === 'acquisition') {
                        draft.reports.dashboard.acquisition = {
                            fetching: false,
                            ...buildData(action.data)
                        };
                    }
                } else if (viewType === 'report') {
                    if (action.data.reportType === 'pageViews') {
                        draft.reports.report.pageViews = {
                            fetching: false,
                            ...buildTableData(action.data, draft.reports.report.pageViews.total)
                        };
                    } else if (action.data.reportType === 'objectTypes') {
                        draft.reports.report.objectTypes = {
                            fetching: false,
                            ...buildTableData(action.data, action.data.total)
                        };
                    } else if (action.data.reportType === 'objects') {
                        draft.reports.report.objects = {
                            fetching: false,
                            ...buildTableData(action.data)
                        };
                    }
                }
            }

            break;
        case STATISTICS_TOTALS_SUCCEEDED:
            if (action.data.total) {
                draft.error = false;

                draft.reports.report.pageViews.total = {
                    pageviews: action.data.total.pageviews,
                    visitors: action.data.total.visitors,
                    visit_duration: action.data.total.visit_duration,
                    bounce_rate: action.data.total.bounce_rate
                };
            }

            break;
        case STATISTICS_HOME_REQUESTED:
            draft.error = false;
            break;
        case STATISTICS_HOME_SUCCEEDED:
            if (action.data) {
                const reports = draft.reports.home.reports,
                    res = [{ ...action.data }];

                draft.reports.home = {
                    fetching: false,
                    reports: reports.concat(res)
                };
            }

            break;
        case STATISTICS_REALTIME_REQUESTED:
            draft.reports.realtime.fetching = true;
            draft.error = false;
            break;
        case STATISTICS_REALTIME_SUCCEEDED:
            if (action.data) {
                draft.reports.realtime = {
                    fetching: false,
                    ...action.data
                };
            }
            break;
        case STATISTICS_PRIMUS_VS_DM_REQUESTED:
            draft.reports.published = {
                ...publishedReportSet,
                fetching: true
            };

            draft.error = false;
            break;
        case STATISTICS_PRIMUS_VS_DM_SUCCEEDED:
            if (action.report) {
                if (viewType === 'published') {
                    draft.reports.published = {
                        fetching: false,
                        published: parseInt(action.report.data.totals[0].values[0]),
                        notPublished: parseInt(action.report.data.totals[0].values[1]),
                        dataMin: parseInt(action.report.data.minimums[0].values[2]),
                        dataMax: parseInt(action.report.data.maximums[0].values[2]),
                        rows: action.report.data.rows.map(row => ({
                            name: row.dimensions[0],
                            published: parseInt(row.metrics[0].values[0]),
                            notPublished: parseInt(row.metrics[0].values[1]),
                            primus: parseInt(row.metrics[0].values[2])
                        })).filter(row => 0 < (row.published + row.notPublished))
                    };
                }
            }
            break;
        case STATISTICS_DOWNLOAD_REQUESTED:
            draft.error = false;
            draft.reports.report.downloads.fetching = true;
            break;
        case STATISTICS_DOWNLOAD_SUCCEEDED:
            if (action.data && action.data.response.results.length) {
                draft.reports.report.downloads = {
                    fetching: false,
                    ...buildTableData(action.data, action.data.total)
                };
            } else {
                draft.reports.report.downloads = {...tableReportSet, fetching: false};
            }
            break;
        case REPORT_EXPORT_REQUESTED:
            draft.reports.export.requested = true;
            draft.reports.export.error = false;
            break;
        case REPORT_EXPORT_STARTED:
            draft.reports.export.fetching = true;
            break;
        case REPORT_EXPORT_SUCCEEDED:
            draft.reports.export.requested = false;
            draft.reports.export.fetching = false;
            break;
        case REPORT_EXPORT_FAILED:
            draft.reports.export.fetching = false;
            draft.reports.export.error = true;
            break;
        default:
            return draft;
    }

    return draft;
};

export default statistics;


const buildData = report => {
    return {
        response: report.response || {},
        max: report.max || 0,
        min: report.min || 0,
        total: report.total || 0
    };
};

const buildTableData = (report, totals = {}) => {
    if (!report.response.results.length) {
        return { rowCount: 0 };
    }

    const headers = Object.keys(totals);

    return {
        headers: headers,
        total: totals,
        min: report.min,
        max: report.max,
        response:{
            results: report.response.results || [],
        },
        rowCount: report.response.results.length || 0
    };
};
