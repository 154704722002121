import svLocale from 'date-fns/locale/sv';
import nbLocale from 'date-fns/locale/nb';
import nnLocale from 'date-fns/locale/nn';
import enLocale from 'date-fns/locale/en-US';

export const getLocale = () => {
    const lng = (localStorage.getItem('i18nextLng') || navigator.language).replace('-NO', '');

    if ('sv' === lng) {
        return svLocale;
    } else if ('no' === lng || 'nb' === lng) {
        return nbLocale;
    } else if ('nn' === lng) {
        return nnLocale;
    } else {
        return enLocale;
    }
};
