import download from 'downloadjs';
import queryString from 'query-string';
import {
    STATISTICS_PRIMUS_VS_DM_REQUESTED,
    STATISTICS_PRIMUS_VS_DM_SUCCEEDED,
    STATISTICS_REQUESTED,
    STATISTICS_SUCCEEDED,
    STATISTICS_TOTALS_SUCCEEDED,
    STATISTICS_TOTALS_FAILED,
    STATISTICS_REALTIME_REQUESTED,
    STATISTICS_REALTIME_SUCCEEDED,
    STATISTICS_DOWNLOAD_REQUESTED,
    STATISTICS_DOWNLOAD_SUCCEEDED,
    REPORT_EXPORT_REQUESTED,
    REPORT_EXPORT_STARTED,
    REPORT_EXPORT_SUCCEEDED,
    REPORT_EXPORT_FAILED,
    STATISTICS_FAILED
} from '../../types';
import { config } from '../../../config';
import { kitFetch } from '@ekultur/fetch';

const handleExportResponse = (response, endpointUrl) => {
    if(response.ok) {
        return response;
    } else {
        return Promise.reject(response.status)
    }
};

const exportResponse = (endpointUrl, configuration, maxTries) => {
    return fetch(endpointUrl, configuration)
        .then(response => {
            if (503 === response.status || 504 === response.status) {
                return Promise.reject('retry')
            } else {
                return handleExportResponse(response, endpointUrl)
            }
        })
        .catch(error => {
            if ('retry' === error && 0 !== maxTries) {
                return exportResponse(endpointUrl, configuration, maxTries - 1);
            } else {
                return Promise.reject(error)
            }
        })
};

const exportRequest = (url) => {
    const token = localStorage.getItem('kit.access_token'),
        headers = new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }),
        configuration = { headers };

    return exportResponse(url, configuration, 3);
};

export const requestStatistics = (
    owner, startDate, endDate, reportType = 'pageViews',
    viewType = 'dashboard', page = 1,
    rows = 50, objectType=null
) => dispatch => {
    const q = {
        owner: owner,
        report_type: reportType,
        rows: rows,
        page: page,
        start_date: startDate,
        end_date: endDate,
        object_type: objectType
    };

    dispatch({
        type: STATISTICS_REQUESTED,
        viewType: viewType,
        reportType: reportType
    });

    kitFetch(`${config.get('plausibleApi')}reports/?${queryString.stringify(q)}`)
        .then(data => dispatch({
            type: STATISTICS_SUCCEEDED,
            viewType: viewType,
            data: { ...data, reportType: reportType }
        }))
        .catch(() => dispatch({
            type: STATISTICS_FAILED,
            viewType: viewType,
            reportType: reportType
        }));

    if (viewType === 'report' && reportType === 'pageViews') {
        kitFetch(`${config.get('plausibleApi')}reports/total-pageviews/?${queryString.stringify(q)}`)
            .then(data => dispatch({
                type: STATISTICS_TOTALS_SUCCEEDED,
                viewType: viewType,
                data: { ...data }
            }))
            .catch(() => dispatch({
                type: STATISTICS_TOTALS_FAILED,
                viewType: viewType
            }));
    }
};

export const requestTimeseriesStatistics = (
    owner, startDate, endDate, reportType = 'pageviews',
    viewType = 'dashboard', interval='date', page = 1,
    rows = 50, objectType=null
) => dispatch => {
    const q = {
        owner: owner,
        report_type: reportType,
        rows: rows,
        page: page,
        start_date: startDate,
        end_date: endDate,
        object_type: objectType,
        interval: interval
    };

    dispatch({
        type: STATISTICS_REQUESTED,
        viewType: viewType,
        reportType: reportType
    });

    kitFetch(`${config.get('plausibleApi')}reports/timeseries/?${queryString.stringify(q)}`)
        .then(data => {dispatch({
            type: STATISTICS_SUCCEEDED,
            viewType: viewType,
            data: { ...data, reportType: reportType }
        });
        console.log('Fetch results', data);
        })
        .catch(() => dispatch({
            type: STATISTICS_FAILED,
            viewType: viewType,
            reportType: reportType
        }));
};

export const requestRealtimeStatistics = (viewType = 'realtime') => dispatch => {
    dispatch({
        type: STATISTICS_REALTIME_REQUESTED
    });

    kitFetch(`${config.get('plausibleApi')}realtime/`)
        .then(data => dispatch({
            type: STATISTICS_REALTIME_SUCCEEDED,
            viewType: viewType,
            data: data
        }))
        .catch(() => dispatch({
            type: STATISTICS_FAILED,
            viewType: viewType
        }));
};

export const requestDownloadStatistics = (
    owners, startDate, endDate,
    rows = 100, page = 1
) => dispatch => {
    const q = {
        owner: owners,
        rows,
        start_date:startDate,
        end_date: endDate,
        page
    };

    dispatch({
        type: STATISTICS_DOWNLOAD_REQUESTED
    });

    kitFetch(`${config.get('plausibleApi')}reports/downloads/?${queryString.stringify(q)}`)
        .then(data => dispatch({
            type: STATISTICS_DOWNLOAD_SUCCEEDED,
            data: data,
            reportType: 'downloads'
        }))
        .catch(() => dispatch({
            type: STATISTICS_FAILED,
            reportType: 'downloads'
        }));
};

export const requestPrimusVsDmStatistics = owner => dispatch => {
    const q = {
        owner: owner,
        rows: 100
    };

    dispatch({
        type: STATISTICS_PRIMUS_VS_DM_REQUESTED
    });

    kitFetch(`${config.get('api')}statistics/primus_vs_dm/?${queryString.stringify(q)}`)
        .then(data => dispatch({
            type: STATISTICS_PRIMUS_VS_DM_SUCCEEDED,
            viewType: 'published',
            report: data.reports[0]
        }))
        .catch(() => dispatch({
            type: STATISTICS_FAILED,
            viewType: 'published'
        }));
};

export const requestExport = () => dispatch => {
    dispatch({
        type: REPORT_EXPORT_REQUESTED
    })
};

export const exportReport = (
    owner, startDate, endDate, reportType = '',
    objectType = ''
) => dispatch => {
    const q = {
        owner: owner,
        start_date: startDate,
        end_date: endDate,
        report_type: reportType,
        object_type: objectType
    },
        url = `${config.get('plausibleApi')}reports/export/?${queryString.stringify(q)}`;

    dispatch({
        type: REPORT_EXPORT_STARTED
    });

    exportRequest(url)
        .then(data => data.blob())
        .then(data => {
            dispatch({
                type: REPORT_EXPORT_SUCCEEDED
            });

            download(data, `dm_statistics_${reportType}_${startDate}-${endDate}.xlsx`);
        })
        .catch(() => dispatch({
            type: REPORT_EXPORT_FAILED
        }));
};

export const exportDownloadStatistics = (owners, timePeriod, startDate, endDate) => dispatch => {
    const q = {
        owner: owners,
        start_date: startDate,
        end_date: endDate
    },
        url = `${config.get('plausibleApi')}reports/downloads/export/?${queryString.stringify(q)}`;
    console.log(url);
    dispatch({
        type: REPORT_EXPORT_STARTED,
    });

    exportRequest(url)
        .then(data => data.blob())
        .then(data => {
            dispatch({
                type: REPORT_EXPORT_SUCCEEDED
            });

            download(data, `dm_statistics_downloads_${timePeriod}.xlsx`);
        })
        .catch(() => dispatch({
            type: REPORT_EXPORT_FAILED
        }));
};
