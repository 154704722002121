import React from 'react';
import { Trans } from 'react-i18next';

import { articleSections } from './section/articleSections';

export const articleSchema = {
    page_type: {
        type: 'string',
        default: 'article',
        readOnly: true
    },
    local_belonging: {
        type: 'array',
        maxItems: 1,
        minItems: 1,
        title: <Trans ns="dm.article" i18nKey="place_title">Steder</Trans>,
        items: {
            type: 'object',
            title: null,
            properties: {
                title: { type: 'string' },
                title_expanded: { type: 'string' },
                _lat: { type: 'number' },
                _lng: { type: 'number' },
                _locationObject: {
                    type: 'object',
                    properties: {
                        address: { type: 'string' },
                        country: { type: 'string' },
                        county: { type: 'string' },
                        house_number: { type: 'number' },
                        municipality: { type: 'string' },
                        neighbourhood: { type: 'string' },
                        road: { type: 'string' },
                        town: { type: 'string' }
                    }
                }
            }
        }
    },
    tags: {
        type: 'array',
        title: <Trans ns="dm.article" i18nKey="topics_title">Nøkkelord (blir søkbare i Dm)</Trans>,
        maxItems: 10,
        items: {
            type: 'object',
            title: null,
            properties: {
                tag: { type: 'string' },
                origin: {
                    type: 'string',
                    default: 'ARTICLE_EDITOR'
                }
            }
        }
    },
    sections: {
        type: 'array',
        title: null,
        items: articleSections
    }
};
