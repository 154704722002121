import {
    FETCHING,
    SLUGS_SUCCEEDED,
    SLUGS_FAILED
} from '../actions/slugs';

const initialState = {
    slugs: null,
    fetching: null,
    error: false
};

export const slugs = (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCHING:
            return {
                ...state,
                fetching: true
            }
        case SLUGS_SUCCEEDED:
            return {
                slugs: action.data.slugs,
                fetching: false,
                error: false
            };
        case SLUGS_FAILED:
            return {
                ...state,
                fetching: false,
                error: action.data
            };
        default:
            return {
                ...state,
            };
    }
};
