import React from 'react';
import { useTranslation } from "react-i18next";


export const NoOwners = () => {
    const { t } = useTranslation(['dm.common'], { useSuspense: false }),
        msg = t('no_owner_access', 'Vi har erstattet DigitaltMuseum Admin med en ny og forbedret løsning. Vennligst be om tilgang til tjenesten ved å sende en e-post til support@kulturit.no');

    return (
        <h3 style={{margin:'2em', width:'100%', lineHeight:'1.5', textAlign:'center'}}>{msg}</h3>
    )
};