import React from 'react';
import { Paper, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Bar, ResponsiveContainer, Tooltip, XAxis, YAxis, BarChart } from 'recharts';

import { ProgressIndicator } from '../../common/ProgressIndicator';
import { useStyles } from './publishedObjectsStyles';

// Workaround for labels disappearing when resizing https://github.com/recharts/recharts/issues/1664
const WorkAroundTick = props => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text dy={16} textAnchor='middle' fill='#666'>{payload.value}</text>
        </g>
    )
};

export const PublishedObjectsBarChart = ({ fetching, rows, t }) => {
    const classes = useStyles(),
        { palette } = useTheme();

    if (fetching) {
        return <ProgressIndicator />
    }

    const translatedRows = rows.map(row => ({
        ...row,
        name: t(`dm.objectTypes:${row.name.toLowerCase()}`),
        publishedPercentage: calculatePercentage(row.published, row.primus),
        notPublishedPercentage: calculatePercentage(row.notPublished, row.primus)
    }));

    return (
        <Paper elevation={3} className={classes.paper}>
            <Typography variant='h6' align='center'>
                {t('percentageByObjectType', 'Prosentvis på objekttype')}
            </Typography>
            <ResponsiveContainer
                width='100%'
                height={70 + (rows.length * 40)}
                minHeight={350}
                id='publishedObjectsBarChartResponsive'
            >
                <BarChart layout='vertical'
                    data={translatedRows}
                    margin={{ top: 10, right: 50, bottom: 10, left: 50 }}
                >
                    <XAxis type='number' domain={[0, 100]} tick={<WorkAroundTick />} hide={true} />
                    <YAxis dataKey='name' type='category' axisLine={false} tickLine={false} />
                    <Tooltip />
                    <Bar
                        dataKey='publishedPercentage'
                        stackId='publishedObjects'
                        name={t('published', 'Publisert')}
                        barSize={15}
                        fill={palette.primary.main}
                        background={{ fill: 'rgba(0, 0, 0, 0.125)' }}
                        unit={'%'}
                        label={{ position: 'right', fill: palette.primary.main, formatter: value => `${value}%` }}
                    />
                </BarChart>
            </ResponsiveContainer>
        </Paper>
    );
};

const calculatePercentage = (partOfNumber, totalNumber) => {
    if(totalNumber === 0) {
        return 100;
    } else if (1 < (partOfNumber / totalNumber)) {
        return 100;
    } else {
        return (partOfNumber / totalNumber * 100).toFixed(1)
    }
};
