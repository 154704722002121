import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './redux/root-reducer';
import ReactGA from 'react-ga';

const middleware = [thunk];


ReactGA.initialize(window._env_.REACT_APP_GA_KEY);
const gaTrackingMiddleware = store => next => action => {
    if (action.type === 'LOCATION_CHANGE') {
        const state = store.getState();

        if (state.app.pathname !== action.path) {
            trackPage(action.path, action.owner, action.userId);
        }
    }

    return next(action);
};

const trackPage = (page, owner, userId) => {
    ReactGA.set({
        owner: owner,
        userId:userId
    });

    ReactGA.pageview(page);
};

export default function configureStore(preloadedState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(
        rootReducer(),
        preloadedState,
        composeEnhancers(
            applyMiddleware(...middleware, gaTrackingMiddleware)
        )
    );
}
