import React, { useState } from 'react';
import { TextField, Menu, MenuItem, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { kitFetchExternal } from '@ekultur/fetch';
import {  } from '@rjsf/material-ui/v5';

import { config } from '../../../config';
import { locationObjToStr } from '../../helpers/locationObjToStr';

let timer;

export const PlaceQueryWidget = (props, onChange) => {
    const [places, setPlaces] = useState([]),
        [selectedLabel, setSelectedLabel] = useState(props && locationObjToStr(props)),
        [anchorEl, setAnchorEl] = useState(null),
        [menuOpen, setMenuOpen] = useState(false),
        [selectedIndex, setSelectedIndex] = useState(0),
        { t } = useTranslation(['dm.article'], { useSuspense: false });

    const _onClick = e => {
        !anchorEl && setAnchorEl(e.target);
        !!places.length && setMenuOpen(true);
    }

    const _onChange = e => {
        const q = e.target.value;

        if (timer) {
            clearTimeout(timer);
            console.log('cleared timeout. q: ', q);
        }

        if (q) {
            timer = setTimeout(() => {
                console.log('setExecutedSearch: ', q);
                kitFetchExternal(`${config.get('placeApi')}?q=${q}`)
                    .then(data => {
                        setPlaces(data);
                        setMenuOpen(true);
                    })
                    .catch(error => console.error(error))
            }, 2000);
        }
    };

    const handleMenuItemClick = (event, index) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex(index);
        setSelectedLabel(locationObjToStr(places[index]));

        onChange && onChange(places[index]);
        setMenuOpen(false);
    };

    const handleKeyUp = e => {
        e.keyCode === 27 && setMenuOpen(false);
    };

    const handleClose = event => {
        event.preventDefault();
        event.stopPropagation();
        setMenuOpen(false);
    };

    return (
        <Box onClick={_onClick} sx={{width: '100%'}}>
            <TextField label={t('place_search', 'Søk etter stedsnavn')} onChange={_onChange} />
            {!!places.length && (
                <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={menuOpen}
                    onClose={handleClose}
                >
                    {places.map((option, index) => (
                        <MenuItem
                            key={index}
                            selected={index === selectedIndex}
                            onClick={event => handleMenuItemClick(event, index)}
                            onKeyUp={event => handleKeyUp(event)}
                        >
                            {locationObjToStr(option)}
                        </MenuItem>
                    ))}
                </Menu>
            )}
            {!!selectedLabel && <p>{selectedLabel}</p>}
        </Box>
    );
};
