import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Link from '@mui/material/Link';
import TableSortLabel from '@mui/material/TableSortLabel';
import { TableContainer } from '@mui/material';
// import TablePagination from '@mui/material/TablePagination';

import { requestDownloadStatistics, exportDownloadStatistics } from '../redux/actions/statistics';
import { setTimePeriod } from '../redux/functions/statistics';
import { getValueFromQueryString } from '../redux/functions/query';
import { setFinderMode, setExportEnabled } from '../redux/actions/layout';

import { ProgressIndicator } from '../common/ProgressIndicator';
import { WithProgressOverlay } from '../common/WithProgressOverlay';
import { StatisticsSwitchMessage } from './StatisticsSwitchMessage';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        padding: 10,
        boxSizing: 'border-box'
    },
    totalRow: {
        background: palette.action.selected
    }
}));

export const StatisticsReportDownloads = ({ viewType }) => {
    //  paging is disabled until API supports it
    const dispatch = useDispatch(),
        classes = useStyles(),
        { t, ready } = useTranslation('googleAnalytics', { useSuspense: false }),
        location = useLocation(),
        pathArr = location.pathname.split('/'),
        reportType = pathArr[2] || 'downloads',
        [order, setOrder] = useState({ by: 'uniquePageviews', descending: true }),
        // [paging, setPaging] = useState({ rows: 10, page: 0 }),
        period = getValueFromQueryString('period'),
        { reports } = useSelector(state => state.gaStatistics),
        downloads = reports && reports[viewType][reportType],
        { requested: exportRequested, fetching } = reports.export,
        { ownerUniqueId } = useSelector(state => state.app),
        { filters, currentDetail } = useSelector(state => state.layout),
        timePeriod = setTimePeriod(filters, currentDetail, period, false, true);

    useEffect(() => {
        if (timePeriod.value === 'custom' && (!timePeriod.start || !timePeriod.end)) {
            return;
        } else if (timePeriod.timePeriod) {
            delete timePeriod.start;
            delete timePeriod.end;
        }

        dispatch(requestDownloadStatistics(ownerUniqueId, timePeriod.timePeriod, timePeriod.start, timePeriod.end));
    }, [dispatch, ownerUniqueId, timePeriod.timePeriod, timePeriod.value, timePeriod.start, timePeriod.end, /* paging.page, paging.rows, */]);

    useEffect(() => {
        if (exportRequested && !fetching) {
            if (timePeriod.value === 'custom' && (!timePeriod.start || !timePeriod.end)) {
                return;
            } else if (timePeriod.timePeriod) {
                delete timePeriod.start;
                delete timePeriod.end;
            }

            dispatch(exportDownloadStatistics(ownerUniqueId, timePeriod.timePeriod, timePeriod.start, timePeriod.end));
        }
    }, [dispatch, ownerUniqueId, timePeriod.timePeriod, timePeriod.value, timePeriod.start, timePeriod.end, exportRequested, fetching]);
    
    useEffect(() => {
        dispatch(setFinderMode('downloads', period));
        dispatch(setExportEnabled(true));
    }, [dispatch, period]);
    
    const orderHandler = headerName => {
        if (headerName === order.by) {
            setOrder({
                ...order,
                descending: !order.descending
            })
        } else {
            setOrder({
                by: headerName,
                descending: true
            })
        }
    };

    const getDirection = headerName => {
        if (order.by === headerName) {
            return order.descending ? 'desc' : 'asc'
        } else {
            return 'desc'
        }
    };

    /* const changePageHandler = newPage => {
        setPaging({
            ...paging,
            page: newPage
        })
    };

    const changeRowsPerPageHandler = event => {
        setPaging({
            rows: parseInt(event.target.value, 10),
            page: 0
        })
    }; */

    if (!ready) {
        return <ProgressIndicator />
    }

    return (
        <WithProgressOverlay loading={downloads.fetching}>
            <StatisticsSwitchMessage />
            <Paper className={classes.root} elevation={3}>
                <h2>Topp 100</h2>
                <TableContainer>
                    <Table>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell key='emptyCell' />
                                {downloads.headers.map(header => (
                                    <TableCell align='right' key={header}>
                                        <TableSortLabel
                                            active={order.by === header}
                                            direction={getDirection(header)}
                                            onClick={() => orderHandler(header)}
                                            disabled={downloads.fetching}
                                        >
                                            {t(header)}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow className={classes.totalRow}>
                                <TableCell key='headerCell'>
                                    Totalt:
                                </TableCell>
                                {downloads.totals.map((total, index) => (
                                    <TableCell align='right' key={index}>
                                        {total}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {downloads.rows.map(row => (
                                <TableRow key={row.header.id}>
                                    <TableCell component='th' scope='row' key='headerCell'>
                                        <Link
                                            href={row.header.id}
                                            rel='noreferrer'
                                            target='_blank'
                                            color='inherit'
                                        >
                                            {row.header.title}
                                        </Link>
                                    </TableCell>
                                    {row.cells.map((cell, index) => (
                                        <TableCell align='right' key={index}>
                                            {cell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component='div'
                    count={downloads.rowCount}
                    rowsPerPage={paging.rows}
                    page={paging.page}
                    onPageChange={(event, newPage) => changePageHandler(newPage)}
                    onRowsPerPageChange={changeRowsPerPageHandler}
                    backIconButtonProps={{ disabled: downloads.fetching || paging.page === 0 }}
                    nextIconButtonProps={{ disabled: downloads.fetching || isLastPage(downloads, paging) }}
                    SelectProps={{ disabled: downloads.fetching }}
                    labelRowsPerPage='Rader per side:'
                    labelDisplayedRows={displaysRowsText}
                /> */}
            </Paper>
        </WithProgressOverlay>
    );
};

// const isLastPage = (downloads, paging) => (Math.floor(downloads.rowCount / paging.rows)) === paging.page;

/* const displaysRowsText = ({from, to, count}) => {
    const calculatedTo = to === -1 ? count : to;
    return `${from}-${calculatedTo} av ${count}`;
}; */
