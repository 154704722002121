import React, { useState } from 'react';
import { FieldTemplate as DefaultFT } from '@rjsf/material-ui/v5';
import { Box, Chip, IconButton, Input } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

const CustomArrayFieldTemplate = arrayFieldProps => {
    return (
        <React.Fragment>
            {arrayFieldProps.items.map((element, index) =>
                (
                    <Box key={index}>
                        {element.children}
                    </Box>
                )
            )}
        </React.Fragment>
    );
};

const TagArrayFieldTemplate = props => {
    const [tag, setTag] = useState();
    
    const onChange = ({ target: { value } }) => {
        setTag(value);
    }

    const addClick = value => {
        props.schema.items.default = value.toLowerCase();
        setTag('');
        props.onAddClick();
    }

    return (
        <Box>
            {props.title && <h4>{props.title}</h4>}
            <div>
                {props.items.map(el => {
                    return <Chip label={el.children.props.formData} onDelete={el.onDropIndexClick(el.index)} />
                })}
            </div>
            {props.canAdd && (
                <>
                    <Input value={tag} onChange={onChange} />
                    <IconButton
                        disabled={!tag || tag.length < 2}
                        onClick={() => addClick(tag)}
                        style={!tag || tag.length < 2 ? {} : { color: 'green' }}
                        size='large'>
                        <DoneIcon />
                    </IconButton>
                </>
            )}
        </Box>
    );
};

const TagItemTemplate = props => {
    const { properties } = props;

    return (
        <>
            {props.title && <span key={props.title}>{props.title}</span>}
            {properties.map(el => {
                if (props.idSchema.$id.includes('tags')) {
                    if (el.name === 'tag') {
                        return el.content;
                    }
                } else if (el.name === 'title_expanded') {
                    return el.content;
                }

                return null;
            })}
        </>
    );
};

export const imageFileUiSchema = {
    title: {
        'ui:FieldTemplate': DefaultFT
    },
    imageDescription: {
        'ui:FieldTemplate': DefaultFT
    },
    alternativeText: {
        'ui:FieldTemplate': DefaultFT
    },
    licenses: {
        'ui:ArrayFieldTemplate': CustomArrayFieldTemplate
    },
    tags: {
        'ui:ArrayFieldTemplate': TagArrayFieldTemplate,
        items: {
            'ui:ObjectFieldTemplate': TagItemTemplate
        }
    }
};
