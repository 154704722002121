import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Link from '@mui/material/Link';
import TableSortLabel from '@mui/material/TableSortLabel';
import { TableContainer } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';

import { requestStatistics, exportReport } from '../../redux/actions/plausible/statistics';
import { setTimePeriod } from '../../redux/functions/statistics';
import { getValueFromQueryString } from '../../redux/functions/query';
import { setFinderMode, setExportEnabled } from '../../redux/actions/layout';

import { Error } from '../../common/Error';
import { NoData } from '../../common/NoData';
import { ProgressIndicator } from '../../common/ProgressIndicator';
import { WithProgressOverlay } from '../../common/WithProgressOverlay';
import numeral from 'numeral';
import { StatisticsSwitchMessage } from '../StatisticsSwitchMessage';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        padding: 10,
        boxSizing: 'border-box'
    },
    totalRow: {
        background: palette.action.selected
    },
    detailRow: {
        cursor: 'pointer',
        '&:hover .title': {
            textDecoration: 'underline'
        }
    }
}));

export const StatisticsReportMetricsTable = ({ viewType }) => {
    const dispatch = useDispatch(),
        classes = useStyles(),
        location = useLocation(),
        navigate = useNavigate(),
        {t, ready} = useTranslation('plausibleAnalytics', {useSuspense: false}),
        pathArr = location.pathname.split('/'),
        reportType = pathArr[2] || 'pageViews',
        {objectType} = useParams(),
        // [order, setOrder] = useState({ by: 'pageviews', descending: true }),
        [paging, setPaging] = useState({rows: 50, page: 0}),
        period = getValueFromQueryString('period'),
        { reports, error } = useSelector(state => state.statistics),
        pageViews = reports && reports[viewType][reportType],
        exportRequested = reports.export.requested,
        { ownerUniqueId } = useSelector(state => state.app),
        { filters, currentDetail } = useSelector(state => state.layout),
        timePeriod = setTimePeriod(filters, currentDetail, period, false, true),

        headers = (header) => {
            switch (header){
                case 'bounce_rate':
                    return header
                            .split('_')
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ')
                case 'visit_duration':
                    return 'Average time/page'
                case 'visitors':
                    return 'Users'
                default:
                    return 'Unique pageviews'
            }
        },
        totals = (key, index) => {
            switch (key){
                case 'bounce_rate':
                    return (<TableCell align="right" key={index}>
                        {formatValue(
                            parseInt(pageViews.total[key])/parseInt(pageViews.response.results.length),
                            'PERCENT'
                        )}
                    </TableCell>)
                case 'visit_duration':
                    return (<TableCell align="right" key={index}>
                        {formatValue(
                            parseInt(pageViews.total[key])/parseInt(pageViews.response.results.length),
                            'TIME'
                        )}
                    </TableCell>)
                default:
                    return (<TableCell align="right" key={index}>
                        {pageViews.total[key]}
                    </TableCell>)
            }
        };

    useEffect(() => {
        if (timePeriod.start && timePeriod.end) {
            dispatch(requestStatistics(ownerUniqueId, timePeriod.start, timePeriod.end, reportType,
                viewType, paging.page+1, paging.rows, objectType));
        }
    }, [dispatch, ownerUniqueId, timePeriod.start, timePeriod.end, viewType,
        paging.page, paging.rows, reportType, objectType]);

    useEffect(() => {
        if (exportRequested && timePeriod.start && timePeriod.end) {
            dispatch(exportReport(ownerUniqueId, timePeriod.start, timePeriod.end,
                reportType, objectType));
        }
    }, [dispatch, ownerUniqueId, timePeriod.start, timePeriod.end, reportType, objectType, exportRequested]);

    useEffect(() => {
        dispatch(setFinderMode('statistics', period));
        dispatch(setExportEnabled(true));
    }, [dispatch, period]);

    /* const orderHandler = headerName => {
        if(headerName === order.by) {
            setOrder({
                ...order,
                descending: !order.descending
            })
        } else {
            setOrder({
                by: headerName,
                descending: true
            })
        }
    }; */

    /* const getDirection = headerName => {
        if(order.by === headerName) {
            return order.descending ? 'desc': 'asc'
        } else {
            return 'desc'
        }
    }; */

    const changePageHandler = newPage => {
        setPaging({
            ...paging,
            page: newPage
        })
    };

    const changeRowsPerPageHandler = event => {
        setPaging({
            rows: parseInt(event.target.value, 10),
            page: 0
        })
    };

    const onExpand = path => {
        navigate(`${location.pathname}/${path}`);
    };

    const formatValue = (value, type) => {
        switch (type) {
            case 'TIME':
                return numeral(value).format('00:00:00');
            case 'PERCENT':
                return numeral(value / 100).format('0.00%');
            default:
                return value
        }
    };

    const setRowTitle = row => {
        if (row.identificationNumber) {
            return row.identificationNumber;
        } else if (row.dimuCode) {
            return row.dimuCode;
        } else if (row.page) {
            return row.page.split('/').slice(2).join('/');
        }

        return '';
    };

    const isLastPage = () => (pageViews.rowCount < paging.rows);

    const displaysRowsText = ({ from, to }) => {
        if (pageViews.fetching) {
            return null;
        }

        const calculatedTo = to > from + pageViews.rowCount ? from + pageViews.rowCount : to;
        return `${from}-${calculatedTo}`;
    };

    if (error) {
        return <Error />;
    } else if (!pageViews.rowCount) {
        return pageViews.fetching ? <ProgressIndicator /> : <NoData />;
    } else if (!headers.length || !ready) {
        return <ProgressIndicator />;
    }

    return (
        <WithProgressOverlay loading={pageViews.fetching}>
            <StatisticsSwitchMessage />
            <Paper className={classes.root} elevation={3}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow key='headerRow'>
                                <TableCell key="emptyCell" />
                                {pageViews.headers.map(header => (
                                    <TableCell align="right" key={header}>
                                        <TableSortLabel
                                            // active={order.by === header}
                                            // direction={getDirection(header)}
                                            // onClick={() => orderHandler(header)}
                                            disabled={pageViews.fetching}
                                        >
                                            {t(headers(header))}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow key='totalRow' className={classes.totalRow}>
                                <TableCell key="headerCell">Totalt:</TableCell>
                                {Object.keys(pageViews.total).map((key, index) => (totals(key, index)))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageViews.response.results.map((row, index) => (
                                (reportType === 'objectTypes' && !objectType && !row.dimuCode) ? (
                                    <TableRow key={row.object_type} className={classes.detailRow} onClick={() => onExpand(row.object_type)}>
                                        <TableCell component="th" scope="row" key="headerCell">
                                            <Typography variant="subtitle2" className="title">
                                                {(reportType === 'objectTypes' && !objectType)
                                                    ? t(`${row.object_type}`)
                                                    : row.object_type}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right" key={0}>
                                            {row.pageviews}
                                        </TableCell>
                                        <TableCell align="right" key={1}>
                                            {row.visitors}
                                        </TableCell>
                                        <TableCell align="right" key={2}>
                                            {formatValue(row.visit_duration, 'TIME')}
                                        </TableCell>
                                        <TableCell align="right" key={3}>
                                            {`${formatValue(row.bounce_rate, 'PERCENT')}`}
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <TableRow key={`${row.dimuCode}_${row.identificationNumber}_${index}`}>
                                        <TableCell component="th" scope="row" key="headerCell">
                                            <Link
                                                href={`${row.link}`}
                                                rel="noreferrer"
                                                target="_blank"
                                                color="inherit"
                                            >
                                                {setRowTitle(row)}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="right" key={0}>
                                            {row.pageviews}
                                        </TableCell>
                                        <TableCell align="right" key={1}>
                                            {row.visitors}
                                        </TableCell>
                                        <TableCell align="right" key={2}>
                                            {formatValue(row.visit_duration, 'TIME')}
                                        </TableCell>
                                        <TableCell align="right" key={3}>
                                            {`${formatValue(row.bounce_rate, 'PERCENT')}`}
                                        </TableCell>
                                    </TableRow>
                                )
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={-1}
                    rowsPerPage={paging.rows}
                    page={paging.page}
                    onPageChange={(event, newPage) => changePageHandler(newPage)}
                    onRowsPerPageChange={changeRowsPerPageHandler}
                    backIconButtonProps={{ disabled: pageViews.fetching || paging.page === 0 }}
                    nextIconButtonProps={{ disabled: pageViews.fetching || isLastPage() }}
                    labelRowsPerPage="Rader per side:"
                    labelDisplayedRows={displaysRowsText}
                    SelectProps={{ disabled: isLastPage() }}
                />
            </Paper>
        </WithProgressOverlay>
    );
};
