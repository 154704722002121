import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';

import { kitFetch } from '@ekultur/fetch';
import { DmsUppyUpload } from '@ekultur/dms-uppy-upload';
import { STATUS } from '@ekultur/dms-upload';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import { config } from '../config';
import { FILE_UPLOAD_SUCCEEDED } from '../redux/actions/media';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative",
        paddingRight: '0 !important',
        backgroundColor: '#006C85',
        '& h6': {
            flex: 1
        }
    }
}));

const createUppy = uppyConfig => {
    return new Uppy().use(DmsUppyUpload, uppyConfig);
};

export const UppyDashboard = ({ closeHandler }) => {
    const publishItemWithId = async (dmsId, mimeType, fileName) => {
        try {
            const postConfig = {
                method: 'POST',
                body: JSON.stringify({
                    owner: ownerUniqueId,
                    mmid: dmsId,
                    userId: userData.uniqueId,
                    media_type: mimeType,
                    file_name: fileName
                })
            };

            return kitFetch(`${config.get('articleApi')}media/resources/publish_media/`, postConfig)
                .then(data => {
                    console.log('Publish status: ', data.status);
                    return data;
                })
                .catch(error => {
                    console.warning(`Failed to publish ${dmsId}. Error: ${error}`);
                    return false;
                });
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    const uploadHandler = async upload => {
        setStatus(upload.status);

        if (upload.status === STATUS.SUCCESS) {
            const data = await publishItemWithId(upload.dmsId, upload.mimeType, upload.fileName);

            console.info(`Publish of ${upload.dmsId} ${data && ['general', 'published'].includes(data.status) ? 'successful' : 'failed'}`);

            dispatch({
                type: FILE_UPLOAD_SUCCEEDED,
                data: data
            });
        }
    };

    const finishedHandler = upload => {
        console.info(`Uploads finished: ${upload.finished.length}, failed: ${upload.failed.length}`);
    };

    const classes = useStyles(),
        dispatch = useDispatch(),
        { ownerUniqueId } = useSelector(state => state.app),
        { userData } = useSelector(state => state.user),
        [status, setStatus] = useState(),
        [uppy] = useState(() => createUppy({
            uploadHandler: uploadHandler,
            finishedHandler: finishedHandler,
            orderUploadEndpoint: { url: `${config.get('api')}dms/upload_url` },
            commitMultipartEndpoint: { url: `${config.get('api')}dms/commit_multipart_upload` },
            chunkSize: 1e8,
            limit: 1,
            retry: 0,
            mock: false
        }));

    const onClose = () => {
        closeHandler?.();
    };

    return (
        <Dialog
            open={true}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6">
                        {status !== 'success'
                            ? 'Vennligst ikke lukk vinduet under opplasting!'
                            : 'Ferdig!'
                        }
                    </Typography>
                    {[undefined, 'success', 'error'].includes(status) && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <Box m={3}>
                <Dashboard uppy={uppy} plugins={['DmsUppyUpload']} proudlyDisplayPoweredByUppy={false} />
            </Box>
        </Dialog>
    );
};
