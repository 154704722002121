import React, { useCallback, useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { svgToSrc, dmsUrl } from '@ekultur/ui-schema-components';
 
export const Resource = ({ media, maxRetries = 10 }) => {
    const [retryCount, setRetryCount] = useState(0);
    const url = ['video', 'audio'].includes(media.type) ? media.url : dmsUrl(media, 10);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setRetryCount(0);
    }, [url]);

    const handleImageError = useCallback(() => {
        setTimeout(() => {
            setRetryCount(r => {
                if (url && r < maxRetries) {
                    return r + 1;
                } else {
                    setLoading(false);
                    return r;
                }
            });
        }, 3000)
    }, [url, maxRetries]);

    const imageLoaded = () => {
        setLoading(false);
    };

    switch (media.type) {
        case 'video':
            return (
                <video controls style={{ maxWidth: '100%' }}>
                    <source src={url} type='video/mp4' />
                    Your browser does not support the video tag.
                </video>
            );
        case 'audio':
            return (
                <audio controls style={{ maxWidth: '100%' }}>
                    <source src={url} type='audio/mp3' />
                    Your browser does not support the audio tag.
                </audio>
            );
        case 'document':
            return <img style={{ maxWidth: '100%' }} src='https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg' alt='PDF' />;
        default:
            return (
                media.mimetype === 'image/svg+xml' && media.source
                    ? <img className='svg' src={svgToSrc(media.source)} alt={media.filename} />
                    :   <div>
                            <CircularProgress style={{display: loading ? "block" : "none"}}/>
                            <img
                                style={{ maxWidth: '100%', display: loading ? "none" : "block"}}
                                src={`${url}?dimension=167x167&failhard=true&bust=${retryCount}`}
                                onError={handleImageError}
                                onLoad={imageLoaded}
                                alt={media.filename}
                            />
                        </div>
            );
    }
};
