import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Paper, useTheme } from '@mui/material';

import { WithProgressOverlay } from '../common/WithProgressOverlay';
import { MuseumsList } from './MuseumsList';

export const UserMuseums = () => {
    const navigate = useNavigate(),
        location = useLocation(),
        { spacing } = useTheme(),
        { museumData } = useSelector(state => state.user);

    const onSelect = mid => {
        navigate(`${location.pathname}?museum_id=${mid}`);
    };

    return (
        <WithProgressOverlay loading={!museumData}>
            <Paper sx={{ padding: spacing(2) }}>
                <MuseumsList
                    museums={museumData}
                    selectMuseumHandler={onSelect}
                />
            </Paper>
        </WithProgressOverlay>
    );
};
