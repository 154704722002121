import { blockVisibility } from '../helpers/blockVisibility';
import { BodyText } from '../preview/tpl/BodyText';
import { Dm } from '../preview/tpl/Dm';

export const MuseumTrending = ({ searches, museum }) => {
    return (
        <section className='museum-trending'>
            {searches.map((block, index) => (block && blockVisibility(block)) && (
                <div
                    id={`@section_2.searches_${index}`}
                    data-unique-key={block._unique_key}
                    key={index}
                >
                        {block.title && <section className='article__content'>{BodyText(block)}</section>}
                    <section className='section--auto dm'>
                        <Dm id={`section_2-searches_${index}`} block={block} museum={museum} />
                    </section>
                    {(block.showMoreLabel && block.searchUrl) && (
                        <a className='button button--link' href={`${block.searchUrl.startsWith('/') ? window._env_.REACT_APP_DM4_URL : ''}${block.searchUrl}`} target='_blank' rel='noreferrer noopener'>
                            {block.showMoreLabel}
                        </a>
                    )}
                </div>
            ))}
        </section>
    )
};
