import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { requestArticle, requestNewArticle, resetEditorState } from '../redux/actions/article';
import { setFinderMode, setExportEnabled } from '../redux/actions/layout';

import { ProgressIndicator } from '../common/ProgressIndicator';
import { Error } from '../common/Error';
import { ArticleEditor } from './ArticleEditor';

export const PageLoader = props => {
    const dispatch = useDispatch(),
        navigate = useNavigate(),
        { articleId } = useParams(),
        { pageType, editUrl } = props,
        { ownerUniqueId } = useSelector(state => state.app),
        { data, fetching, error, created } = useSelector(state => state.article),
        [pageId, setPageId] = useState();

    useEffect(() => {
        if (articleId === 'new') {
            if (created) {
                setPageId(data.id);
                navigate(`/${editUrl}/edit/${data.id}`);
            } else {
                dispatch(requestNewArticle(ownerUniqueId, pageType));
            }
        } else {
            if (pageId !== parseInt(articleId)) {
                setPageId(parseInt(articleId));
                dispatch(requestArticle(ownerUniqueId, pageType, articleId));
            }
        }
    }, [dispatch, navigate, articleId, ownerUniqueId, pageType, pageId, created, data.id, editUrl]);
    
    useEffect(() => {
        dispatch(setFinderMode('articles'));
        dispatch(setExportEnabled(false));

        return () => {
            console.log('******************* UNMOUNTING PAGE');
            dispatch(resetEditorState());
        };
    }, [dispatch]);

    const renderProgress = () => {
        return <ProgressIndicator />;
    };

    if (error) {
        return <Error />;
    }

    if (!data.id) {
        return renderProgress();
    }

    return (
        <>
            {fetching && renderProgress()}
            <ArticleEditor dbPage={data} {...props} />
        </>
    );
};
