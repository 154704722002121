import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme, Typography, Paper, TableContainer } from '@mui/material';

import { requestRealtimeStatistics } from '../../redux/actions/plausible/statistics';

import { WithProgressOverlay } from '../../common/WithProgressOverlay';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

export const RealtimeStatistics = () => {
    const dispatch = useDispatch(),
        { spacing } = useTheme(),
        { t, ready } = useTranslation('plausibleAnalytics', { useSuspense: false }),
        { reports } = useSelector(state => state.statistics),
        { response } = reports.realtime,
        [intrval, setIntrval] = useState(0);
        // data = labels.map((row, index) => ({
        //     name: `${row} ${t('minutesAgo', 'minutter siden')}`,
        //     pageViews: parseInt(plot[index])
        // }));

    useEffect(() => {
        const tick = () => {
            dispatch(requestRealtimeStatistics());
            setIntrval(60000);
        };

        let id = setInterval(tick, intrval);
        return () => clearInterval(id);
    }, [dispatch, intrval]);

    return (
        <WithProgressOverlay loading={!ready || !response}>
            <Paper style={{ padding: spacing(2) }}>
                <Typography variant='h6' align='center'>
                    {t('realtimeVisitors', 'Unike besøkende på DigitaltMuseum siste 5 minutter')}
                </Typography>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow key={0}>
                                <TableCell component="th" scope="row" key="headerCell">
                                    <center><b>{response}</b></center>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </WithProgressOverlay>
    );
};
