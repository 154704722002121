import merge from 'deepmerge';
import delve from 'dlv';
import { defaultConfig } from './default';

const { REACT_APP_ENV, NODE_ENV } = process.env;

const ENV = REACT_APP_ENV || NODE_ENV;

const envConfig = () => {
    try {
        return merge(defaultConfig, require(`./${ENV}.js`).config);
    } catch (e) {
        // console.log(` CONFIG: Environment "${ENV}" not found.`);

        return defaultConfig;
    }
};

// https://github.com/developit/dlv
// config.get('my.nested.config', 'defaultValue')
export const config = {
    get: (value, def = '') => delve(envConfig(), value, def),
};
