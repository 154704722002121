import image from './image';

export const imageWithFocusPoint = {
    type: 'object',
    properties: {
        ...image.properties,
        media: {
            type: 'object',
            title: null,
            properties: {
                ...image.properties.media.properties,
                focuspoint: {
                    type: 'object',
                    properties: {
                        x: {
                            type: 'number',
                            default: 0
                        },
                        y: {
                            type: 'number',
                            default: 0
                        }
                    }
                }
            }
        }
    }
};
