import React from 'react';
import {useTranslation} from "react-i18next";


export const NoData = () => {
    const { t } = useTranslation(['dm.common'], { useSuspense: false }),
        msg = t('data_noResults', 'Ingen data å vise');

    return (
        <h3>{msg}</h3>
    )
};